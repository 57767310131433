import React, { Suspense } from 'react';
import Loader from "../headerComponents/loader"
import UploadedPrescription from "./UploadedPrescription"
// import MainHeader from "../headerComponents/mainHeader"
// import MainFooter from "../headerComponents/mainFooter"
import $ from "jquery";
import { pageHitAPICall } from '../services'
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
class orderSuccessTest extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: '0',
            cartDetails: [],
            taxData: []
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        var orderSummery = localStorage.getItem('orderSummery')
        if (orderSummery != null && orderSummery != 'null' && orderSummery != '') {
            // console.log('orderSummery....~~~~....',orderSummery)
            var orderSummeryData = JSON.parse(orderSummery);
            console.log('orderSummery....~~~~....',orderSummeryData)
            this.setState({
                isLoading: '1',
                cartDetails: orderSummeryData
            })
            localStorage.removeItem('orderSummery')
        }
        else {
            window.location.href = "/";
        }
        var sellerID = "";
        var moduleID = 13;
        var siteCategoryGroupId = "";

        if (window.sessionStorage.getItem('siteCategoryId')) {
            siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
        }
        else {
            sellerID = window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
    }

    continueClick() {
        let themeId = localStorage.getItem('themeId')
        let mainCategoryId = window.sessionStorage.getItem('mainGroupCategory')
        let siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId')
        let subDomainFlag = window.sessionStorage.getItem('subDomainFlag');
        if (siteCategoryGroupId != '0' && siteCategoryGroupId != 'null' && siteCategoryGroupId != null) {
            if (subDomainFlag == '1') {
                window.location.href = '/'
            }
            else if (localStorage.getItem('hasGlobalSearch') == "true" ){
                window.location.href = '/'
            }
            else {
                window.location.href = '/category'
            }
        }
        else {
            if (mainCategoryId == '5') {
                window.location.href = "/restaurantListing/0/0/";
            }
            else if (mainCategoryId == '8') {
                // if (themeId == '36') {
                //     window.location.href = "/cmCategoryHome";
                // }
               if (themeId == '38') {
                    window.location.href = '/';
                }
                else {
                    window.location.href = "/cmItemListing/0/0/0/";
                }
            }
            else {
                window.location.href = '/'
            }
        }
    }
    addDefaultSrc(ev) {
        ev.target.src = $("#hid" + ev.target.id).val()
    }
    render() {
        let siteLokalDetails = window.sessionStorage.getItem('siteLokalDetails');
        var siteDetailsLokal = JSON.parse(siteLokalDetails);
        let themeId = localStorage.getItem('themeId')
        let siteCategoryId = window.sessionStorage.getItem('siteCategoryId')
        return (
            <div className={themeId ==="29" ? "coverWraper mraCoverWraper ": "coverWraper "}>
                <Suspense fallback={<div></div>}>
                 {this.state.isLoading == '1' ? <MainHeader></MainHeader> : ""}
               <div className={themeId ==="38" ? "scUnifrmOrder  " :" "}>

               <div className={themeId ==="28" ? "contentWrap clContentWrap " :"contentWrap contentWrapWhite"}>
                {console.log("this.state.cartDetails",this.state.cartDetails)}
                    {this.state.isLoading == '0' ? <Loader></Loader> :
                        this.state.cartDetails.map((cartObject) => (
                            <div class="invoice-div">
                                <div class="titleHeading-succes">Your Order Confirmed Successfully.</div>
                                <div class="titleHeading">Order Summary</div>
                                <div className="orderSection">
                                    {cartObject.commonItems.map((commonItemObject, groceryObjectIndex) =>
                                        <div>
                                            <div className="shopList">
                                                <div className="shopTitle shopTitleCircle">
                                                    <div className="shopLogo"><img id={'common' + groceryObjectIndex} onError={this.addDefaultSrc} src={commonItemObject.logopath} alt=""/></div>
                                                    <input type="hidden" id={"hidcommon" + groceryObjectIndex} value={commonItemObject.sellerLogo} />
                                                    <div className="shopName">{commonItemObject.storeName}
                                                        <div className="shopMobNmbr">Mob : {commonItemObject.mobileNumber}</div>
                                                    </div>
                                                    <div className="shoporderNumberr">Order Number: {commonItemObject.saleOrderId}</div>
                                                </div>
                                                {commonItemObject.commonItemCartItems.map((commonItemCartItems) =>
                                                    <div>
                                                        <div className="productItemWrap">
                                                            <div className="productItemList" >
                                                                <div className="productItemImage">
                                                                    <img src={commonItemCartItems.itemImage} alt="" />
                                                                </div>
                                                                <div className="productItemDetails">
                                                                    <div className="productItemLeft">
                                                                        <div className="productItemName productItemNameSize">{commonItemCartItems.itemName} - {commonItemCartItems.sizeName}</div>
                                                                        {/* <div className="productItemName">Qty - {commonItemCartItems.quantity}</div> */}
                                                                        {commonItemCartItems.brandName != null && commonItemCartItems.brandName != 'null' && commonItemCartItems.brandName != '' ?

                                                                            <div className="productItemName">{commonItemCartItems.brandName}</div>
                                                                            : ''}
                                                                    </div>
                                                                    <div className="productItemRight">
                                                                        <div class="productPrice">
                                                                            <span class="newPrice">{commonItemObject.currencySymbol} {parseFloat(commonItemCartItems.netAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}  |  Qty - {commonItemCartItems.quantity}</span>
                                                                            {commonItemCartItems.netAmount < commonItemCartItems.mrp ?
                                                                                <span class="oldPrice">{commonItemObject.currencySymbol} {parseFloat(commonItemCartItems.mrp).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                                                : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="orderSummaryPrice">
                                                <div className="orderPriceLeft deliveryTime">
                                                    {commonItemObject.isFreeItem == "1" ?
                                                        <div className="promocodeAppliedItem"><span className="summarySaveText">Congratulations!</span> You got {commonItemObject.freeItemName} free </div>
                                                        : ''}
                                                    Delivery between date : {commonItemObject.slotNames}
                                                    {commonItemObject.consumerNumber != null && commonItemObject.consumerNumber != '' ?
                                                        <div className="orderSummaryConsumerCard">
                                                            {(commonItemObject.consumerCardName != null && commonItemObject.consumerCardName != '') ? commonItemObject.consumerCardName : 'Consumer Number'} : {commonItemObject.consumerNumber}
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                <div className="orderPriceRight">
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Sub Total :</label>
                                                        <span id="subTotal"> {commonItemObject.currencySymbol} {parseFloat(commonItemObject.storeTotalBeforePromoCode).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>total Discount :</label>
                                                        <span id="subTotal"> {commonItemObject.currencySymbol} {parseFloat(commonItemObject.discount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    {commonItemObject.taxData.map((tax, taxIndex) => (
                                                        <div key={taxIndex} className="priceListSummary priceListTotal">
                                                            <label>{tax.taxName}({tax.taxPercentage} %) :</label>
                                                            <span id={"taxValuediv" + taxIndex}>{commonItemObject.currencySymbol}  {parseFloat(tax.taxAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                        </div>
                                                    ))}
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Delivery Charge :</label>
                                                        <span id="divDeliverCharge">{commonItemObject.currencySymbol} {parseFloat(commonItemObject.deliveryAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Grand Total :</label>
                                                        <span id="gTotal">{commonItemObject.currencySymbol} {parseFloat(commonItemObject.grandTotal).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="invoice-div">
                                                {commonItemObject.comments != null && commonItemObject.comments != '' ?
                                                    <div className="orderSummaryBtmText orderSummaryRed">
                                                        <strong>Message : </strong> {commonItemObject.comments}
                                                    </div>
                                                    :
                                                    ''
                                                }
                                                <div className="orderSummaryBtmText">
                                                    <strong>Please Note:</strong> {commonItemObject.emailFooterText}
                                                    <br></br>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {cartObject.restaurant.map((groceryItemObject, groceryObjectIndex) =>
                                        <div>
                                            <div className="shopList">
                                                <div className="shopTitle shopTitleCircle">
                                                    <div className="shopLogo"><img id={'restaurant' + groceryObjectIndex} onError={this.addDefaultSrc} src={groceryItemObject.logopath} alt="" /></div>
                                                    <input type="hidden" id={"hidrestaurant" + groceryObjectIndex} value={groceryItemObject.sellerLogo} />
                                                    <div className="shopName">{groceryItemObject.storeName}
                                                        <div className="shopMobNmbr">Mob : {groceryItemObject.mobileNumber}</div>
                                                    </div>
                                                    <div className="shoporderNumberr">Order Number: {groceryItemObject.saleOrderId}</div>
                                                </div>
                                                {groceryItemObject.restaurantCartItems.map((groceryCartItems) =>
                                                    <div className="productItemWrap">
                                                        <div className="productItemList" >
                                                            <div className="productItemImage">
                                                                <img src={groceryCartItems.item_image} alt=""/>
                                                            </div>
                                                            <div className="productItemDetails productItemDetailsInvoice">
                                                                <div className="productItemLeft">
                                                                    <div className="productItemName">{groceryCartItems.item_names} - {groceryCartItems.pack_name}</div>
                                                                    {/* <div className="productItemName">Qty - {groceryCartItems.quantity}</div> */}
                                                                    {groceryCartItems.toppingsDetails.map((restaurantCartItemsToppings) => (
                                                                        <div>
                                                                            <div className="productItemName newPrice">{restaurantCartItemsToppings.name}</div>
                                                                            {restaurantCartItemsToppings.toppingArray.map((restaurantCartItemsToppingsSub) => (
                                                                                <div className="productItemName productItemNameLeft">{restaurantCartItemsToppingsSub.name} {restaurantCartItemsToppingsSub.price != 0 ? groceryItemObject.currencySymbol + ' ' + parseFloat(restaurantCartItemsToppingsSub.price).toFixed(window.sessionStorage.getItem('decimalCount')) : '  (Free)'}</div>
                                                                            ))}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="productItemLeft productItemLeftCenter">
                                                                    <div className="productItemName">Price - {groceryItemObject.currencySymbol} {parseFloat(groceryCartItems.itemMrp).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}  |  Qty - {groceryCartItems.quantity}</div>
                                                                </div>
                                                                <div className="productItemRight">
                                                                    <div class="productPrice">
                                                                        <span class="newPrice"> {groceryItemObject.currencySymbol} {parseFloat(groceryCartItems.itemMrp * groceryCartItems.quantity).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="orderSummaryPrice">
                                                <div className="orderPriceLeft deliveryTime">
                                                    {groceryItemObject.isFreeItem == "1" ?
                                                        <div className="promocodeAppliedItem"><span className="summarySaveText">Congratulations!</span> You got {groceryItemObject.freeItemName} free </div>
                                                        : ''}
                                                    Delivery Time : {groceryItemObject.slotNames}
                                                    {groceryItemObject.receiverName !== "" ?
                                                        <div>
                                                            Receiver Name : {groceryItemObject.receiverName}
                                                            Order Option : {groceryItemObject.orderOptionsName}
                                                            Receiver Option : {groceryItemObject.receiverOptionsName}
                                                        </div>
                                                        : ""
                                                    }

                                                    {groceryItemObject.consumerNumber != null && groceryItemObject.consumerNumber != '' ?
                                                        <div className="orderSummaryConsumerCard">
                                                            {(groceryItemObject.consumerCardName != null && groceryItemObject.consumerCardName != '') ? groceryItemObject.consumerCardName : 'Consumer Number'} : {groceryItemObject.consumerNumber}
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                <div className="orderPriceRight">
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Sub Total :</label>
                                                        <span id="subTotal">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.storeTotalBeforePromoCode).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    {groceryItemObject.storePromocodeAmount != '' && groceryItemObject.storePromocodeAmount != '0' ?
                                                        <div className="priceListSummary priceListTotal">
                                                            <label>PromoCode Discount:</label>
                                                            {groceryItemObject.isFreeItem == '1' ?
                                                                <span id="subTotal">Applied</span>
                                                                :
                                                                <span id="subTotal">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.storePromocodeAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                            }
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Total Discount :</label>
                                                        <span id="subTotal">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.discount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    {groceryItemObject.taxData.map((tax, taxIndex) => (
                                                        <div key={taxIndex} className="priceListSummary priceListTotal">
                                                            <label>{tax.taxName}({tax.taxPercentage} %) :</label>
                                                            <span id={"taxValuediv" + taxIndex}>{groceryItemObject.currencySymbol}  {parseFloat(tax.taxAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                        </div>
                                                    ))}
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Delivery Charge :</label>
                                                        <span id="divDeliverCharge">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.deliveryAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Grand Total :</label>
                                                        <span id="gTotal">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.grandTotal).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="invoice-div">
                                                {groceryItemObject.comments != null && groceryItemObject.comments != '' ?
                                                    <div className="orderSummaryBtmText orderSummaryRed">
                                                        <strong>Message : </strong> {groceryItemObject.comments}
                                                    </div>
                                                    :
                                                    ''
                                                }
                                                <div className="orderSummaryBtmText">
                                                    <strong>Please Note:</strong> {groceryItemObject.emailFooterText}
                                                    <br></br>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {cartObject.grocery.map((groceryItemObject, groceryObjectIndex) =>
                                        <div>
                                            <div className="shopList">
                                                <div className="shopTitle shopTitleCircle">
                                                    <div className="shopLogo"><img id={'grocery' + groceryObjectIndex} onError={this.addDefaultSrc} src={groceryItemObject.logopath} alt=""/></div>
                                                    <input type="hidden" id={"hidgrocery" + groceryObjectIndex} value={groceryItemObject.sellerLogo} />
                                                    <div className="shopName">{groceryItemObject.storeName}
                                                        <div className="shopMobNmbr">Mob : {groceryItemObject.mobileNumber}</div>
                                                    </div>
                                                    <div className="shoporderNumberr">Order Number: {groceryItemObject.saleOrderId}</div>
                                                </div>
                                                {groceryItemObject.cartItems.map((groceryCartItems) =>
                                                    groceryCartItems.item_names !== "" ?
                                                        <div className="productItemWrap">
                                                            <div className="productItemList" >
                                                                <div className="productItemImage">
                                                                    <img src={groceryCartItems.item_image} alt=""/>
                                                                </div>
                                                                <div className="productItemDetails productItemDetailsInvoice">
                                                                    <div className="productItemLeft">
                                                                        <div className="productItemName">{groceryCartItems.item_names} - {groceryCartItems.pack_name}</div>
                                                                        {/* <div className="productItemName">Qty - {groceryCartItems.quantity}</div> */}
                                                                        <div className="productItemName">{groceryCartItems.brand_name}</div>
                                                                    </div>
                                                                    <div className="productItemLeft productItemLeftCenter">
                                                                        <div className="productItemName">Unit Price - {groceryItemObject.currencySymbol} {parseFloat(groceryCartItems.item_prices).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}  |  Qty - {groceryCartItems.quantity}</div>
                                                                        <div className="productItemName">MRP - {groceryItemObject.currencySymbol} {parseFloat(groceryCartItems.mrp).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</div>
                                                                    </div>
                                                                    <div className="productItemRight">
                                                                        <div class="productPrice">
                                                                            <span class="newPrice"> {groceryItemObject.currencySymbol} {parseFloat(groceryCartItems.item_prices * groceryCartItems.quantity).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''
                                                )}
                                                {groceryItemObject.isPharmacy == "1" ?
                                                    <UploadedPrescription isPharmacy={groceryItemObject.isPharmacy} cartItems={groceryItemObject.cartItems}></UploadedPrescription>
                                                    : ''}

                                            </div>
                                            <div className="orderSummaryPrice">
                                                <div className="orderPriceLeft deliveryTime">
                                                    {groceryItemObject.isFreeItem == "1" ?
                                                        <div className="promocodeAppliedItem"><span className="summarySaveText">Congratulations!</span> You got {groceryItemObject.freeItemName} free </div>
                                                        : ''}

                                                    Delivery Time : {groceryItemObject.slotNames}
                                                    {groceryItemObject.consumerNumber != null && groceryItemObject.consumerNumber != '' ?
                                                        <div className="orderSummaryConsumerCard">
                                                            {(groceryItemObject.consumerCardName != null && groceryItemObject.consumerCardName != '') ? groceryItemObject.consumerCardName : 'Consumer Number'} : {groceryItemObject.consumerNumber}
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                <div className="orderPriceRight">
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Sub Total :</label>
                                                        <span id="subTotal">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.storeTotalBeforePromoCode).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    {groceryItemObject.storePromocodeAmount != '' && groceryItemObject.storePromocodeAmount != '0' ?
                                                        <div className="priceListSummary priceListTotal">
                                                            <label>PromoCode Discount :</label>
                                                            {groceryItemObject.isFreeItem == '1' ?
                                                                <span id="subTotal">Applied</span>
                                                                :
                                                                <span id="subTotal">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.storePromocodeAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                            }
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Total Discount :</label>
                                                        <span id="subTotal">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.discount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    {groceryItemObject.taxData.map((tax, taxIndex) => (
                                                        <div key={taxIndex} className="priceListSummary priceListTotal">
                                                            <label>{tax.taxName}({tax.taxPercentage} %) :</label>
                                                            <span id={"taxValuediv" + taxIndex}>{groceryItemObject.currencySymbol}  {parseFloat(tax.taxAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                        </div>
                                                    ))}
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Delivery Charge :</label>
                                                        <span id="divDeliverCharge">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.deliveryAmount).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })}</span>
                                                    </div>
                                                    <div className="priceListSummary priceListTotal">
                                                        <label>Grand Total :</label>
                                                        <span id="gTotal">{groceryItemObject.currencySymbol} {parseFloat(groceryItemObject.grandTotal).toLocaleString('en-IN', { minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount') })} </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="invoice-div">
                                                {groceryItemObject.comments != null && groceryItemObject.comments != '' ?
                                                    <div className="orderSummaryBtmText orderSummaryRed">
                                                        <strong>Message : </strong> {groceryItemObject.comments}
                                                    </div>
                                                    :
                                                    ''
                                                }
                                                <div className="orderSummaryBtmText">
                                                    <strong>Please Note : </strong> {groceryItemObject.emailFooterText}
                                                    <br></br>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="summaryButtonSection">
                                        <span className="commonButton" onClick={this.continueClick}>Continue Shopping</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
               </div>
                <MainFooter></MainFooter>
                </Suspense>
            </div>
        )
    }
}
export default orderSuccessTest  