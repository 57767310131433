import React, { Component } from 'react';
import { getData } from '../services';
import RootFileSub from "./rootFileSub"
import $ from 'jquery'
import { printParams } from "../config";
export default class rootFile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: '0',
      siteData: [],
      themeId: '',
      sellerData: [],
      storeData: []
    };
  }
  fetchSiteDetails(siteUrl, categoryId, sellerId) {
    if (window.sessionStorage.getItem('sellerId') !== '0' && window.sessionStorage.getItem('sellerId') !== 'null' && window.sessionStorage.getItem('sellerId') !== null && window.sessionStorage.getItem('sellerId') !== '' && window.sessionStorage.getItem('subDomainFlag' !== '1')) {
      siteUrl = ''
      sellerId = window.sessionStorage.getItem('sellerId')
    }
    const postData = {
      functionName: "getsitedetail",
      siteUrl: siteUrl,
      storeId: "",
      sellerId: sellerId,
      categoryId: categoryId
    }
    getData(postData).then((data) => {
      printParams("abczzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz", data)
      if (data.data.success === "1") {
        if (data.data.results.siteData[0].siteId === 1) {
          localStorage.setItem('siteId', data.data.results.siteData[0].siteId);
          localStorage.setItem("isPincodeBased", data.data.results.siteData[0].isPincodeBased === 1 ? "true" : "false")
          localStorage.setItem('siteGlobalDelivery', data.data.results.siteData[0].hasGlobalDelivery === 1 ? "true" : "false");
          localStorage.setItem('themeName', data.data.results.siteData[0].themeName);
          this.setState({
            isLoading: '1',
            siteData: data.data.results.siteData,
          });
          $("#defaultBannerLoad").hide();
        }
        else {
          if (data.data.results.siteData[0].subDomainFlag === '1') {
            window.sessionStorage.setItem('subDomainFlag', data.data.results.siteData[0].subDomainFlag)
            let storeIdfromSubdomainSeller = window.sessionStorage.getItem('storeIdfromSubdomainSeller')
            if (storeIdfromSubdomainSeller !== "0" && storeIdfromSubdomainSeller !== "" && storeIdfromSubdomainSeller !== "null" && storeIdfromSubdomainSeller !== null) {
              window.sessionStorage.setItem('storeId', storeIdfromSubdomainSeller)

            }
            else {
              window.sessionStorage.setItem('storeId', data.data.results.siteData[0].storeId)
            }

            window.sessionStorage.setItem('mainGroupCategory', data.data.results.siteData[0].mainGroupCategoryId)
            window.sessionStorage.setItem('mainCategoryId', data.data.results.siteData[0].mainCategoryId);
            window.sessionStorage.setItem('storeMobile', data.data.results.siteData[0].storeMobileNumber)
            window.sessionStorage.setItem('storeEmail', data.data.results.siteData[0].storeEmail)
          }
          var siteNameReplace = data.data.results.siteData[0].siteUrl.replace("www.", "")
          var siteName = siteNameReplace.charAt(0).toUpperCase() + siteNameReplace.slice(1)
          window.sessionStorage.setItem('siteName', siteName);
          localStorage.setItem('siteId', data.data.results.siteData[0].siteId);
          localStorage.setItem("isPincodeBased", data.data.results.siteData[0].isPincodeBased === 1 ? "true" : "false")
          localStorage.setItem('siteGlobalDelivery', data.data.results.siteData[0].hasGlobalDelivery === 1 ? "true" : "false");
          localStorage.setItem('themeName', data.data.results.siteData[0].themeName);
          window.sessionStorage.setItem('hasMultipleCheckout', data.data.results.siteData[0].hasMultipleCheckout);
          window.sessionStorage.setItem('authenticationApi', data.data.results.siteData[0].authenticationApi);
          window.sessionStorage.setItem('hasAuthentication', data.data.results.siteData[0].hasAuthentication);
          window.sessionStorage.setItem('googleAnalyticsId', data.data.results.siteData[0].googleAnalyticsId);
          window.sessionStorage.setItem('fbPixelId', data.data.results.siteData[0].fbPixelId);
          window.sessionStorage.setItem('sellerId', data.data.results.siteData[0].sellerId);
          window.sessionStorage.setItem('siteCategoryId', data.data.results.siteData[0].siteCategoryGroupId);
          localStorage.setItem('themeId', data.data.results.siteData[0].themeId);
          window.sessionStorage.setItem('decimalCount', data.data.results.siteData[0].decimalCount);
          window.sessionStorage.setItem('hasParcel', data.data.results.siteData[0].hasParcel);
          localStorage.setItem('logoPath', data.data.results.siteData[0].logoPath)
          window.sessionStorage.setItem('IsEmbeded', true);
          window.sessionStorage.setItem('siteDetails', JSON.stringify(data.data.results));
          if (data.data.results.siteData[0].siteCategoryGroupId !== 0) {
            if (localStorage.getItem('siteGlobalDelivery') === "true" || localStorage.getItem('isPincodeBased') === "true") {
              localStorage.setItem('hasLocation', '0');
            }
            else {
              localStorage.setItem('hasLocation', '1');
            }
            localStorage.setItem("hasGlobalSearch", data.data.results.siteData[0].siteCategoryData[0].hasGlobalSearch)
            localStorage.setItem('sellerRegisterationFlag', data.data.results.siteData[0].siteCategoryData[0].hasSellerRegisteration)
            window.sessionStorage.setItem('displayCity', data.data.results.siteData[0].siteCategoryData[0].display_city);
            window.sessionStorage.setItem('seoTitle', data.data.results.siteData[0].siteCategoryData[0].seo_title);
            window.sessionStorage.setItem('seoKeyword', data.data.results.siteData[0].siteCategoryData[0].seo_keyword);
            window.sessionStorage.setItem('seoDescription', data.data.results.siteData[0].siteCategoryData[0].seo_description);
            window.sessionStorage.setItem('groupName', data.data.results.siteData[0].siteCategoryData[0].group_name)
          }
          else {
            window.sessionStorage.setItem('countryCode', data.data.results.siteData[0].sellerData[0].countryCode);
            localStorage.setItem('hasLocation', data.data.results.siteData[0].sellerData[0].hasLocation);
            window.sessionStorage.setItem('seoTitle', data.data.results.siteData[0].sellerData[0].storeData[0].seo_title);
            window.sessionStorage.setItem('seoKeyword', data.data.results.siteData[0].sellerData[0].storeData[0].seo_keywords);
            window.sessionStorage.setItem('seoDescription', data.data.results.siteData[0].sellerData[0].storeData[0].seo_description);
          }
          window.sessionStorage.setItem('seoImage', data.data.results.siteData[0].logoPath);
          window.sessionStorage.setItem('seoUrl', data.data.results.siteData[0].siteUrl);
          if (data.data.results.siteData[0].sellerId !== 0 && data.data.results.siteData[0].sellerId !== null && (window.sessionStorage.getItem('storeId') === null || window.sessionStorage.getItem('storeId') === "null")) {
            window.sessionStorage.setItem('storeId', data.data.results.siteData[0].sellerData[0].storeData[0].storeId);
            window.sessionStorage.setItem('mainCategoryId', data.data.results.siteData[0].sellerData[0].storeData[0].mainCategoryId);
            window.sessionStorage.setItem('mainGroupCategory', data.data.results.siteData[0].sellerData[0].storeData[0].mainGroupCategoryId)
          }
          this.setState({
            isLoading: '1',
            siteData: data.data.results.siteData,
            themeId: data.data.results.siteData[0].themeId,
            sellerData: data.data.results.siteData[0].sellerData,
            storeData: data.data.results.siteData[0].storeData
          });
          $("#defaultBannerLoad").hide();
        }

      }
    });
  }
  componentDidMount() {
    if (window.isPageLoad === "" || typeof window.isPageLoad === "undefined") {
      window.isPageLoad = ""
    }
    let sellerNo = "";
    let storeNo = "";
    let siteUrl = "";
    let categoryId = "";
    if (this.props.match.params.sellerNo) {
      sellerNo = this.props.match.params.sellerNo;
      this.fetchSiteDetails("", "", sellerNo)
    }
    else if (this.props.match.params.storeNo) {
      storeNo = this.props.match.params.storeNo;
      this.fetchSiteDetails("", storeNo, "")
    }
    else if (this.props.match.params.categoryId) {
      categoryId = this.props.match.params.categoryId;
      this.fetchSiteDetails("", categoryId, "")
    }
    else if (this.props.match.params.siteUrl) {
      siteUrl = this.props.match.params.siteUrl;
      this.fetchSiteDetails("www.myyshopp", "", "")
    }
    else {
      var serverName = window.location.host
      var serverNameParts = serverName.split('.');
      if (serverNameParts.length === 2) {
        serverName = 'www.' + serverName;
      }
      this.fetchSiteDetails(serverName, "", "")
    }
  }
  render() {
    if (localStorage.getItem('siteId') === null) {
      $("#defaultBannerLoad").show();
    }
    return (
      <div>
        {console.log("111111", this.state.isLoading)}
        {this.state.isLoading === '1' &&
          <RootFileSub></RootFileSub>
        }
      </div>
    )
  }
}