import React, { Component } from 'react';
import CartBtn from "../headerComponents/addcartBtn"
import $ from "jquery";
import Savemore from "./savemore"
import Contact from "./contact"
import offer from "../images/icons/star.png"
import { Link, NavLink } from 'react-router-dom';
import ProductDefault from "../images/product-default.png"
import ReactPaginate from 'react-paginate';

export default class itemListingData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            itemPriceForSession: '',
            itemPackIdForSession: '',
            itemIdForSession: '',
            itemImageForSession: '',
            sisIdForSession: '',
            storeButtonName: '',
            ItemName: '',
            storeItemStockId: '',
            selected: Number(window.sessionStorage.getItem("ActiveageNumber2")),
            selectBoxValueCheck: 0
        };
        this.saveMore = this.saveMore.bind(this);
        this.contactForm = this.contactForm.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }
    handlePageChange(e) {
        var pageNumber = e.selected + 1;
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = "40";
        }
        else {
            offset = parseInt(pageNumber - 1) * 40;
            max = "40";
        }
        window.sessionStorage.setItem("ActiveageNumber2", e.selected)
        this.props.onFilterParent('', '', '', '', '', '', '', offset, max, pageNumber);
        window.scrollTo(0, 0)
    }
    contactForm(storeButtonName, ItemName, storeItemStockId) {
        this.setState({
            storeButtonName: storeButtonName,
            ItemName: ItemName,
            storeItemStockId: storeItemStockId
        })
        $(".contactFormPopUp").show();
    }
    saveMore = (no, cityId) => {
        let storeId = window.sessionStorage.getItem('storeId');
        let sisId = $("#sisId" + no).val();
        var itemId = $("#itemId" + no).val();
        var price = $("#price" + no).val();
        var itemName = $("#itemName" + no).text();
        var unit = $("#unit" + no).val();
        this.setState({
            sisId: sisId,
            itemId: itemId,
            price: price,
            itemName: itemName,
            unit: unit,
            cityId: cityId,
            storeId: storeId,
            isSaveMore: '1',
        });
        $(".savemorePopUp").show();
    }
    setValueOnLoosePackChange = (storeId, no, mrp, price, unit, currencySymbol, event) => {
        var pno = $("#selPack" + no).val();
        $("#hidPackIndex" + no).val(pno)
        var storeItemStockId = $("#sisId" + pno).val();
        var itemName = $("#itemName" + pno).val();
        var itemId = $("#itemId" + pno).val();
        var stockQuantity = $("#itemStockQuantity" + pno).val();
        var netAmount = $("#price" + pno).val();
        var packId = $("#packId" + pno).val();
        var mrp = $("#mrp" + pno).val();
        var image = $("#image" + pno).val();
        $("#itemPricespan" + no).text(currencySymbol + " " + parseFloat(netAmount).toFixed(window.sessionStorage.getItem('decimalCount')));
        $("#itemMrpspan" + no).text(currencySymbol + " " + parseFloat(mrp).toFixed(window.sessionStorage.getItem('decimalCount')));
        this.refs.groceryCart.updateCart(itemName, itemId, $("#txtQty" + no).val(), netAmount, storeItemStockId, packId, false, stockQuantity, image)
    }
    setValueOnPackChange = (no, sisId, itemId, price, packId, mrp, logoPath, stockQuantity, swapItem, itemPath, itemSrcPath, unit, isoffer, offerText, currencySymbol, event, ind, itemoffset, is_display_item_price) => {
        var pno = $("#selPack" + no).val();
        var index = (ind + itemoffset) + '-' + pno
        $("#hidPackIndex" + no).val(index)
        $("#sisId" + no).val(sisId.split('|')[pno]);
        $("#itemId" + no).val(itemId.split('|')[pno]);
        $("#price" + no).val(price.split('|')[pno]);
        $("#unit" + no).val(unit.split('|')[pno]);
        $("#packId" + no).val(packId.split('|')[pno]);
        $("#itemMrp" + no).val(mrp.split('|')[pno]);
        var itemId = itemId.split('|')[pno]
        var sisId = sisId.split('|')[pno]
        if (is_display_item_price === true) {
            $("#itemPricespan" + no).text(currencySymbol + " " + parseFloat(price.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount')));
            $("#itemMrpspan" + no).text(currencySymbol + " " + parseFloat(mrp.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount')));
        }
        else {
            $("#itemPricespan" + no).text(currencySymbol + " " + parseFloat(mrp.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount')));
        }
        $("#itemStockQuantity" + no).text(stockQuantity.split('|')[pno]);
        $("#image" + no).attr("src", itemSrcPath + '/' + itemPath.split('|')[pno]);
        if (swapItem.split('|')[pno] === "1") {
            $("#swap" + no).show();
        }
        else {
            $("#swap" + no).hide();
        }
        if (isoffer.split('|')[pno] === "1") {
            $("#offerSpan" + no).show();
        }
        else {
            $("#offerSpan" + no).hide();
        }
    }
    componentDidUpdate() {
        debugger
        $(".saveClose").click(function () {
            this.setState({
                isSaveMore: '0',
            })
            $(".savemorePopUp").hide();
        }.bind(this));
        $(".popOverlay").click(function () {
            this.setState({
                isSaveMore: '0',
            })
            $(".popupWrap").hide();
        }.bind(this));
    }
    addDefaultSrc(ev) {
        ev.target.src = ProductDefault
    }
    render() {
        $(".popOverlay").click(function () {
            $(".popupWrap").hide();
        });
        let itemOffset;
        itemOffset = this.props.itemOffset;
        let themeId = localStorage.getItem('themeId')
        return (
            <div>
                <div className={themeId === '30' ? "itemListSection productItemListing row" : "itemListSection productItemListing"}>
                    {this.props.itemDetails.map((similar, index) => (
                        <div className={themeId === '30' ? "productList col-lg-4" : "productList"}>
                            {similar.finalDataSetSub.map((pack_names, packIndex) => (
                                <div>
                                    <input type="hidden" id={"sisId" + (index + itemOffset + '-' + packIndex)} value={pack_names.store_item_stock_ids} />
                                    <input type="hidden" id={"itemId" + (index + itemOffset + '-' + packIndex)} value={pack_names.item_ids} />
                                    <input type="hidden" id={"price" + (index + itemOffset + '-' + packIndex)} value={pack_names.item_prices} />
                                    <input type="hidden" id={"unit" + (index + itemOffset + '-' + packIndex)} value={pack_names.unit} />
                                    <input type="hidden" id={"packId" + (index + itemOffset + '-' + packIndex)} value={pack_names.pack_ids} />
                                    <input type="hidden" id={"itemStockQuantity" + (index + itemOffset + '-' + packIndex)} value={pack_names.stock_quantities} />
                                    <input type="hidden" id={"itemName" + (index + itemOffset + '-' + packIndex)} value={pack_names.item_names} />
                                    <input type="hidden" id={"mrp" + (index + itemOffset + '-' + packIndex)} value={pack_names.mrps} />
                                    <input type="hidden" id={"image" + (index + itemOffset + '-' + packIndex)} value={this.props.imagePath + '/' + pack_names.logo_path_thumbs} />
                                </div>
                            ))}
                            <input type="hidden" id={"sisId" + parseInt(index + itemOffset)} value={similar.finalDataSetSub[0].store_item_stock_ids} />
                            <input type="hidden" id={"itemId" + parseInt(index + itemOffset)} value={similar.finalDataSetSub[0].item_ids} />
                            <input type="hidden" id={"price" + parseInt(index + itemOffset)} value={similar.finalDataSetSub[0].item_prices} />
                            <input type="hidden" id={"unit" + parseInt(index + itemOffset)} value={similar.finalDataSetSub[0].unit} />
                            <input type="hidden" id={"itemStockQuantity" + parseInt(index + itemOffset)} value={similar.finalDataSetSub[0].stock_quantities} />
                            <div className="productItem">
                                {similar.finalDataSetSub[0].is_offers === "1" ?
                                    <span id={'offerSpan' + parseInt(index + itemOffset)} className="offerText"><img src={offer} alt="Offer" /> {similar.finalDataSetSub[0].offer_text}</span>
                                    : ""}
                                <div className="productImage">
                                    <Link to={{ pathname: "/" + similar.finalDataSetSub[0].slug }}>
                                        <img onError={this.addDefaultSrc} id={'image' + parseInt(index + itemOffset)} alt={similar.finalDataSetSub[0].item_names} src={this.props.imagePath + '/' + similar.finalDataSetSub[0].logo_path_thumbs} />
                                    </Link>
                                </div>
                                <div className="productDetails">
                                    <div className="brandName">{similar.brand_name}</div>
                                    <div className="productName">
                                        <NavLink className="elipsis" id={'itemNameTitle' + parseInt(index + itemOffset)} title={similar.finalDataSetSub[0].item_names} to={{ pathname: "/" + similar.finalDataSetSub[0].slug }}>{similar.finalDataSetSub[0].item_names}</NavLink>
                                    </div>
                                    <div className="productQty">
                                        <div>
                                            <input type="hidden" id={"hidPackIndex" + parseInt(index + itemOffset)} value={index + itemOffset + '-' + 0} />
                                            {(() => {
                                                if (similar.is_loose_item === true) {
                                                    return (
                                                        <select id={'selPack' + parseInt(index + itemOffset)} onChange={(e) => this.setValueOnLoosePackChange(similar.store_id, parseInt(index + itemOffset), similar.finalDataSetSub[similar.finalDataSetSub.length - 1].mrps, similar.finalDataSetSub[similar.finalDataSetSub.length - 1].item_prices, similar.finalDataSetSub[similar.finalDataSetSub.length - 1].unit, this.props.currencySymbol, e)}>
                                                            {similar.finalDataSetSub.map((pack_names, packIndex) => (
                                                                <option value={index + itemOffset + '-' + packIndex}>{pack_names.pack_names} - {this.props.currencySymbol} {parseFloat(pack_names.item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                                            ))}
                                                        </select>
                                                    )
                                                }
                                                else if (similar.group_count === '1') {
                                                    return (
                                                        <div>
                                                            {similar.finalDataSetSub.map((pack_names) => (
                                                                pack_names.pack_names
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <select id={'selPack' + parseInt(index + itemOffset)} onChange={(e) => this.setValueOnPackChange(parseInt(index + itemOffset), similar.store_item_stock_ids_with_seperator, similar.item_ids_with_seperator, similar.item_prices_with_seperator, similar.pack_ids_with_seperator, similar.mrps_with_seperator, similar.logoPath_with_seperator, similar.stock_quantities_with_seperator, similar.is_swap_items_with_seperator, similar.logo_path_thumbs_with_seperator, this.props.imagePath, similar.unit_with_seperator, similar.is_offers_with_seperator, similar.offer_text_with_seperator, this.props.currencySymbol, e, index, itemOffset, similar.is_display_item_price)}>
                                                            {similar.finalDataSetSub.map((pack_names, packno) => (
                                                                <option value={packno}>{pack_names.pack_names} - {this.props.currencySymbol} {parseFloat(pack_names.item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                                            ))}
                                                        </select>
                                                    )
                                                }
                                            })()}
                                        </div>
                                    </div>
                                    <div className="productPrice">
                                        {similar.hasContactUsWithoutPrice === false ?
                                            <>
                                                {similar.is_display_item_price === true ?
                                                    <div>
                                                        {similar.finalDataSetSub[0].item_prices > 0 ?
                                                            <span id={'itemPricespan' + parseInt(index + itemOffset)} className="newPrice">{this.props.currencySymbol} {parseFloat(similar.finalDataSetSub[0].item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))} </span>
                                                            : ""}
                                                        <span style={{ display: ((similar.finalDataSetSub[0].mrps > similar.finalDataSetSub[0].item_prices) ? 'block' : 'none') }} id={'itemMrpspan' + parseInt(index + itemOffset)} className="oldPrice">{this.props.currencySymbol}  {parseFloat(similar.finalDataSetSub[0].mrps).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                                    </div>
                                                    :
                                                    <div>
                                                        {similar.finalDataSetSub[0].item_prices > 0 ?
                                                            <span id={'itemPricespan' + parseInt(index + itemOffset)} className="newPrice">{this.props.currencySymbol} {parseFloat(similar.finalDataSetSub[0].mrps).toFixed(window.sessionStorage.getItem('decimalCount'))} </span>
                                                            : ""}
                                                    </div>
                                                }
                                            </>
                                            : ""}
                                    </div>
                                    <div className="itemCountNumber"></div>
                                    <div className="addCartButton">
                                        {(() => {
                                            if (similar.finalDataSetSub[0].is_swap_items === true) {
                                                if (similar.hasBuyOption) {
                                                    return (
                                                        <div id={'swap' + parseInt(index + itemOffset)} className="saveMore" onClick={(e) => this.saveMore(parseInt(index + itemOffset), this.props.cityId)}>Save more</div>
                                                    )
                                                }
                                            }
                                        })()}
                                        {(() => {
                                            if (similar.hasContactUsWithoutPrice === true) {
                                                if (similar.storeButtonName) {
                                                    return (
                                                        <span class="cartButton" onClick={(e) => this.contactForm(similar.storeButtonName, similar.finalDataSetSub[0].item_names, similar.finalDataSetSub[0].store_item_stock_ids)}>{similar.storeButtonName}</span>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <span class="cartButton" onClick={(e) => this.contactForm(similar.storeButtonName, similar.finalDataSetSub[0].item_names, similar.finalDataSetSub[0].store_item_stock_ids)}>Contact Us</span>
                                                    )
                                                }
                                            }
                                            else if (similar.hasBuyOption === true) {
                                                if (similar.finalDataSetSub[0].mrps !== 0 || similar.finalDataSetSub[0].mrps <= 0) {
                                                    return (
                                                        <CartBtn
                                                            fromPage={this.props.fromPage}
                                                            itemLoad={this.props.itemLoad}
                                                            index={parseInt(index + itemOffset)}
                                                            itemOffset={itemOffset}
                                                            item_names={similar.finalDataSetSub[0].item_names}
                                                            item_ids={(() => {
                                                                if (similar.is_loose_item === true) {
                                                                    return (
                                                                        similar.finalDataSetSub[0].item_ids
                                                                    )
                                                                }
                                                                else if (similar.group_count === '1') {
                                                                    return (
                                                                        similar.finalDataSetSub[0].item_ids
                                                                    )
                                                                }
                                                                else {
                                                                    if (this.state.itemIdForSession === "") {
                                                                        return (
                                                                            similar.finalDataSetSub[0].item_ids
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            this.state.itemIdForSession
                                                                        )
                                                                    }
                                                                }
                                                            })()}
                                                            item_prices={(() => {
                                                                if (similar.is_loose_item === true) {
                                                                    if (this.state.itemPriceForSession === "") {
                                                                        return (
                                                                            similar.finalDataSetSub[0].item_prices
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            this.state.itemPriceForSession
                                                                        )
                                                                    }
                                                                }
                                                                else if (similar.group_count === '1') {
                                                                    return (
                                                                        similar.finalDataSetSub[0].item_prices
                                                                    )
                                                                }
                                                                else {
                                                                    if (this.state.itemPriceForSession === "") {
                                                                        return (
                                                                            similar.finalDataSetSub[0].item_prices
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            this.state.itemPriceForSession
                                                                        )
                                                                    }
                                                                }
                                                            })()}
                                                            store_item_stock_ids={(() => {
                                                                if (similar.is_loose_item === true) {
                                                                    return (
                                                                        similar.finalDataSetSub[0].store_item_stock_ids
                                                                    )
                                                                }
                                                                else if (similar.group_count === '1') {
                                                                    return (
                                                                        similar.finalDataSetSub[0].store_item_stock_ids
                                                                    )
                                                                }
                                                                else {
                                                                    if (this.state.sisIdForSession === "") {
                                                                        return (
                                                                            similar.finalDataSetSub[0].store_item_stock_ids
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            this.state.sisIdForSession
                                                                        )
                                                                    }
                                                                }
                                                            })()}
                                                            pack_ids={(() => {
                                                                if (similar.is_loose_item === true) {
                                                                    return (
                                                                        similar.finalDataSetSub[0].pack_ids
                                                                    )
                                                                }
                                                                else if (similar.group_count === '1') {
                                                                    return (
                                                                        similar.finalDataSetSub[0].pack_ids
                                                                    )
                                                                }
                                                                else {
                                                                    if (this.state.itemPackIdForSession === "") {
                                                                        return (
                                                                            similar.finalDataSetSub[0].pack_ids
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            this.state.itemPackIdForSession
                                                                        )
                                                                    }
                                                                }
                                                            })()}
                                                            stock_quantity={similar.finalDataSetSub[0].stock_quantities}
                                                            itemAddedColour="itemAddedColour"
                                                            qtyError="qtyError"
                                                            itemCount="itemCount"
                                                            cartLabel={similar.cartLabel}
                                                            addcartActive={similar.addcartActive}
                                                            isCartItemOpenClass={similar.isCartItemOpenClass}
                                                            cartItemFlag={similar.cartItems.length > 0 ? true : false}
                                                            cartItemQuantity={similar.cartItems.length > 0 ? similar.cartItems[0].quantity : 1}
                                                            itemImage={(() => {
                                                                if (similar.is_loose_item === true) {
                                                                    return (
                                                                        this.props.imagePath + '/' + similar.finalDataSetSub[0].logo_path_thumbs
                                                                    )
                                                                }
                                                                else if (similar.group_count === '1') {
                                                                    return (
                                                                        this.props.imagePath + '/' + similar.finalDataSetSub[0].logo_path_thumbs
                                                                    )
                                                                }
                                                                else {
                                                                    if (this.state.itemImageForSession === "") {
                                                                        return (
                                                                            this.props.imagePath + '/' + similar.finalDataSetSub[0].logo_path_thumbs
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            this.state.itemImageForSession
                                                                        )
                                                                    }
                                                                }
                                                            })()}
                                                            ref="groceryCart"
                                                        ></CartBtn>
                                                    )
                                                }
                                                else {
                                                    if (similar.storeButtonName) {
                                                        return (
                                                            <span class="cartButton">{similar.storeButtonName}</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span class="cartButton">Contact Us</span>
                                                        )
                                                    }
                                                }
                                            }
                                            else if (similar.hasBuyOption === false) {
                                                if (similar.storeButtonName) {
                                                    return (
                                                        <span class="cartButton">{similar.storeButtonName}</span>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <span class="cartButton">Contact Us</span>
                                                    )
                                                }
                                            }
                                        })()}
                                    </div>
                                </div>
                                {(() => {
                                    if (similar.cartItems.length === 0) {
                                        return (
                                            <div>
                                                <div className="showItemDisplayColor" id={"itemAddedColour" + parseInt(index + itemOffset)}><span className="itemAddCount" id={"itemCount" + parseInt(index + itemOffset)}>{ }</span></div>
                                                <div className="itemAlertText" id={'qtyError' + parseInt(index + itemOffset)}></div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div>
                                                <div className={"showItemDisplayColor itemAdded itemDisplay " + similar.itemAdded} id={"itemAddedColour" + parseInt(index + itemOffset)}><span className="itemAddCount" id={"itemCount" + parseInt(index + itemOffset)}>{similar.cartItems[0].quantity}</span></div>
                                                <div className="itemAlertText" id={'qtyError' + parseInt(index + itemOffset)}></div>
                                            </div>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    ))}
                    <div className="paginationSection">
                        {this.props.rowCount > 40 ?
                            <ReactPaginate
                                nextLabel=">>"
                                onPageChange={this.handlePageChange}
                                pageRangeDisplayed={10}
                                marginPagesDisplayed={1}
                                pageCount={this.props.rowCount / 48}
                                previousLabel="<<"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                forcePage={this.state.selected}
                            />
                            : ''}
                    </div>
                    <div className="showMoreDiv">
                        <span className="showMore">Show more</span>
                    </div>
                    <div class="popupWrap savemorePopUp">
                        {this.state.isSaveMore === '1' ?
                            <Savemore
                                sisId={this.state.sisId}
                                itemId={this.state.itemId}
                                price={this.state.price}
                                unit={this.state.unit}
                                cityId={this.state.cityId}
                                storeId={this.state.storeId}
                                flag='1'
                            >
                            </Savemore>
                            : ''}
                    </div>
                    <div class="popupWrap contactFormPopUp">
                        <Contact storeButtonName={this.state.storeButtonName} ItemName={this.state.ItemName} storeItemStockId={this.state.storeItemStockId}></Contact>
                    </div>
                </div>
            </div>
        )
    }
}
