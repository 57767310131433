import React, { Component } from 'react';
import Slider from 'react-slick';
import $ from "jquery";
import CartBtn from "../headerComponents/addcartBtn"
import offer from "../images/icons/star.png"
import Contact from "../itemlisting/contact"
import Savemore from "../itemlisting/savemore"
import { Link, NavLink } from 'react-router-dom';
export default class similarItems extends Component {
    constructor(props) {
        super(props)
        this.state = {
            similarProductsItems: [],
            storeResults: [],
            itemPriceForSession: '',
            itemPackIdForSession: '',
            itemIdForSession: '',
            itemImageForSession: '',
            sisIdForSession: '',
            storeButtonName: '',
            ItemName: '',
            storeItemStockId: '',
            isSaveMore: '0',
            selectBoxValueCheck: 0
        };
        this.saveMore = this.saveMore.bind(this);
        this.contactForm = this.contactForm.bind(this);
    }
    componentDidMount() {
    }
    componentDidUpdate() {
        $(".saveClose").click(function () {
            this.setState({
                isSaveMore: '0',
            })
            $(".savemorePopUpSimilar").hide();
        }.bind(this));
        $(".popOverlay").click(function () {
            this.setState({
                isSaveMore: '0',
            })
            $(".popupWrap").hide();
        }.bind(this));
    }
    contactForm(storeButtonName, ItemName, storeItemStockId) {
        this.setState({
            storeButtonName: storeButtonName,
            ItemName: ItemName,
            storeItemStockId: storeItemStockId
        })
        $(".contactFormPopUp").show();
    }
    saveMore(no, cityId) {
        let storeId = window.sessionStorage.getItem('storeId');
        var sisId = $("#sisId" + no).val();
        var itemId = $("#itemId" + no).val();
        var price = $("#price" + no).val();
        var itemName = $("#itemName" + no).text();
        var unit = $("#unit" + no).val();
        this.setState({
            sisId: sisId,
            itemId: itemId,
            price: price,
            itemName: itemName,
            unit: unit,
            cityId: cityId,
            storeId: storeId,
            isSaveMore: '1'
        });
        $(".savemorePopUpSimilar").show();
    }
    setValueOnLoosePackChange = (storeId, no, mrp, price, unit, currencySymbol, event) => {
        var pno = $("#selPack" + no).val();
        $("#hidPackIndex" + no).val(pno)
        var netAmount = $("#price" + pno).val();
        var mrps = $("#mrp" + pno).val();
        $("#itemPricespan" + no).text(currencySymbol + " " + parseFloat(netAmount).toFixed(window.sessionStorage.getItem('decimalCount')));
        $("#itemMrpspan" + no).text(currencySymbol + " " + parseFloat(mrps).toFixed(window.sessionStorage.getItem('decimalCount')));
    }
    setValueOnPackChange = (no, sisId, itemId, price, packId, mrp, logoPath, stockQuantity, swapItem, itemPath, itemSrcPath, unit, isoffer, offerText, currencySymbol, event) => {
        var pno = $("#selPack" + no).val();
        var index = no + '-' + pno
        $("#hidPackIndex" + no).val(index)
        $("#sisId" + no).val(sisId.split('|')[pno]);
        $("#itemId" + no).val(itemId.split('|')[pno]);
        $("#price" + no).val(price.split('|')[pno]);
        $("#unit" + no).val(unit.split('|')[pno]);
        $("#packId" + no).val(packId.split('|')[pno]);
        $("#itemMrp" + no).val(mrp.split('|')[pno]);
        itemId = itemId.split('|')[pno];
        sisId = sisId.split('|')[pno];
        $("#itemPricespan" + no).text(currencySymbol + " " + parseFloat(price.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount')));
        $("#itemMrpspan" + no).text(currencySymbol + " " + parseFloat(mrp.split('|')[pno]).toFixed(window.sessionStorage.getItem('decimalCount')));
        $("#itemStockQuantity" + no).text(stockQuantity.split('|')[pno]);
        $("#image" + no).attr("src", itemSrcPath + '/' + itemPath.split('|')[pno]);
        if (swapItem.split('|')[pno] === "1") {
            $("#swap" + no).show();
        }
        else {
            $("#swap" + no).hide();
        }
        if (isoffer.split('|')[pno] === "1") {
            $("#offerSpan" + no).show();
        }
        else {
            $("#offerSpan" + no).hide();
        }
    }
    render() {
        let themeId = localStorage.getItem('themeId')
        let cartLabel = 'ADD TO CART'
        var sliderCount = 6
        if (themeId === '36') {
            cartLabel = '+'
        }
        else if (themeId === '35') {
            sliderCount = 5
        }
        else if (themeId === '34'|| themeId === '29') {
            sliderCount = 4
            cartLabel = '<span><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" /> ADD TO CART</span>'
        }
        const itemSetting = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: sliderCount,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        swipeToSlide: true,
                        speed: 200
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        swipeToSlide: true
                    }
                }
            ]
        };
        return (
            this.props.similarProductsItems.length > 0 ?
                <div className="relatedSlider">
                    <div className={(themeId === '34'|| themeId === '29') ? "container" : ""}>
                        <div className="subTitle">Similar Products</div>
                        <Slider {...itemSetting}>
                            {this.props.similarProductsItems.map((similar, index) => (
                                <div>
                                    {similar.finalDataSetSub.map((pack_names, packIndex) => (
                                        <div>
                                            <input type="hidden" id={"sisId" + (index + 200 + '-' + packIndex)} value={pack_names.store_item_stock_ids} />
                                            <input type="hidden" id={"itemId" + (index + 200 + '-' + packIndex)} value={pack_names.item_ids} />
                                            <input type="hidden" id={"price" + (index + 200 + '-' + packIndex)} value={pack_names.item_prices} />
                                            <input type="hidden" id={"unit" + (index + 200 + '-' + packIndex)} value={pack_names.unit} />
                                            <input type="hidden" id={"packId" + (index + 200 + '-' + packIndex)} value={pack_names.pack_ids} />
                                            <input type="hidden" id={"itemStockQuantity" + (index + 200 + '-' + packIndex)} value={pack_names.stock_quantities} />
                                            <input type="hidden" id={"itemName" + (index + 200 + '-' + packIndex)} value={pack_names.item_names} />
                                            <input type="hidden" id={"mrp" + (index + 200 + '-' + packIndex)} value={pack_names.mrps} />
                                            <input type="hidden" id={"image" + (index + 200 + '-' + packIndex)} value={this.props.storeResults[0].imagePath + '/' + pack_names.logo_path_thumbs} />
                                        </div>
                                    ))}
                                    <input type="hidden" id={"sisId" + (parseInt(index) + 200)} value={similar.finalDataSetSub[0].store_item_stock_ids} />
                                    <input type="hidden" id={"itemId" + (parseInt(index) + 200)} value={similar.finalDataSetSub[0].item_ids} />
                                    <input type="hidden" id={"price" + (parseInt(index) + 200)} value={similar.finalDataSetSub[0].item_prices} />
                                    <input type="hidden" id={"unit" + (parseInt(index) + 200)} value={similar.finalDataSetSub[0].unit} />
                                    <input type="hidden" id={"itemStockQuantity" + (parseInt(index) + 200)} value={similar.finalDataSetSub[0].stock_quantities} />
                                    <div className="productItem">
                                        {similar.finalDataSetSub[0].is_offers === "1" ?
                                            <span id={'offerSpan' + (parseInt(index) + 200)} className="offerText"><img src={offer} alt="mouse"/> {similar.finalDataSetSub[0].offer_text}</span>
                                            : ""}
                                        <div className="productImage">
                                            <Link to={{ pathname: "/" + similar.finalDataSetSub[0].slug }}>
                                                <img id={'image' + (parseInt(index) + 200)} alt={similar.finalDataSetSub[0].item_names} src={this.props.storeResults[0].imagePath + '/' + similar.finalDataSetSub[0].logo_path_thumbs}  />
                                            </Link>
                                        </div>
                                        <div className="productDetails">
                                            <div className="brandName">{similar.brand_name}</div>
                                            <div className="productName">
                                                <NavLink className="elipsis" id={'itemNameTitle' + (parseInt(index) + 200)} title={similar.finalDataSetSub[0].item_names} to={{ pathname: "/" + similar.finalDataSetSub[0].slug }}>{similar.finalDataSetSub[0].item_names}</NavLink>
                                            </div>
                                            <div className="productQty">
                                                <div>
                                                    <input type="hidden" id={"hidPackIndex" + parseInt(index + 200)} value={index + 200 + '-' + 0} />
                                                    {(() => {
                                                        if (similar.is_loose_item === true) {
                                                            return (
                                                                <select id={'selPack' + (parseInt(index) + 200)} onChange={(e) => this.setValueOnLoosePackChange(similar.store_id, (parseInt(index) + 200), similar.finalDataSetSub[similar.finalDataSetSub.length - 1].mrps, similar.finalDataSetSub[similar.finalDataSetSub.length - 1].item_prices, similar.finalDataSetSub[similar.finalDataSetSub.length - 1].unit, this.props.storeResults[0].currencySymbol, e)}>
                                                                    {similar.finalDataSetSub.map((pack_names, packIndex) => (
                                                                        <option value={index + 200 + '-' + packIndex}>{pack_names.pack_names} - {this.props.storeResults[0].currencySymbol} {parseFloat(pack_names.item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                                                    ))}
                                                                </select>
                                                            )
                                                        }
                                                        else if (similar.group_count === '1') {
                                                            return (
                                                                <div>
                                                                    {similar.finalDataSetSub.map((pack_names) => (
                                                                        pack_names.pack_names
                                                                    ))}
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <select id={'selPack' + (parseInt(index) + 200)} onChange={(e) => this.setValueOnPackChange((parseInt(index) + 200), similar.store_item_stock_ids_with_seperator, similar.item_ids_with_seperator, similar.item_prices_with_seperator, similar.pack_ids_with_seperator, similar.mrps_with_seperator, similar.logoPath_with_seperator, similar.stock_quantities_with_seperator, similar.is_swap_items_with_seperator, similar.logo_path_thumbs_with_seperator, this.props.storeResults[0].imagePath, similar.unit_with_seperator, similar.is_offers_with_seperator, similar.offer_text_with_seperator, this.props.storeResults[0].currencySymbol, e)}>
                                                                    {similar.finalDataSetSub.map((pack_names, packno) => (
                                                                        <option value={packno}>{pack_names.pack_names} - {this.props.storeResults[0].currencySymbol} {parseFloat(pack_names.item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                                                    ))}
                                                                </select>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                            <div className="productPrice">
                                                {similar.hasContactUsWithoutPrice === false ?
                                                    <div>
                                                        {parseFloat(similar.finalDataSetSub[0].item_prices) > 0 ?
                                                            <span id={'itemPricespan' + (parseInt(index) + 200)} className="newPrice">{this.props.storeResults[0].currencySymbol} {parseFloat(similar.finalDataSetSub[0].item_prices).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                                            : ""}
                                                        {parseFloat(similar.finalDataSetSub[0].mrps) > parseFloat(similar.finalDataSetSub[0].item_prices) ?
                                                            <span id={'itemMrpspan' + (parseInt(index) + 200)} className="oldPrice">{this.props.storeResults[0].currencySymbol}  {parseFloat(similar.finalDataSetSub[0].mrps).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                                            : ""}
                                                    </div>
                                                    : ""}
                                            </div>
                                            <div className="itemCountNumber"></div>
                                            <div className="addCartButton">
                                                {(() => {
                                                    if (similar.finalDataSetSub[0].is_swap_items === true) {
                                                        if (similar.hasBuyOption) {
                                                            return (
                                                                <div id={'swap' + (parseInt(index) + 200)} className="saveMore" onClick={(e) => this.saveMore((parseInt(index) + 200), this.props.storeResults[0].cityId)}>Save more</div>
                                                            )
                                                        }
                                                    }
                                                })()}
                                                {(() => {
                                                    if (similar.hasContactUsWithoutPrice === true) {
                                                        if (similar.storeButtonName) {
                                                            return (
                                                                <span class="cartButton" onClick={(e) => this.contactForm(similar.storeButtonName, similar.finalDataSetSub[0].item_names, similar.finalDataSetSub[0].store_item_stock_ids)}>{similar.storeButtonName}</span>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <span class="cartButton" onClick={(e) => this.contactForm(similar.storeButtonName, similar.finalDataSetSub[0].item_names, similar.finalDataSetSub[0].store_item_stock_ids)}>Contact Us</span>
                                                            )
                                                        }
                                                    }
                                                    else if (similar.hasBuyOption === true) {
                                                        if (similar.finalDataSetSub[0].mrps !== 0 || similar.finalDataSetSub[0].mrps <= 0) {
                                                            return (
                                                                <CartBtn
                                                                    index={(parseInt(index) + 200)}
                                                                    itemOffset={200}
                                                                    item_names={similar.finalDataSetSub[0].item_names}
                                                                    item_ids={(() => {
                                                                        if (similar.is_loose_item === true) {
                                                                            return (
                                                                                similar.finalDataSetSub[0].item_ids
                                                                            )
                                                                        }
                                                                        else if (similar.group_count === '1') {
                                                                            return (
                                                                                similar.finalDataSetSub[0].item_ids
                                                                            )
                                                                        }
                                                                        else {
                                                                            if (this.state.itemIdForSession === "") {
                                                                                return (
                                                                                    similar.finalDataSetSub[0].item_ids
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    this.state.itemIdForSession
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                    item_prices={(() => {
                                                                        if (similar.is_loose_item === true) {
                                                                            if (this.state.itemPriceForSession === "") {
                                                                                return (
                                                                                    similar.finalDataSetSub[0].item_prices
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    this.state.itemPriceForSession
                                                                                )
                                                                            }
                                                                        }
                                                                        else if (similar.group_count === '1') {
                                                                            return (
                                                                                similar.finalDataSetSub[0].item_prices
                                                                            )
                                                                        }
                                                                        else {
                                                                            if (this.state.itemPriceForSession === "") {
                                                                                return (
                                                                                    similar.finalDataSetSub[0].item_prices
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    this.state.itemPriceForSession
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                    store_item_stock_ids={(() => {
                                                                        if (similar.is_loose_item === true) {
                                                                            return (
                                                                                similar.finalDataSetSub[0].store_item_stock_ids
                                                                            )
                                                                        }
                                                                        else if (similar.group_count === '1') {
                                                                            return (
                                                                                similar.finalDataSetSub[0].store_item_stock_ids
                                                                            )
                                                                        }
                                                                        else {
                                                                            if (this.state.sisIdForSession === "") {
                                                                                return (
                                                                                    similar.finalDataSetSub[0].store_item_stock_ids
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    this.state.sisIdForSession
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                    pack_ids={(() => {
                                                                        if (similar.is_loose_item === true) {
                                                                            return (
                                                                                similar.finalDataSetSub[0].pack_ids
                                                                            )
                                                                        }
                                                                        else if (similar.group_count === '1') {
                                                                            return (
                                                                                similar.finalDataSetSub[0].pack_ids
                                                                            )
                                                                        }
                                                                        else {
                                                                            if (this.state.itemPackIdForSession === "") {
                                                                                return (
                                                                                    similar.finalDataSetSub[0].pack_ids
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    this.state.itemPackIdForSession
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                    stock_quantity={similar.finalDataSetSub[0].stock_quantities}
                                                                    itemAddedColour="itemAddedColour"
                                                                    qtyError="qtyError"
                                                                    itemCount="itemCount"
                                                                    cartItemFlag={similar.cartItems.length > 0 ? true : false}
                                                                    cartLabel={cartLabel}
                                                                    fromPage="Profile"
                                                                    cartItemQuantity={similar.cartItems.length > 0 ? similar.cartItems[0].quantity : 1}
                                                                    itemImage={(() => {
                                                                        if (similar.is_loose_item === true) {
                                                                            return (
                                                                                this.props.storeResults[0].imagePath + '/' + similar.finalDataSetSub[0].logo_path_thumbs
                                                                            )
                                                                        }
                                                                        else if (similar.group_count === '1') {
                                                                            return (
                                                                                this.props.storeResults[0].imagePath + '/' + similar.finalDataSetSub[0].logo_path_thumbs
                                                                            )
                                                                        }
                                                                        else {
                                                                            if (this.state.itemImageForSession === "") {
                                                                                return (
                                                                                    this.props.storeResults[0].imagePath + '/' + similar.finalDataSetSub[0].logo_path_thumbs
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    this.state.itemImageForSession
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                ></CartBtn>
                                                            )
                                                        }
                                                        else {
                                                            if (similar.storeButtonName) {
                                                                return (
                                                                    <span class="cartButton">{similar.storeButtonName}</span>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <span class="cartButton">Contact Us</span>
                                                                )
                                                            }
                                                        }
                                                    }
                                                    else if (similar.hasBuyOption === false) {
                                                        if (similar.storeButtonName) {
                                                            return (
                                                                <span class="cartButton">{similar.storeButtonName}</span>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <span class="cartButton">Contact Us</span>
                                                            )
                                                        }
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                        {(() => {
                                            if (similar.cartItems.length === 0) {
                                                return (
                                                    <div>
                                                        <div className="showItemDisplayColor" id={"itemAddedColour" + (parseInt(index) + 200)}><span className="itemAddCount" id={"itemCount" + (parseInt(index) + 200)}>{ }</span></div>
                                                        <div className="itemAlertText" id={'qtyError' + (parseInt(index) + 200)}></div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div>
                                                        <div className={"showItemDisplayColor itemAdded itemDisplay "} id={"itemAddedColour" + (parseInt(index) + 200)}><span className="itemAddCount" id={"itemCount" + (parseInt(index) + 200)}>{similar.cartItems[0].quantity}</span></div>
                                                        <div className="itemAlertText" id={'qtyError' + (parseInt(index) + 200)}></div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div class="popupWrap contactFormPopUp">
                            <Contact storeButtonName={this.state.storeButtonName} ItemName={this.state.ItemName} storeItemStockId={this.state.storeItemStockId}></Contact>
                        </div>
                        <div class="popupWrap savemorePopUp savemorePopUpSimilar">
                            {this.state.isSaveMore === '1' ?
                                <Savemore
                                    sisId={this.state.sisId}
                                    itemId={this.state.itemId}
                                    price={this.state.price}
                                    unit={this.state.unit}
                                    cityId={this.state.cityId}
                                    storeId={this.state.storeId}
                                    flag='1'
                                >
                                </Savemore>
                                : ''}
                        </div>
                    </div>
                </div>
                :
                ''
        )
    }
}
