import React, { Component ,Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.css'

import "../css/style.css"
import "../css/responsive.css"
// import LokalInHeader from "../lokalIn/header"
// import LokalFooter from "../lokalIn/footer"
import Banner from "./banner"
import Steps from "./steps"
import RegisterText from "./registerText"
let LokalInHeader = React.lazy(() => import("../lokalIn/header"));
let LokalFooter = React.lazy(() => import("../lokalIn/footer"));
export default class Home extends Component {
    render() {
        window.scrollTo(0, 0)
         return (
            <div className="coverWraper lklSellerWrap">
                <Suspense fallback={<div></div>}>
                <LokalInHeader/>
                <div className="lkcontentWrap">
                    <Banner />
                    <Steps />
                    <RegisterText />
                </div>
                <LokalFooter/>
                </Suspense>
            </div>
        )
    }
}