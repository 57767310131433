import React, { Component, Suspense } from 'react';
import Slider from 'react-slick';
import CartBtn from "../commonItem/addcartBtn"
import { getData, pageHitAPICall } from '../services';
import { Link } from 'react-router-dom';
import offer from "../images/icons/star.png"
import "../css/commonitem.css"
import Parser from 'html-react-parser';
import Contact from "../itemlisting/contact"
import $ from 'jquery'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import ReactPlayer from "react-player"
import Video from '../school/assets/images/videoIcon.png';
import Warranty from '../images/warranty.png'
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemProfile: [],
      colourData: [],
      similarItemData: [],
      imageResultData: [],
      relateditemProfileData: [],
      otherDataResult: [],
      cartSize: 0,
      storeButtonName: null,
      ItemName: null,
      storeItemStockId: null,
      category: "",
      videoPlay: "",
    };
    this.itemLoad = this.itemLoad.bind(this);
    this.videoPopup = this.videoPopup.bind(this);
  }
  videoPopup = (image_name) => {
    this.setState({
      videoPlay: image_name,
    }, () => {
      $(".locationPopUps").show();
    })
  }
  closeVideoPopup() {
    $(".locationPopUps").hide();
  }
  imageZoomLarge = (img) => {
    this.setState({
      category: img
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.itemId === this.props.itemId) {
      window.location.reload();
    }
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    this.itemLoad(params.itemId)
    var sellerID = "";
    var moduleID = 3;
    var siteCategoryGroupId = "";
    if (window.sessionStorage.getItem('siteCategoryId')) {
      siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
    }
    else {
      sellerID = window.sessionStorage.getItem('sellerId')
    }
    pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
  }
  contactForm(storeButtonName, ItemName, storeItemStockId) {
    this.setState({
      storeButtonName: storeButtonName,
      ItemName: ItemName,
      storeItemStockId: storeItemStockId
    })
    $(".contactFormPopUp").show();
  }
  itemLoad(itemId) {
    let storeId = window.sessionStorage.getItem('storeId');
    const postData = {
      "functionName": "cmitemprofile",
      "cmItemId": itemId,
      "cmStoreId": storeId
    }
    getData(postData).then((data) => {
      if (data.data.success === "1") {
        var cartQuantity = 0;
        var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart'))
        if (selectCommonItemCart !== null && selectCommonItemCart !== '' && selectCommonItemCart !== 'null') {
          for (let n = 0; n < selectCommonItemCart.length; n++) {
            if (selectCommonItemCart[n].itemId === data.data.result.itemProfile[0].item_id) {
              cartQuantity = parseInt(cartQuantity) + parseInt(selectCommonItemCart[n].quantity)
            }
          }
        }
        this.setState({
          cartSize: cartQuantity,
          itemProfile: data.data.result.itemProfile,
          colourData: data.data.result.colourResultProfile,
          similarItemData: data.data.result.similarItem,
          imageResultData: data.data.result.imageResult,
          relateditemProfileData: data.data.result.relateditemProfile,
          otherDataResult: data.data.result.otherData,
        });
      }
    })
  }
  aboutClick() {
    $("#aboutTabSpecification").removeClass("aboutTabActive");
    $("#aboutTabDiscription").addClass("aboutTabActive");
    $("#tabSpecificationContent").removeClass("productDetailShow");
    $("#tabDiscriptionContent").addClass("productDetailShow");
  }
  specificationsClick() {
    $("#aboutTabDiscription").removeClass("aboutTabActive");
    $("#aboutTabSpecification").addClass("aboutTabActive");
    $("#tabDiscriptionContent").removeClass("productDetailShow");
    $("#tabSpecificationContent").addClass("productDetailShow");
  }
  render() {
    let themeId = localStorage.getItem('themeId')
    var sliderCount = 5
    if (themeId === '34' || themeId === '29') {
      sliderCount = 4
    }
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToShow: sliderCount,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 570,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const settingsSimilar = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            swipeToSlide: true
          }
        }
      ]
    };
    return (
      <div className="coverWraper coverWraper-bg comnItem-prf-wrapper">
        <Suspense fallback={<div></div>}>
          <MainHeader></MainHeader>
          {console.log("this.state.itemProfile",this.state.itemProfile)}
          {this.state.itemProfile.map((itemProfileresult, index) => (
            <div className={themeId === '28' ? "contentWrap clContentWrap" : "contentWrap"}>
              <div className={(themeId === '34' || themeId === '29') ? "profileContainer container" : "profileInnerContainer"}>
                <div className="cart-shpinTopSec">
                <div className="tagSection profileTagSection profileTagNew">
                  <a href="/cmItemListing/0/0/0/" className="tag">{itemProfileresult.storeName}</a>
                  <Link className="tag" to={"/cmItemListing/" + itemProfileresult.category1_id + "/0/0"}>{itemProfileresult.category1_name}</Link>
                  <Link className="tag" to={"/cmItemListing/" + itemProfileresult.category1_id + "/" + itemProfileresult.category2_id + "/0"}>{itemProfileresult.category2_name}</Link>
                  {itemProfileresult.category3_name !== null ?
                    <Link className="tag" to={"/cmItemListing/" + itemProfileresult.category1_id + "/" + itemProfileresult.category2_id + "/" + itemProfileresult.category3_id}> {itemProfileresult.category3_name} </Link> :
                    ""
                  }
                </div>
                {/* ----------- */}
                <div className="continueBtnDiv">
                  <Link to="/cmItemListing/0/0/0/" className="textdec-unset">
                    <div class="buttonss Sc_continueShop btn-continueshop" id="button-3">
                      <div id="circle"></div>
                      <a> Continue shopping </a>
                    </div>
                  </Link>
                </div>
                </div>
                {/* ----------- */}
                <div className="itemProfile itemProfileCommon">
                  <div className='profileImage Prod_img_Zoom'>
                    <InnerImageZoom src={this.state.category === "" ? this.state.imageResultData[0].image_name : this.state.category} zoomSrc={this.state.category === "" ? this.state.imageResultData[0].image_name : this.state.category} zoomScale={2.5} zoomType="hover" />
                    <div className='Prod_img_small'>
                      {this.state.imageResultData.map((itemData) => (
                        <>
                          {itemData.is_video === "0" ?
                            <img src={itemData.image_name} alt="Zoom Effect" onClick={() => this.imageZoomLarge(itemData.image_name)} />
                            :
                            <button onClick={(e) => this.videoPopup(itemData.image_name)}><img src={Video} alt="" /></button>}
                        </>
                      ))}
                    </div>
                  </div>
                  {/*  */}
                  <div className="popupWrap locationPopUps" style={{ display: 'none' }}>
                    <div className="confirmWrappers">
                      <div className="paymentSectionWrap">
                        <div className="paymentHead" style={{ padding: '0px', borderRadius: '6px' }}>
                          <button onClick={(e) => { this.closeVideoPopup(this.setState({ videoPlay: "" })) }} className='videoPopupClose'><span class="close">&times;</span></button>
                          <ReactPlayer controls
                            url={this.state.videoPlay}
                            alt={""}
                            width='100%'
                            height='100%'
                            playing={true}
                            className="paymentHead"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Product Zoom ends */}
                  <div className="profileDetails">
                    <div id="portal" className="portal" />
                    {itemProfileresult.brand_name !== '' ? <div className="profileItemBrand">{itemProfileresult.brand_name}</div> : ''}
                    <div className="profileItemName">{itemProfileresult.name}<br />
                      {itemProfileresult.title !== null ?
                        <span class="profileSmallName">{itemProfileresult.title}</span> : ''
                      }
                    </div>
                    <div className="profileItemQty">
                      <div className="radioList">
                        <span className="profilelist">
                          <a href>{itemProfileresult.collection_name}</a>
                        </span>
                      </div>
                    </div>
                    <div class="bankOffer profileMargin">
                      {itemProfileresult.is_offer === "1" ?
                        <span id={'offerSpan'} className="offerText"><img src={offer} alt="bankofferprofile" /> {itemProfileresult.offer_text}</span>
                        : ""}
                    </div>
                    <div class="bankOffer profileMargin">
                      {itemProfileresult.warrenty !== null && itemProfileresult.warrenty !== '' && itemProfileresult.warrenty !== 'null' ? <div className="productAboutLine">
                        {/* <h3>Warranty</h3>  */}
                        <img src={Warranty} alt="Warranty" className='img-fluid' width={35} height={35} title='Warranty'/>
                        {itemProfileresult.warrenty}</div> :
                        ""}
                    </div>
                    <div class="productcolor profileMargin productAboutLine">
                      <h3 className='prod-color-comnItem'>Product Colour</h3>
                      <div className="productInlineData">
                        <div className="colorDivFilter">
                          <span class="colorList">
                            <span class="colorItem" title={itemProfileresult.colourName} style={{ background: itemProfileresult.colourCode }}></span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="productDetailPrice">
                      <span className="newPrice ml-0">{itemProfileresult.currencySymbol} {parseFloat(itemProfileresult.net_amount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                      {itemProfileresult.net_amount < itemProfileresult.mrp &&
                        <span className="oldPrice">{itemProfileresult.currencySymbol} {parseFloat(itemProfileresult.mrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                      }
                    </div>
                    <div className="profileButtonSection pf-section-gsa">
                      {(() => {
                        if (itemProfileresult.available_quantity === 0 && itemProfileresult.sizeResult.length === 0) {
                          return (
                            <span style={{ color: 'red' }}><b>Out Of Stock</b></span>
                          )
                        }
                      })()}
                      {(() => {
                        if (itemProfileresult.available_quantity === 0 && itemProfileresult.sizeResult.length === 0) {
                          return (
                            <span class="cartButton" onClick={(e) => this.contactForm(itemProfileresult.storeButtonName, itemProfileresult.name, itemProfileresult.item_id)}>Contact Us</span>
                          )
                        }
                        else {
                          if (itemProfileresult.hasContactUsWithoutPrice === "1") {
                            if (itemProfileresult.storeButtonName) {
                              return (
                                <span class="cartButton" onClick={(e) => this.contactForm(itemProfileresult.storeButtonName, itemProfileresult.name, itemProfileresult.item_id)}>{itemProfileresult.storeButtonName}</span>
                              )
                            }
                            else {
                              return (
                                <span class="cartButton" onClick={(e) => this.contactForm(itemProfileresult.storeButtonName, itemProfileresult.name, itemProfileresult.item_id)}>Contact Us</span>
                              )
                            }
                          }
                          else if (itemProfileresult.hasBuyOption === "1") {
                            if (itemProfileresult.mrp !== 0 || itemProfileresult.mrp <= 0) {
                              return (
                                <CartBtn itemLoad={this.itemLoad} cartSize={this.state.cartSize} fromPage='itemProfile' availableQuantity={itemProfileresult.available_quantity} maxBuyCount={itemProfileresult.maxBuyCount} itemId={itemProfileresult.item_id} itemName={itemProfileresult.name} storeId={itemProfileresult.store_id} itemTitle={itemProfileresult.title} brand={itemProfileresult.brand_name} itemSize={itemProfileresult.sizeResult} netAmount={itemProfileresult.net_amount} mrp={itemProfileresult.mrp} itemImage={this.state.imageResultData[0].image_name} index={itemProfileresult.item_id} itemMasterId={itemProfileresult.item_master_id} itemSizeChart={itemProfileresult.size_chart_image_path} itemWarranty={itemProfileresult.warrantyPath} itemAddedColour="itemAddedColour"></CartBtn>
                              )
                            }
                            else {
                              if (itemProfileresult.storeButtonName) {
                                return (
                                  <span class="cartButton" onClick={(e) => this.contactForm(itemProfileresult.storeButtonName, itemProfileresult.name, itemProfileresult.item_id)}>{itemProfileresult.storeButtonName}</span>
                                )
                              }
                              else {
                                return (
                                  <span class="cartButton" onClick={(e) => this.contactForm(itemProfileresult.storeButtonName, itemProfileresult.name, itemProfileresult.item_id)}>Contact Us</span>
                                )
                              }
                            }
                          }
                          else if (itemProfileresult.hasBuyOption === "0") {
                            if (itemProfileresult.storeButtonName) {
                              return (
                                <span class="cartButton" onClick={(e) => this.contactForm(itemProfileresult.storeButtonName, itemProfileresult.name, itemProfileresult.item_id)}>{itemProfileresult.storeButtonName}</span>
                              )
                            }
                            else {
                              return (
                                <span class="cartButton" onClick={(e) => this.contactForm(itemProfileresult.storeButtonName, itemProfileresult.name, itemProfileresult.item_id)}>Contact Us</span>
                              )
                            }
                          }
                        }
                      })()}
                      <span class="text-red" id={'qtyError' + itemProfileresult.item_id.toString() + "-"}></span>
                    </div>
                    {(themeId !== '34' && themeId !== '29') ?
                      <div>
                        {itemProfileresult.description !== '' ?
                          <div className="productAbout">
                            <h3>About the Product</h3>
                            <p>{Parser(itemProfileresult.description)}</p>
                          </div>
                          : ''}
                        {itemProfileresult.model_name !== null && itemProfileresult.model_name !== '' && itemProfileresult.model_name !== 'null' ?
                          <div className="productAbout productAboutLine">
                            <h3>Model:</h3>{itemProfileresult.model_name}
                          </div>
                          : ''
                        }
                      </div>
                      : ''}
                    <div className="productNote">
                      <h6>Important Note</h6>
                      <p>Product information or packaging displayed may not be current or complete. For additional information, contact the retailer.</p>
                    </div>
                    {this.state.similarItemData.length > 0 ?
                      <div className="similarSlider profileMargin">
                        <h3>Similar Items</h3>
                        <div className="productItemSlider similarItemSlider">
                          <Slider {...settingsSimilar}>
                            {this.state.similarItemData.map((similarItemDataresult, index) => (
                              <div>
                                <div className="similarItem">
                                  {similarItemDataresult.item_image !== null ?
                                    <a href={'/cmItemProfile/' + similarItemDataresult.itemId}>
                                      <img src={similarItemDataresult.item_image} alt="similiarItem" />
                                    </a> : ""
                                  }
                                  {similarItemDataresult.net_amount !== null ?
                                    <div className="similarPrice">{itemProfileresult.currencySymbol}. {parseFloat(similarItemDataresult.net_amount).toFixed(window.sessionStorage.getItem('decimalCount'))}</div> : ""
                                  }
                                </div>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                      : ''}
                    {this.state.colourData.length > 0 ?
                      <div className="availableColor profileMargin">
                        <h3>Available Product Colours</h3>
                        <div className="colorDivFilter">
                          {this.state.colourData.map((colourDataresult, index) => (
                            <div>
                              {colourDataresult.colour_name !== null ?
                                <span className="colorList">
                                  <span className="colorItem" title={colourDataresult.colour_name} style={{ background: "#" + colourDataresult.colour_code }}></span>
                                </span> : ""
                              }
                            </div>
                          ))}
                        </div>
                      </div>
                      : ''}
                  </div>
                  {(themeId === '34' || themeId === '29') ?
                    <div>
                      <div className="aboutTAb aboutTAbTop">
                        <h3 id="aboutTabDiscription" className="aboutTabActive" onClick={this.aboutClick}>Description</h3>
                        <h3 id="aboutTabSpecification" onClick={this.specificationsClick}>Specifications</h3>
                      </div>
                      {itemProfileresult.description !== '' ?
                        <div id="tabDiscriptionContent" className="productDetailHide productDetailShow">
                          <p>{itemProfileresult.description}</p>
                        </div>
                        : ''}
                      {itemProfileresult.model_name !== null && itemProfileresult.model_name !== '' && itemProfileresult.model_name !== 'null' ?
                        <div id="tabSpecificationContent" className="productDetailHide">
                          <h3 className='model-headtext'>Model:</h3>{itemProfileresult.model_name}
                        </div>
                        : ''
                      }
                    </div>
                    : ''}
                  {itemProfileresult.over_view !== '' ?
                    <div className="productNote">
                      <h3 class="over-view">Over View</h3>
                      <p class="color-black-gsa">{Parser(itemProfileresult.over_view)}</p>
                    </div>
                    : ""}
                  {itemProfileresult.featureData.length !== 0 ?
                    <div>
                      <div className="productNote prodFeatures">
                        <h3 class="over-view">Features</h3>
                        {itemProfileresult.featureData.map((featureData, index) => (
                          <div>
                            <p class="color-black-gsa prodFeaturesHead">&nbsp; {featureData.featureName}</p>

                            {featureData.level2Data.length !== 0 ?
                              <div>
                                {featureData.level2Data.map((featureData1, index) => (
                                  <div className='prodFeaturesSubHead'>

                                    <p class="color-black-gsa prodFeaturesContents">{featureData1.name} : {featureData1.value}</p>

                                    <span className='Sc_prodFeatureImg'><img src={featureData1.image} alt="" /></span>
                                  </div>
                                ))}
                              </div> : ""}
                          </div>
                        ))}
                      </div>
                    </div>
                    : ""}
                </div>
              </div>
              <div class="popupWrap contactFormPopUp">
                <Contact storeButtonName={this.state.storeButtonName} ItemName={this.state.ItemName} storeItemStockId={this.state.storeItemStockId} moduleType={"common"}></Contact>
              </div>
              {this.state.relateditemProfileData.length > 0 ?
                <div className="relatedSlider">
                  <div className={(themeId === '34' || themeId === '29') ? "container" : ""}>
                    <div className="subTitle">Related Items</div>
                    <Slider {...settings}>
                      {this.state.relateditemProfileData.map((relateditemProfileDataresult, index) => (
                        <div>
                          <div className="productItem">
                            <div className="productImage">
                              <a href={'/cmItemProfile/' + relateditemProfileDataresult.item_id}>
                                <img src={relateditemProfileDataresult.imageName} alt="" />
                              </a>
                            </div>
                            <div className="productDetails">
                              <div className="productName">
                                <a href="/#" className="elipsis" title={relateditemProfileDataresult.name}>{relateditemProfileDataresult.name}</a>
                              </div>
                              <div className="productPrice">
                                <span className="newPrice">{itemProfileresult.currencySymbol} {parseFloat(relateditemProfileDataresult.net_amount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                {relateditemProfileDataresult.net_amount < relateditemProfileDataresult.mrp &&
                                  <span className="oldPrice">{itemProfileresult.currencySymbol} {parseFloat(relateditemProfileDataresult.mrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                }
                              </div>
                              <div class="itemSizeShow">
                                Sizes:
                                {relateditemProfileDataresult.sizeResult.map((sizerrayObject, sizeArrayIndex) => (
                                  <span>{sizerrayObject.sizeName}</span>
                                ))}
                              </div>
                              <div className="addCartButton">
                                <CartBtn
                                  fromPage='itemProfile'
                                  // sizeId = {relateditemProfileDataresult.sizeId}
                                  quantity={relateditemProfileDataresult.quantity}
                                  sizeName={relateditemProfileDataresult.sizeName}
                                  cmsizeId={relateditemProfileDataresult.cmsizeId}
                                  availableQuantity={relateditemProfileDataresult.available_quantity}
                                  maxBuyCount={relateditemProfileDataresult.maxBuyCount}
                                  itemId={relateditemProfileDataresult.item_id}
                                  itemName={relateditemProfileDataresult.name}
                                  storeId={relateditemProfileDataresult.store_id}
                                  itemTitle={relateditemProfileDataresult.title}
                                  brand={relateditemProfileDataresult.brand_name}
                                  itemSize={relateditemProfileDataresult.sizeResult}
                                  netAmount={relateditemProfileDataresult.net_amount}
                                  mrp={relateditemProfileDataresult.mrp}
                                  itemImage={relateditemProfileDataresult.imageName}
                                  index={relateditemProfileDataresult.item_id}
                                  itemMasterId={relateditemProfileDataresult.item_master_id}
                                  itemAddedColour="itemAddedColour">
                                </CartBtn>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                      </div>
                    </Slider>
                  </div>
                </div>
                : ""}
            </div>
          ))}
          <MainFooter></MainFooter>
        </Suspense>
      </div>
    )
  }
}
