import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './css/style.css'
import './css/responsive.css'
import 'bootstrap/dist/css/bootstrap.css'
import rootFile from "./main/rootFile"
import restaurantListing from "../src/restaurant/restaurantListing"
import OrderSummary from './lokalIn/orderSummary';
import dashboard from "../src/dashboard/dashboard"
import OrderReview1 from '../src/checkOut/orderSuccessTest'
import myorderFeedBack from '../src/dashboard/myorderFeedBack';
import orderDetails from "../src/checkOut/orderDetails"
import Invoice from '../src/checkOut/newInvoice'
import reOrder from '../src/dashboard/reorder';

import cmItemListing from './commonItem/cmItemListing';
import cmItemProfile from './commonItem/cmItemProfile';

 import category from './lokalIn/categorylist';
 import store from './lokalIn/store';
import storeProfile from './lokalIn/storeProfile';
 import profile from './itemprofile/profile';
import listing from "../src/itemlisting/listing"
 import lokalSellerRegistrationHome from '../src/lokalSeller/home';
 import lokalSellerSignup from '../src/lokalSeller/signup';
 import aboutNew from '../src/lokalIn/aboutnew'
 import footerContent from './headerComponents/footerContent';

import About from "../src/mraTheme/about"
import Branches from "../src/mraTheme/branches"
import MenuDetails from "../src/mraTheme/menuDetails"
import Blogs from "../src/mraTheme/blogs"
import Gallery from '../src/mraTheme/gallery'
import Csr from '../src/mraTheme/csr';
import ContactDetails from '../src/mraTheme/contactDetails';
import MenuStaticDetails from "../src/mraTheme/menuStaticDetails"
import BlogsDetails from "../src/mraTheme/blogsDetails"
import BlogsDetailsBakery from "../src/mraTheme/blogsDetailsBakery"
import BlogDetailsCatering from "../src/mraTheme/blogDetailsCatering"
import BlogsDetailsSnacks from "../src/mraTheme/blogsDetailsSnacks"
import BlogsDetailsCakes from "../src/mraTheme/blogsDetailsCakes"

import myyshoppMystory from "../src/myyshopp/myyshopp/mystory"
import myyshoppAdvantages from "../src/myyshopp/myyshopp/advantages"
import myyshoppOurDna from "../src/myyshopp/myyshopp/our-dna"
import myyshoppFeatures from "../src/myyshopp/myyshopp/features"
import myyshoppPricing from "../src/myyshopp/myyshopp/pricing"
import myyshoppContact from "../src/myyshopp/myyshopp/contact"
import myyshoppTeam from "../src/myyshopp/myyshopp/team"
import myyshoppOurInvestor from "../src/myyshopp/myyshopp/our-investor"
import myyshoppCareer from "../src/myyshopp/myyshopp/career"
import myyshoppHelpcentre from "../src/myyshopp/myyshopp/helpcentre"
import Privacy from "../src/myyshopp/myyshopp/privacy"
import TermsConditions from "../src/myyshopp/myyshopp/terms_Conditions"

import aboutUs from "../src/fragrance/aboutUsFooter"
import OurVision from "../src/fragrance/vision"
import OurMission from "../src/fragrance/mission"
import OrdersummarySuccess from './lokalIn/orderSummarySucess';
import StripeSuccess from './stripeDetails/stripeSuccess';
import Globalsearchresult from './lokalIn/globalSearchResult';
import phonePeRedirect from './lokalIn/phonePeRedirection'
function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/SELLER-:sellerNo" component={rootFile} />
        <Route path="/restaurantListing/:itemId/:categoryLevel1Id?/:searchKeyword?" component={restaurantListing} />
        <Route path="/OrderSummary" component={OrderSummary} />
        <Route path="/OrderSummary-:test" component={OrdersummarySuccess} />
        <Route path="/dashboard" component={dashboard} />
        <Route path="/viewOrderDetails" component={OrderReview1} />
        <Route path="/myorderFeedBack/:orderId" component={myorderFeedBack} />
        <Route path="/orderDetails/:orderId" component={orderDetails} />
        <Route path="/orderInvoice/:orderId" component={Invoice} />
      
        <Route path="/cmItemListing/:categoryLevel1Id?/:categoryLevel2Id?/:categoryLevel3Id?/:searchKeyword?" component={cmItemListing} />
        <Route path="/cmItemProfile/:itemId" component={cmItemProfile} />
        <Route path="/reOrder/:orderId?/:mainCategoryGroupId" component={reOrder} />
        <Route path="/globalSearchResult" component={Globalsearchresult} />

        <Route path="/UPI/:transactionId" component={phonePeRedirect} />
        <Route path="/MYCATEGORY-:categoryId" component={rootFile} />
         <Route path="/category" component={category} exact />
         <Route path="/:city/online-:slug" component={store} />
         <Route path="/STORE-:slug" component={storeProfile} />
        <Route path="/:category-shop-in-:storeName" component={storeProfile} exact />
        <Route path="/profile" component={profile} />
        <Route path="/ITEM-:slug" component={profile} />
        <Route path="/listing/:categoryLevel1Id?/:categoryLevel2Id?/:categoryLevel3Id?/:searchKeyword?/:description?" component={listing} />
        <Route path="/registrationForSeller" component={lokalSellerRegistrationHome} />
        <Route path="/signUpAsSeller" component={lokalSellerSignup} />
         <Route path="/aboutNew" component={aboutNew} />
         <Route path="/footerContent" component={footerContent} />       


        <Route path="/mraAbout" component={About} />
        <Route path="/branches" component={Branches} />
        <Route path="/mraMenu" component={MenuDetails} />
        <Route path="/blogs" component={Blogs} />
        <Route path="/blogsDetails" component={BlogsDetails} />
        <Route path="/blogDetailsCatering" component={BlogDetailsCatering} /> 
        <Route path="/blogsDetailsBakery" component={BlogsDetailsBakery} /> 
        <Route path="/blogsDetailsSnacks" component={BlogsDetailsSnacks} /> 
        <Route path="/blogsDetailsCakes" component={BlogsDetailsCakes} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/csr" component={Csr} />
        <Route path="/contactDetails" component={ContactDetails} />
        <Route path="/menuStaticDetails" component={MenuStaticDetails} />
        <Route path="/" component={rootFile} exact /> 

        <Route path="/myyshopp/mystory" component={myyshoppMystory} />
        <Route path="/myyshopp/advantages" component={myyshoppAdvantages} />
        <Route path="/myyshopp/our-dna" component={myyshoppOurDna} />
        <Route path="/myyshopp/features" component={myyshoppFeatures} />
        <Route path="/myyshopp/pricing" component={myyshoppPricing} />
        <Route path="/myyshopp/contact" component={myyshoppContact} />
        <Route path="/myyshopp/team" component={myyshoppTeam} />
        <Route path="/myyshopp/our-investor" component={myyshoppOurInvestor} />
        <Route path="/myyshopp/career" component={myyshoppCareer} />
        <Route path="/myyshopp/helpcentre" component={myyshoppHelpcentre} />
        <Route path="/myyshopp/privacy" component={Privacy} />
        <Route path="/myyshopp/terms_Conditions" component={TermsConditions} /> 

         <Route path="/aboutUs" component={aboutUs} />
        <Route path="/ourVision" component={OurVision} />
        <Route path="/ourMission" component={OurMission} />
        <Route path="/INV:slug" component={orderDetails} />  

        <Route path="/stripeSuccess/:stripeId/:buyerDeliveryAddressId/:itemBeforePaymentGroupCode/:storeId" component={StripeSuccess} exact/>
      </Router>
    </div>
  );
}
export default App;
