import React, { Component } from 'react'
import { getData, pageHitAPICall } from '../services';
import $ from "jquery";
import map from '../images/lokal/map.png'
import LazyLoad from 'react-lazyload';
export default class Category extends Component {
    constructor(props) {
        super(props)
        this.state = {
            category: [],
            isData: '1'
        }
    }
    componentDidMount() {
        window.isPageLoad = "1"
        let locationId = '';
        if (this.props.fromPage !== 'lokalHome') {
            locationId = localStorage.getItem('locationId');
        }
        const postData = {
            "functionName": "lokal/category",
            "myCategoryId": window.sessionStorage.getItem('siteCategoryId'),
            "locationId": locationId
        }

        getData(postData).then((data) => {
            console.log("categorydata123333333333 ", data)
            if (data.data.success === "1") {
                if (data.data.result.category.length > 0) {
                    this.setState({
                        category: data.data.result.category,
                        isData: '1'
                    });
                }
                else {
                    this.setState({
                        category: data.data.result.category,
                        isData: '0'
                    });
                }
            }
            else {
                this.setState({
                    isData: '0'
                });
            }
        });
        pageHitAPICall("", 11, window.sessionStorage.getItem('siteCategoryId'));
    }
    location(obj) {
        window.sessionStorage.setItem('mainCategoryIdBeforLocation', obj.id)
        window.sessionStorage.setItem('mainCategoryIdLokal', obj.id)
        window.sessionStorage.setItem('mainCategoryAlternativeName', obj.alternativeName)
        window.sessionStorage.setItem('mainCategoryDetails', JSON.stringify(obj))
        $(".locationPopUp").show();
    }
    setCategory(obj) {
        window.sessionStorage.setItem('mainCategoryIdLokal', obj.id)
        window.sessionStorage.setItem('mainCategoryId', obj.id)
        window.sessionStorage.setItem('storeName', '')
        window.sessionStorage.setItem('storeId', '')
        window.sessionStorage.setItem('mainCategoryAlternativeName', obj.alternativeName)
        this.setCategorySessions(obj, 'hasLocation')
    }
    setCategorySessions(obj, flag) {

        let title = obj.seoTitle.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', "anywhere")
        let description = obj.seoDescription.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', "anywhere")
        let slug = obj.seoSlug.replace('{{City}}',"anywhere").replace('{{Location}}', "anywhere")
        let h1 = obj.seoH1.replace('{{Location}}', "anywhere")
        if(localStorage.getItem('siteGlobalDelivery') === "false" && localStorage.getItem('isPincodeBased') === "false"){
        title = obj.seoTitle.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', localStorage.getItem('locationNameOnly'))
        description = obj.seoDescription.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', localStorage.getItem('locationNameOnly'))
        slug = obj.seoSlug.replace('{{City}}', localStorage.getItem('cityNameOnly').toLowerCase()).replace('{{Location}}', localStorage.getItem('locationNameOnly').toLowerCase().trim().replace(/ /g, '-'))
        h1 = obj.seoH1.replace('{{Location}}', localStorage.getItem('locationNameOnly'))
        }

        window.sessionStorage.setItem('mainCategorySeoTitle', title)
        window.sessionStorage.setItem('mainCategorySeoDescription', description)
        window.sessionStorage.setItem('mainCategorySeoSlug', slug)
        window.sessionStorage.setItem('mainCategorySeoH1', h1)
        if (flag === "hasLocation") {
            window.location.href = slug
        }
    }
    locationClick() {
        $(".locationPopUp").show();
    }
    goClick() {
        let themeId = localStorage.getItem('themeId')
       if (themeId === '34' || themeId === '29') {
                this.categorySelection() 
        }
      }
      categorySelection(){
 
        let locationId = '';
        if (this.props.fromPage !== 'lokalHome') {
            locationId = localStorage.getItem('locationId');
        }
        const postData = {
          "functionName": "lokal/category",
          "myCategoryId": window.sessionStorage.getItem('siteCategoryId'),
          "locationId": locationId
      }
    
      getData(postData).then((data) => {
          console.log("categorydata ", data)
          if (data.data.success === "1") {
              if (data.data.result.category.length === 1) {
                const obj = data.data.result.category[0];
                  window.sessionStorage.setItem('mainCategoryIdLokal', obj.id)
                  window.sessionStorage.setItem('mainCategoryId', obj.id)
                  window.sessionStorage.setItem('storeName', '')
                  window.sessionStorage.setItem('storeId', '')
                  window.sessionStorage.setItem('mainCategoryAlternativeName', obj.alternativeName)
                  localStorage.removeItem('cityId');
                  localStorage.removeItem('cityText');
                  localStorage.removeItem('cityNameOnly');
                  localStorage.removeItem('locationNameOnly');
                  localStorage.removeItem('locationId');
              localStorage.removeItem('locationText');
                  this.setCategorySessions(obj, 'hasLocation')
              }
              else {
                localStorage.removeItem('cityId');
                localStorage.removeItem('cityText');
                localStorage.removeItem('cityNameOnly');
                localStorage.removeItem('locationNameOnly');
                localStorage.removeItem('locationId');
                localStorage.removeItem('locationText');
                window.location.href = "/category";
              }
          }
          else {
              this.setState({
                  isData: '0'
              });
          }
      });
      }
      setCategorySessions(obj, flag) {
        let title = obj.seoTitle.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', "anywhere")
        let description = obj.seoDescription.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', "anywhere")
        let slug = obj.seoSlug.replace('{{City}}',"anywhere").replace('{{Location}}', "anywhere")
        let h1 = obj.seoH1.replace('{{Location}}', "anywhere")
        if(localStorage.getItem('siteGlobalDelivery') === "false" && localStorage.getItem('isPincodeBased') === "false"){
         title = obj.seoTitle.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', localStorage.getItem('locationNameOnly'))
         description = obj.seoDescription.replace('{{SiteName}}', window.sessionStorage.getItem('siteName')).replace('{{Location}}', localStorage.getItem('locationNameOnly'))
        slug = obj.seoSlug.replace('{{City}}', localStorage.getItem('cityNameOnly').toLowerCase()).replace('{{Location}}', localStorage.getItem('locationNameOnly').toLowerCase().trim().replace(/ /g, '-'))
         h1 = obj.seoH1.replace('{{Location}}', localStorage.getItem('locationNameOnly'))
        }
        window.sessionStorage.setItem('mainCategorySeoTitle', title)
        window.sessionStorage.setItem('mainCategorySeoDescription', description)
        window.sessionStorage.setItem('mainCategorySeoSlug', slug)
        window.sessionStorage.setItem('mainCategorySeoH1', h1)
        if (flag === "hasLocation") {
            window.location.href = slug
        }
    }
    render() {
        let locationId = localStorage.getItem('locationId');
        let locationSessionName = localStorage.getItem('locationText');
        return (
            this.state.isData === '1' ?
                <div className="categoryItemSection">
                    {this.state.category.map((category, index) => (
                        <div key={index} className="categoryItemsList gsa-categorylist">
                            <div className="categoryItems">
                                {(() => {
                                    if ((locationId === null || locationId === '')&& localStorage.getItem('siteGlobalDelivery') !== "true" && localStorage.getItem('isPincodeBased') !== "true") {
                                        return (
                                            <div className='itemsLists' onClick={(e) => this.location(category)}>
                                                <LazyLoad once>
                                                    <img src={category.image_path} alt={category.NAME} />
                                                </LazyLoad>
                                                <span>{category.NAME}</span>
                                            </div>
                                        )
                                    }
                                    else if ((locationId === null || locationId === '')&& localStorage.getItem('isPincodeBased') === "true") {
                                        return (
                                            <div className='itemsLists' onClick={(e) => this.goClick()}>
                                                <LazyLoad once>
                                                    <img src={category.image_path} alt={category.NAME} />
                                                </LazyLoad>
                                                <span>{category.NAME}</span>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className='itemsLists' onClick={(e) => this.setCategory(category)} >
                                                <LazyLoad once>
                                                    <img src={category.image_path} alt={category.NAME} />
                                                </LazyLoad>
                                                <span>{category.NAME}</span>
                                            </div>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    ))}
                </div>
                :
                <div>
                    <div className="itemCategoryTitle">
                        Sorry...! No store(s) available in {locationSessionName}. Please change your location
                    </div>
                    <div className="locationStoreIcon" onClick={this.locationClick}>
                        <span className="locationStoreClick"><img src={map} alt="location" />Change Location</span>
                    </div>
                </div>
        )
    }
}