import React, { Component } from 'react';
import { getData, pageHitAPICall } from '../services';
export default class personal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      emailId: '',
      mobileNumber: '',
      promotions: '',
      disabledName: true,
      displayName: ''
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailId = this.handleEmailId.bind(this);
    this.handlemobileNumber = this.handlemobileNumber.bind(this);
    this.handlepromotions = this.handlepromotions.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }
  onEdit() {
    document.getElementById('btnEditPersonal').style.display = 'none';
    document.getElementById('btnSavePersonal').style.display = '';
    this.setState({
      disabledName: !this.state.disabledName
    })
  }
  checkingfunction() {
  }
  handleButtonClick = (event) => {
    var personalMsgError = "";
    let formIsValid;
    document.getElementsByClassName("personalMsgError")[0].innerHTML = personalMsgError;
    let firstName = this.state.displayName;
    let emailId = this.state.emailId;
    let mobileNumber = this.state.mobileNumber;
    if (!firstName) {
      personalMsgError = "Full Name Required";
      formIsValid = false;
    }
    // else if (!emailId) {
    //   personalMsgError = "Email Id Required";
    //   formIsValid = false;
    // }
    else if (!mobileNumber) {
      personalMsgError = "Mobile Number Required";
      formIsValid = false;
    }
    else {
      formIsValid = true;
    }
    var characters = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    // var characters = /^(([^<>()\\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((    [[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var email = document.getElementById("txtEmailId").value;
    var splitemail = email.split(/\r|\r\n|\n/);
    var emailcount = splitemail.length;
    if (characters.test(splitemail[emailcount - 1]) === false && document.getElementById("txtEmailId").value !== "") {
      alert("Invalid Email Id");
      document.getElementById("txtEmailId").focus();
      return false;
    }
    if (mobileNumber !== "undefined") {
      if (!mobileNumber.match(/^[0-9]{10}$/)) {
        personalMsgError = "Please enter valid mobile number";
        document.getElementById("txtMobileNumber").focus();
        formIsValid = false;
      }
    }
    if (!formIsValid) {
      document.getElementsByClassName("personalMsgError")[0].innerHTML = personalMsgError;
      return false;
    }
    else {
      let currentUserId = localStorage.getItem('userId');
      const postData = {
        functionName: "updateaccountdetails/updateaccountdetails",
        currentUserId: currentUserId,
        firstName: this.state.displayName,
        lastName: (this.state.lastName === null || this.state.lastName === "") ? null : this.state.lastName,
        emailId: this.state.emailId ? this.state.emailId : "",
        mobileNumber: this.state.mobileNumber,
        promotions: this.state.promotions === true ? '1' : '0'
      }
      getData(postData).then((data) => {
        if (data.data.success === "1") {
          document.getElementsByClassName("personalMsgSuccess")[0].innerHTML = "Account details updated successfully";
          document.getElementById('btnEditPersonal').style.display = '';
          document.getElementById('btnSavePersonal').style.display = 'none';
          this.setState({
            disabledName: !this.state.disabledName
          })
        }
      });
    }
  }
  componentDidMount() {
    let currentUserId = localStorage.getItem('userId');
    const postData = {
      functionName: "userdashboarddetails/selectAccountDetails",
      currentUserId: currentUserId
    }
    getData(postData).then((data) => {
      if (data.data.success === "1") {
        this.setState({
          firstName: data.data.result.accountList[0].buyerFirstName,
          lastName: data.data.result.accountList[0].buyerLastName === 'null' ? '' : data.data.result.accountList[0].buyerLastName,
          displayName: data.data.result.accountList[0].buyerFirstName + (data.data.result.accountList[0].buyerLastName === 'null' ? '' : ' ' + data.data.result.accountList[0].buyerLastName),
          emailId: data.data.result.accountList[0].buyerEmailId,
          mobileNumber: data.data.result.accountList[0].buyerMobileNumber,
          promotions: data.data.result.accountList[0].promotions === '1' ? true : false,
        });
      }
    })
    var sellerID = "";
    var moduleID = 9;
    var siteCategoryGroupId = "";

    if (window.sessionStorage.getItem('siteCategoryId')) {
      siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
    }
    else {
      sellerID = window.sessionStorage.getItem('sellerId')
    }
    pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
  }
  handleFirstNameChange = (event) => {
    this.setState({
      displayName: event.target.value
    });
  }
  handleLastNameChange = (event) => {
    this.setState({
      lastName: event.target.value
    });
  }
  handleEmailId = (event) => {
    this.setState({
      emailId: event.target.value
    });
  }
  handlemobileNumber = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({ mobileNumber: event.target.value })
    }
  }
  handlepromotions = (event) => {
    let val = document.getElementById("chechk1").checked;
    this.setState({
      promotions: val
    });
  }
  render() {
    const { promotions } = this.state;
    return (
      <div>
        <div className="accountdetails">
          <div className="dashTitle" onClick={this.checkingfunction}>Account Details</div>
          <div className="dashSection">
            <div>
              <div className="addressForm">
                <form>
                  <div className="text-success personalMsgSuccess text-center"></div>
                  <div className="errorMsg personalMsgError text-center"></div>
                  <div className="adressFormList">
                    <label className="labelField">Full Name<sup>*</sup></label>
                    <div className="formInputSection">
                      <input type="text" autoComplete="off" tabindex='1' className="formInputField" disabled={(this.state.disabledName) ? "disabledName" : ""} value={this.state.displayName} onChange={this.handleFirstNameChange} placeholder="" id="txtFirstName" />
                    </div>
                  </div>
                  
                  <div className="adressFormList">
                    <label className="labelField"  >Mobile Number<sup>*</sup></label>
                    <div className="formInputSection">
                      <input type="text" tabindex='3' autoComplete="off" className="formInputField" disabled={(this.state.disabledName) ? "disabledName" : ""} value={this.state.mobileNumber} onChange={this.handlemobileNumber} placeholder="" id="txtMobileNumber" />
                    </div>
                  </div>
                  <div className="adressFormList">
                    <label className="labelField">Email ID</label>
                    <div className="formInputSection">
                      <input type="text" tabindex='2' autoComplete="off" className="formInputField" disabled={(this.state.disabledName) ? "disabledName" : ""} value={this.state.emailId} onChange={this.handleEmailId} placeholder="" id="txtEmailId" />
                    </div>
                  </div>
                  <div className="adressFormList">
                    <label className="labelField"></label>
                    <div className="formInputSection">
                      <span className="numberDefult">
                        <span className="checkBoxSquare">
                          {promotions === true ?
                            <input type="checkbox" disabled={(this.state.disabledName) ? "disabledName" : ""} id="chechk1" checked={this.state.promotions}
                              onChange={this.handlepromotions}
                              name="chkIsOpt" value={this.state.promotions} />
                            :
                            <input type="checkbox" disabled={(this.state.disabledName) ? "disabledName" : ""} id="chechk1" name="check" onChange={this.handlepromotions} />
                          }
                          <label for="chechk1"></label>
                        </span>
                        Receive emails about Promotions and offers
                      </span>
                    </div>
                  </div>
                  <div className="adressFormButton adressButtonTop">
                    <span class="commonButton" tabindex='4' id="btnEditPersonal" onClick={this.onEdit} >Edit</span>
                    <span class="commonButton" tabindex='4' style={{ display: 'none' }} id="btnSavePersonal" onClick={(e) => { return this.handleButtonClick(this) }}>Save</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
