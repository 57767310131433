import React, { Component } from 'react';
import { getData } from '../services'
import Slider from 'react-slick';
export default class addressdetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addressList: [],
            storeLocations: [],
            isLoading: '0',
            mobile: '',
            alternateno: '',
            name: ''
        };
        this.handleMobileChange = this.handleMobileChange.bind(this);
        this.handleAltMobileChange = this.handleAltMobileChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
    }
    checkedMobile() {
        if (document.getElementById("chechk1").checked === true) {
            if (window.sessionStorage.getItem('mobileNumber') !== null && window.sessionStorage.getItem('mobileNumber') !== 'null' && window.sessionStorage.getItem('mobileNumber') !== '') {
                document.getElementById("txtMobile").value = window.sessionStorage.getItem('mobileNumber')
                this.setState({
                    mobile: window.sessionStorage.getItem('mobileNumber')
                })
            }
            else {
                document.getElementById("txtMobile").value = window.sessionStorage.getItem('userMobileNumber')
                this.setState({
                    mobile: window.sessionStorage.getItem('userMobileNumber')
                })
            }
        }
        else {
            this.setState({
                mobile: ''
            })
            document.getElementById("txtMobile").value = "";
        }
    }
    checkedAddress() {
        if (document.getElementById("chechkAddress").checked === true) {
            this.setState({
                isDefaultAddress: "true"
            })
        }
        else {
            this.setState({
                isDefaultAddress: "false"
            })
        }
    }
    editAddres = (addressObj) => {
        document.getElementById("hidAddressID").value = addressObj.buyerDeliveryAddressId;
        document.getElementById("txtTitle").value = addressObj.title;
        document.getElementById("txtName").value = addressObj.name;
        document.getElementById("txtAddress1").value = addressObj.address1;
        document.getElementById("txtPin").value = addressObj.zip;
        document.getElementById("txtMobile").value = addressObj.telephoneNumber;
        document.getElementById("txtAlterNum").value = addressObj.altNum;
        document.getElementById("txtAddress2").value = addressObj.address2;
        document.getElementById("txtCityName").value = addressObj.cityName;
        document.getElementById("hidtxtCityName").value = addressObj.cityId;
        document.getElementById("txtstate").value = addressObj.stateName;
        document.getElementById("hidtxtstate").value = addressObj.stateId;
        var a = (addressObj.locationId + '-' + addressObj.locationName + ' (' + addressObj.cityName + ')' + '-' + addressObj.cityId + '-' + addressObj.cityName + '-' + addressObj.stateId + '-' + addressObj.stateName)
        document.getElementById("txtLocality").value = a;
        document.getElementById("chechkAddress").value = addressObj.isDefaultAddress;
        this.setState({
            isDefaultAddress: addressObj.isDefaultAddress,
            mobile: addressObj.telephoneNumber,
            name: addressObj.name,
            alternateno: addressObj.alternateno
        })
    }
    setLocationData() {
        var locationData = document.getElementById("txtLocality").value.split('-')
        document.getElementById("hidtxtCityName").value = locationData[2];
        document.getElementById("txtCityName").value = locationData[3];
        document.getElementById("hidtxtstate").value = locationData[4];
        document.getElementById("txtstate").value = locationData[5];
    }
    componentDidMount() {
        let userId = localStorage.getItem('userId')
        const postData = {
            functionName: "checkout/getDeliveryAddressList",
            buyerId: userId,
        }
        getData(postData).then((data) => {
            if (data.data.result.deliveryAddressList) {
                this.setState({
                    addressList: data.data.result.deliveryAddressList,
                    isLoading: '1'
                });
            }
        })
        if (localStorage.getItem('cityId') !== null) {
            let myCategoryId = window.sessionStorage.getItem('siteCategoryId');
            let sellerId = window.sessionStorage.getItem('sellerId');
            const postData = {
                functionName: "lokal/locationList",
                myCategoryId: (myCategoryId === 0 ? '' : myCategoryId),
                cityId: '',
                locationKeyword: '',
                storeId: '',
                sellerId: (sellerId === null ? '' : sellerId),
            }
            getData(postData).then((data) => {
                if (data.data.result.locationDetails.length > 0) {
                    this.setState({
                        storeLocations: data.data.result.locationDetails
                    });
                }
            })
        }
    }
    deleteAddress(buyerDeliveryAddressId, i) {
        const postData = {
            functionName: "checkout/deleteDeliveryAddress",
            id: buyerDeliveryAddressId
        }
        getData(postData).then((data) => {
            window.location.reload();
        });
        document.getElementById('addressListDiv' + i).style.display = 'none';
        alert("Address Deleted Successfully")
    }
    saveAddress() {
        var addressTitle = document.getElementById('txtTitle').value;
        var name = document.getElementById('txtName').value;
        var address1 = document.getElementById('txtAddress1').value;
        var zip = document.getElementById('txtPin').value;
        var mobile = document.getElementById('txtMobile').value;
        var alternateno = document.getElementById('txtAlterNum').value;
        var address2 = document.getElementById('txtAddress2').value;
        var location = document.getElementById('txtLocality').value;
        var phoneno = /^[0-9]*$/;
        if (name === '') {
            alert("Name required");
            document.getElementById('txtName').focus();
            return false;
        }
        else if (address1 === '') {
            alert("Address required");
            document.getElementById('txtAddress1').focus();
            return false;
        }
        else if (location === '0') {
            alert("Location required");
            document.getElementById('txtLocality').focus();
            return false;
        }
        else if (zip === '') {
            alert("Zipcode required");
            document.getElementById('txtPin').focus();
            return false;
        }
        else if (mobile === '') {
            alert("Mobile Number required");
            document.getElementById('txtMobile').focus();
            return false;
        }
        else if (mobile !== "undefined") {
            if (!mobile.match(/^[0-9]{10}$/)) {
                alert("Please enter valid mobile number");
                document.getElementById('txtMobile').focus();
                return false;
            }
        }
        else if (phoneno.test(mobile) === false) {
            alert("Please enter valid mobile number");
            document.getElementById('txtMobile').focus();
            return false;
        }
        else if (alternateno !== "") {
            if (!alternateno.match(/^[0-9]{10}$/)) {
                alert("Please enter valid Alternate mobile number");
                document.getElementById('txtAlterNum').focus();
                return false;
            }
        }
        const postData = {
            functionName: "savedeliveryaddress",
            addressTitle: addressTitle,
            name: name,
            address1: address1,
            zip: zip,
            mobile: mobile,
            buyerId: localStorage.getItem('userId'),
            alternateno: alternateno,
            address2: address2,
            locationId: location,
            buyerDeliveryAddressId: document.getElementById('hidAddressID').value,
            isDefaultAddress: this.state.isDefaultAddress ? this.state.isDefaultAddress : "false"
        }
        getData(postData).then((data) => {
            let message = document.getElementById('hidAddressID').value ? "Address details updated successfully" : "Address details added successfully"
            document.getElementById('txtTitle').value = "";
            document.getElementById('txtName').value = "";
            document.getElementById('txtAddress1').value = "";
            document.getElementById('txtPin').value = "";
            document.getElementById('txtAlterNum').value = "";
            document.getElementById('txtAddress2').value = "";
            document.getElementById('txtLocality').value = "";
            document.getElementById('txtMobile').value = "";
            document.getElementById('hidAddressID').value = "";
            alert(message)
            window.location.reload();
        });
    }
    handleMobileChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ mobile: e.target.value })
            document.getElementById('txtMobile').value = e.target.value;
        }
    }
    handleAltMobileChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ alternateno: e.target.value })
            document.getElementById('txtAlterNum').value = e.target.value;
        }
    }
    handleNameChange = (e) => {
        var regex = /^[a-zA-Z ]*$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            this.setState({ name: e.target.value })
            document.getElementById('txtName').value = e.target.value;
        }
    }
    render() {
        const addressSetting = {
            dots: true,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        swipeToSlide: true
                    }
                }
            ]
        };
        return (
            <div className="accountdetails">
                <div className="dashTitle">Address Details</div>
                <div className="dashSection">
                    <div className="addressForm addressLeftForm">
                        <div className="errorMsg addressErrorMsg"></div>
                        <input type="hidden" className="formInputField" id="hidAddressID" />
                        <div className="adressFormList">
                            <label className="labelField">Address Title</label>
                            <div className="formInputSection">
                                <input type="text" tabindex='1' className="formInputField" id="txtTitle" placeholder="Address Title" />
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField" >Name<sup>*</sup></label>
                            <div className="formInputSection">
                                <input type="text" tabindex='2' className="formInputField" id="txtName" placeholder="Full Name" />
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField" >House/Street/Area/Locality<sup>*</sup></label>
                            <div className="formInputSection">
                                <input type="text" tabindex='3' id="txtAddress1" className="formInputField" placeholder="" />
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField" >Landmark</label>
                            <div className="formInputSection">
                                <input type="text" tabindex='4' id="txtAddress2" className="formInputField" placeholder="" />
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField" >Location<sup>*</sup></label>
                            <div className="formInputSection">
                                <select className="formInputField" id="txtLocality" tabindex='5' onChange={() => this.setLocationData()}>
                                    <option value="0">Select Location</option>
                                    {this.state.storeLocations.map((locationObject, k) => (
                                        <option value={locationObject.locationId + '-' + locationObject.location + '-' + locationObject.cityId + '-' + locationObject.city + '-' + locationObject.stateId + '-' + locationObject.stateName} >{locationObject.location}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField">City<sup>*</sup></label>
                            <div className="formInputSection">
                                <input type="text" tabindex='4' id="txtCityName" className="formInputField" placeholder="City" readOnly />
                                <input type="hidden" tabindex='4' id="hidtxtCityName" className="formInputField" placeholder="" readOnly />
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField" >State<sup>*</sup></label>
                            <div className="formInputSection">
                                <input type="text" tabindex='4' id="txtstate" className="formInputField" placeholder="State" readOnly />
                                <input type="hidden" tabindex='4' id="hidtxtstate" className="formInputField" placeholder="" readOnly />
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField">Zipcode<sup>*</sup></label>
                            <div className="formInputSection">
                                <input type="text" tabindex='8' id="txtPin" className="formInputField" placeholder="" />
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField" >Mobile Number<sup>*</sup></label>
                            <div className="formInputSection">
                                <span className="numberDefult">
                                    <span className="checkBoxSquare">
                                        <input type="checkbox" tabindex='9' id="chechk1" name="check" value="None" onClick={(e) => this.checkedMobile()} />
                                        <label for="chechk1"></label>
                                    </span>
                                    Same as registered mobile
                                </span>
                                <span className="numberField">
                                    +91
                                    <input type="text" tabindex='10' id="txtMobile" maxLength={10} value={this.state.mobile} onChange={this.handleMobileChange} className="formInputField" placeholder="" />
                                </span>
                            </div>
                        </div>
                        <div className="adressFormList">
                            <label className="labelField" >Alternate Mobile No.</label>
                            <div className="formInputSection">
                                <input type="text" tabindex='11' id="txtAlterNum" className="formInputField" maxLength={10} value={this.state.alternateno} onChange={this.handleAltMobileChange} placeholder="" />
                                <span className="numberDefult">
                                    <span className="checkBoxSquare">
                                        <input type="checkbox" tabindex='12' id="chechkAddress" name="chechkAddress" value="None" defaultChecked={this.state.isDefaultAddress} onClick={(e) => this.checkedAddress()} />
                                        <label for="chechkAddress"></label>
                                    </span>
                                    Make this my default address
                                </span>
                            </div>
                        </div>
                        <div className="adressFormButton adressButtonTop">
                            <span class="commonButton " tabindex='13' onClick={(e) => { return this.saveAddress(this) }}>Save</span>
                        </div>
                    </div>
                    <div className="addressRightShow">
                        <div className="addressSection">
                            <Slider {...addressSetting}>
                                {this.state.addressList.map((addressObj, i) => (
                                    <div key={i} className="addressList" id={'addressListDiv' + i} >
                                        <div className="addressTitle" >Address</div>
                                        <div className="addressDetails">
                                            <div className="addressPanel">
                                                <h4>{addressObj.title}</h4>
                                                <p>{addressObj.name}</p>
                                                <p>{addressObj.address1}</p>
                                                {addressObj.address2 !== "" ?
                                                    <p>{addressObj.address2}</p>
                                                    : ""
                                                }
                                                <p>{addressObj.locationName},{addressObj.cityName} ,{addressObj.stateName}</p>
                                                <p>{addressObj.telephoneNumber}</p>
                                                {addressObj.altNum !== "" ?
                                                    <p>{addressObj.altNum}</p>
                                                    : ""
                                                }
                                                <p>{addressObj.zip}</p>
                                                <p>
                                                    <img src="" alt="" />
                                                </p>
                                            </div>
                                            <div className="addressAction">
                                                <span className="addressEdit" id="updateId" onClick={(e) => { this.editAddres(addressObj) }} >Edit</span>
                                                <span className="addressDelete" onClick={(e) => { this.deleteAddress(addressObj.buyerDeliveryAddressId, i) }}>Delete</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
