import React, { Component, Suspense } from 'react';
import $ from "jquery";
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class footerContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      content: '',
      footerData: []
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const { footerData } = this.props.location.state;
    $(".sideMenuDiv").hide()
    return (
      <div className="coverWraper">
        {/* {(() => {
          if (siteCategoryId != '0' && siteCategoryId != 'null' && siteCategoryId != null) {           
            if (themeId == '36') {
              return (
                <PothysHeader></PothysHeader>
              )
            }
            else if (themeId == '34'|| themeId == '29') {
              return (
                <LokalInHeader></LokalInHeader>
              )
            }
            else {              
              return (
                <LokalHeader siteDataDetails={siteDetailsLokal}></LokalHeader>
              )
            }
          }
          else if (themeId == '35') {
            return (
              <LollipopHeader></LollipopHeader>
            )
          }
          else if (themeId == '36') {           
            return (
              <PothysHeader></PothysHeader>
            )
          }
          else if (themeId == '37') {
            return (
              <StroberryHeader></StroberryHeader>
            )
          }
          else {            
            return (
              <Header></Header>
            )
          }
        })()} */}
        <Suspense fallback={<div></div>}>
          <Header />
          <div className="contentWrap">
            {window.sessionStorage.getItem('siteCategoryId') ?
              <div className="lokalMenuContent">
                <div dangerouslySetInnerHTML={{ __html: footerData.content }}></div>
              </div>
              :
              <div className="container">
                <div className="mainHeading">{footerData.name}</div>
                <div className="privacyselect">
                  <div dangerouslySetInnerHTML={{ __html: footerData.content }}></div>
                </div>
              </div>
            }
          </div>
          {/* {window.sessionStorage.getItem('siteCategoryId') ?
          <LokalInFooter></LokalInFooter> : */}
          <Footer></Footer>
        </Suspense>
        {/* } */}
      </div>
    )
  }
}