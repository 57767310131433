import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import deleteimg from "../images/lokal/delete.png"
import $ from "jquery"
import { getData, getCartItemsMultiple, pageHitAPICall } from '../services'
import calender from "../images/icons/calender.png"
import clock from "../images/icons/clock.png"
import Promocode from "./promocode"
import DatePicker from "react-datepicker";
import UploadPrescription from "../headerComponents/uploadPrescription"
import "react-datepicker/dist/react-datepicker.css";
const moment = require('moment-timezone');
export default class Checkout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartDetails: [],
            bookingDate: new Date(),
            bookingTime: '',
            minTime: this.calculateMinTime(new Date()),
            maxTime: new Date(),
            promocodeDetails: [],
            isPaymnetTypeExist: false,
            isPrescriptionOnly: '0'
        }
        this.savedAmount = this.savedAmount.bind(this);
        this.addToCart = this.addToCart.bind(this);
    }
    calculateMinTime = date => {
        let isToday = moment(date).isSame(moment(), 'day');
        if (isToday) {
            let nowAddOneHour = moment(new Date()).add({ hours: 0 }).toDate();
            return nowAddOneHour;
        }
        return moment().startOf('day').toDate();
    }
    calculateMaxTime = (date) => {
        var val = this.convertTimeFrom12To24(date)
        var val1 = val.split(":")
        var date = new Date();
        date.setHours(val1[0], val1[1], 0, 0);
        return date;
    }
    convertTimeFrom12To24(timeStr) {
        var colon = timeStr.indexOf(':');
        var hours = timeStr.substr(0, colon),
            minutes = timeStr.substr(colon + 1, 2),
            meridian = timeStr.substr(colon + 4, 2).toUpperCase();
        var hoursInt = parseInt(hours, 10),
            offset = meridian === 'PM' ? 12 : 0;
        if (hoursInt === 12) {
            hoursInt = offset;
        } else {
            hoursInt += offset;
        }
        return hoursInt + ":" + minutes;
    }
    handle = (date) => {
        this.setState({
            bookingTime: date,
            minTime: this.calculateMinTime(date)
        })
    }
    handleDateChange = date => {
        this.setState({
            bookingDate: date,
            minTime: this.calculateMinTime(date)
        });
    };
    handleTimeChange = time => {
        this.setState({
            bookingTime: time
        });
    };
    componentDidMount() {
        $("#checkOutHeadingDiv").text("Proceed to checkout")
        window.scrollTo(0, 0)
        this.savedAmount();
        this.getPaymentTypes();
    }
    getPaymentTypes(storeId) {
        var storeId = []
        var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
        for (var i = 0; i < selectGroceryItemCart.length; i++) {
            storeId.push(selectGroceryItemCart[i].storeId)
        }
        var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
        for (var j = 0; j < selectCommonItemCart.length; j++) {
            storeId.push(selectCommonItemCart[j].storeId)
        }
        var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
        for (var k = 0; k < selectRestaurantCart.length; k++) {
            storeId.push(selectRestaurantCart[k].storeId)
        }
        var unique = storeId.filter(function (itm, i, storeId) {
            return i === storeId.indexOf(itm);
        });
        var storeIdSplit = unique.toString().replace(/[\[\]']+/g, '')
        const postData = {
            functionName: "checkout/getPaymentOptions",
            storeId: storeIdSplit
        }
        getData(postData).then((data) => {
            if (data.data.success === '1') {
                if (data.data.result.paymentOptions.length > 0) {
                    this.setState({
                        isPaymnetTypeExist: true
                    });
                }
            }
        });
    }
    savedAmount() {
        // debugger
        var locationId = localStorage.getItem('locationId') === null ? '' : localStorage.getItem('locationId')
        var cityId = localStorage.getItem('cityIdGlobal') === null ? '' : localStorage.getItem('cityIdGlobal')
        var hasLocation = localStorage.getItem('hasLocation') === '1' ? '1' : '0'
        var cookieNewGroceryItemValue = []
        var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
        for (var i = 0; i < selectGroceryItemCart.length; i++) {
            var promoCodeDetails = $("#hidPromocodeDetails" + selectGroceryItemCart[i].storeId).val()
            var promoCodeArray = ''
            var isPromoCode = '0'
            if (promoCodeDetails !== null && promoCodeDetails !== 'null' && promoCodeDetails !== '' && promoCodeDetails !== 'undefined' && promoCodeDetails !== undefined) {
                promoCodeArray = JSON.parse(promoCodeDetails)
                isPromoCode = '1'
            }
            cookieNewGroceryItemValue[i] =
            {
                storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                itemNo: selectGroceryItemCart[i].itemNo.toString().trim(),
                isPromoCode: isPromoCode,
                promoCodeDetails: promoCodeArray
            }
        }
        var storeArrayForPrescription = []
        var k = cookieNewGroceryItemValue.length
        for (var i = 0; i < selectGroceryItemCart.length; i++) {
            if (typeof $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val() !== 'undefined') {
                if (storeArrayForPrescription.includes(selectGroceryItemCart[i].storeId) === false) {
                    storeArrayForPrescription.push(selectGroceryItemCart[i].storeId)
                    var prescriptionId = $("#hidPrescriptionId" + selectGroceryItemCart[i].storeId).val().slice(1)
                    var prescriptionIdSplit = prescriptionId.toString().trim().split(',');
                    for (var p = 0; p < prescriptionIdSplit.length; p++) {
                        if (prescriptionIdSplit[p] !== "") {
                            cookieNewGroceryItemValue[k] =
                            {
                                storeId: selectGroceryItemCart[i].storeId,
                                item_names: "",
                                item_ids: "",
                                quantity: "",
                                item_prices: "",
                                store_item_stock_ids: "",
                                pack_ids: "",
                                stock_quantity: "",
                                item_image: "",
                                itemNo: "",
                                isPromoCode: "",
                                promoCodeDetails: "",
                                phIds: prescriptionIdSplit[p]
                            }
                            k = parseInt(k) + 1
                        }
                    }
                }
            }
        }
        var k = cookieNewGroceryItemValue.length
        if (this.props.fromPage === 'Prescription') {
            var storeId = window.sessionStorage.getItem('storeId')
            if (typeof $("#hidPrescriptionId" + storeId).val() !== 'undefined' && $("#hidPrescriptionId" + storeId).val() !== "") {
                var prescriptionId = $("#hidPrescriptionId" + storeId).val().slice(1)
                var prescriptionIdSplit = prescriptionId.toString().split(',');
                for (var p = 0; p < prescriptionIdSplit.length; p++) {
                    if (prescriptionIdSplit[p] !== "") {
                        cookieNewGroceryItemValue[k] =
                        {
                            storeId: storeId,
                            item_names: "",
                            item_ids: "",
                            quantity: "",
                            item_prices: "",
                            store_item_stock_ids: "",
                            pack_ids: "",
                            stock_quantity: "",
                            item_image: "",
                            itemNo: "",
                            isPromoCode: "",
                            promoCodeDetails: "",
                            phIds: prescriptionIdSplit[p]
                        }
                        k = parseInt(k) + 1
                    }
                }
            }
            else {
                cookieNewGroceryItemValue[k] =
                {
                    storeId: storeId,
                    item_names: "",
                    item_ids: "",
                    quantity: "",
                    item_prices: "",
                    store_item_stock_ids: "",
                    pack_ids: "",
                    stock_quantity: "",
                    item_image: "",
                    itemNo: "",
                    isPromoCode: "",
                    promoCodeDetails: ""
                }
            }
            this.setState({
                isPrescriptionOnly: '1'
            });
        }
        var grocery = []
        if (cookieNewGroceryItemValue.length > 0) {
            grocery[0] = {
                cartCategory: "grocery",
                locationId: locationId,
                selectedProduct: cookieNewGroceryItemValue
            }
        }
        var cookieNewCommonItemValue = []
        var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
        for (var i = 0; i < selectCommonItemCart.length; i++) {
            var promoCodeDetails = $("#hidPromocodeDetails" + selectCommonItemCart[i].storeId).val()
            var promoCodeArray = ''
            var isPromoCode = '0'
            if (promoCodeDetails !== null && promoCodeDetails !== 'null' && promoCodeDetails !== '' && promoCodeDetails !== 'undefined' && promoCodeDetails !== undefined) {
                promoCodeArray = JSON.parse(promoCodeDetails)
                isPromoCode = '1'
            }
            cookieNewCommonItemValue[i] =
            {
                storeId: selectCommonItemCart[i].storeId.toString(),
                itemId: selectCommonItemCart[i].itemId.toString(),
                itemName: selectCommonItemCart[i].itemName,
                quantity: selectCommonItemCart[i].quantity,
                itemTitle: selectCommonItemCart[i].itemTitle,
                brand: selectCommonItemCart[i].brand,
                sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                itemMasterId: selectCommonItemCart[i].itemMasterId.toString(),
                netAmount: selectCommonItemCart[i].netAmount.toString(),
                mrp: selectCommonItemCart[i].mrp.toString(),
                itemImage: selectCommonItemCart[i].itemImage,
                itemNo: selectCommonItemCart[i].itemNo,
                isPromoCode: isPromoCode,
                promoCodeDetails: promoCodeArray,
                maxBuyCount: selectCommonItemCart[i].maxBuyCount
            }
        }
        var commonItem = []
        if (cookieNewCommonItemValue.length > 0) {
            commonItem[0] = {
                cartCategory: "commonItem",
                locationId: locationId,
                cityId: cityId,
                selectCommonItemCart: cookieNewCommonItemValue
            }
        }
        var cookieNewRestaurantValue = []
        var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
        for (var i = 0; i < selectRestaurantCart.length; i++) {
            var promoCodeDetails = $("#hidPromocodeDetails" + selectRestaurantCart[i].storeId).val()
            var promoCodeArray = ''
            var isPromoCode = '0'
            if (promoCodeDetails !== null && promoCodeDetails !== 'null' && promoCodeDetails !== '' && promoCodeDetails !== 'undefined' && promoCodeDetails !== undefined) {
                promoCodeArray = JSON.parse(promoCodeDetails)
                isPromoCode = '1'
            }
            cookieNewRestaurantValue[i] =
            {
                storeId: selectRestaurantCart[i].storeId.toString().trim(),
                itemId: selectRestaurantCart[i].itemId.toString().trim(),
                itemName: selectRestaurantCart[i].itemName.toString().trim(),
                quantity: selectRestaurantCart[i].quantity.toString().trim(),
                itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                toppingsData: selectRestaurantCart[i].toppingsData.toString().trim(),
                isPromoCode: isPromoCode,
                promoCodeDetails: promoCodeArray
            }
        }
        var restaurant = []
        if (cookieNewRestaurantValue.length > 0) {
            restaurant[0] = {
                cartCategory: "restaurant",
                locationId: locationId,
                selectRestaurantCart: cookieNewRestaurantValue
            }
        }
        var postData = {
            "functionName": "cartItem",
            "commonItem": commonItem,
            "restaurant": restaurant,
            "grocery": grocery,
            "paymentId": "",
            "hasLocation": hasLocation,
            "timeZone": new Date().getTimezoneOffset().toString(),
            "enableOnHold": "1",
            "buyerId": localStorage.getItem('userId')
        }
        getCartItemsMultiple(postData).then((data) => {
            console.log("cartitem",data)
            if (data.data.success === '1') {
                this.setState({
                    cartDetails: data.data.result
                });
            }
        })
    }
    setStore(groceryObject) {
        window.sessionStorage.setItem('storeId', groceryObject.storeId)
        window.sessionStorage.setItem('mainGroupCategory',groceryObject.mainGroupCategoryId)
    }
    checkCartCount() {
        var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
        var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
        var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
        if (selectGroceryItemCart.length === 0 && selectCommonItemCart.length === 0 && selectRestaurantCart.length === 0) {
            window.location.href = "/";
        }
        else {
            this.savedAmount();
        }
    }
    deleteStoreFromCart(storeId) {
        if (window.confirm("Do you want to delete the items in this store ?")) {
            var cookieNewGroceryItemValue = []
            var selectGroceryItemCart = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
            var index = 0;
            for (var i = 0; i < selectGroceryItemCart.length; i++) {
                var storeIdCart = selectGroceryItemCart[i].storeId
                if (storeId.toString() !== storeIdCart.toString()) {
                    cookieNewGroceryItemValue[index] = {
                        storeId: selectGroceryItemCart[i].storeId.toString().trim(),
                        item_names: selectGroceryItemCart[i].item_names.toString().trim(),
                        item_ids: selectGroceryItemCart[i].item_ids.toString().trim(),
                        quantity: selectGroceryItemCart[i].quantity.toString().trim(),
                        item_prices: selectGroceryItemCart[i].item_prices.toString().trim(),
                        store_item_stock_ids: selectGroceryItemCart[i].store_item_stock_ids.toString().trim(),
                        pack_ids: selectGroceryItemCart[i].pack_ids.toString().trim(),
                        stock_quantity: selectGroceryItemCart[i].stock_quantity.toString().trim(),
                        item_image: selectGroceryItemCart[i].item_image.toString().trim(),
                        itemNo: selectGroceryItemCart[i].itemNo.toString().trim()
                    }
                    index = parseInt(index) + 1;
                }
            }
            localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewGroceryItemValue))
            var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
            var cookieNewCommonItemValue = []
            index = 0
            for (var i = 0; i < selectCommonItemCart.length; i++) {
                if (storeId.toString() !== selectCommonItemCart[i].storeId.toString()) {
                    cookieNewCommonItemValue[index] =
                    {
                        storeId: selectCommonItemCart[i].storeId.toString().trim(),
                        itemId: selectCommonItemCart[i].itemId.toString().trim(),
                        itemName: selectCommonItemCart[i].itemName.toString().trim(),
                        quantity: selectCommonItemCart[i].quantity.toString().trim(),
                        itemTitle: selectCommonItemCart[i].itemTitle.toString().trim(),
                        brand: selectCommonItemCart[i].brand.toString().trim(),
                        sizeName: selectCommonItemCart[i].sizeName.toString().trim(),
                        sizeId: selectCommonItemCart[i].sizeId.toString().trim(),
                        cmItemSizeId: selectCommonItemCart[i].cmItemSizeId.toString().trim(),
                        itemMasterId: selectCommonItemCart[i].itemMasterId.toString().trim(),
                        netAmount: selectCommonItemCart[i].netAmount.toString().trim(),
                        mrp: selectCommonItemCart[i].mrp.toString().trim(),
                        itemImage: selectCommonItemCart[i].itemImage.toString().trim(),
                        itemNo: selectCommonItemCart[i].itemNo.toString().trim()
                    }
                    index = parseInt(index) + 1
                }
            }
            localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewCommonItemValue))
            var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
            var cookieNewRestaurantValue = []
            var j = 0
            for (var i = 0; i < selectRestaurantCart.length; i++) {
                if (storeId.toString() !== selectRestaurantCart[i].storeId.toString()) {
                    cookieNewRestaurantValue[j] =
                    {
                        storeId: selectRestaurantCart[i].storeId.toString().trim(),
                        itemId: selectRestaurantCart[i].itemId.toString().trim(),
                        itemName: selectRestaurantCart[i].itemName.toString().trim(),
                        quantity: selectRestaurantCart[i].quantity.toString().trim(),
                        itemSize: selectRestaurantCart[i].itemSize.toString().trim(),
                        itemMrp: selectRestaurantCart[i].itemMrp.toString().trim(),
                        itemImage: selectRestaurantCart[i].itemImage.toString().trim(),
                        storeItemSizeId: selectRestaurantCart[i].storeItemSizeId.toString().trim(),
                        itemNo: selectRestaurantCart[i].itemNo.toString().trim(),
                        toppingsData: selectRestaurantCart[i].toppingsData.toString().trim()
                    }
                    j = parseInt(j) + 1
                }
            }
            localStorage.setItem('selectRestaurantCart', JSON.stringify(cookieNewRestaurantValue))
            if (cookieNewGroceryItemValue.length === 0 && cookieNewCommonItemValue.length === 0 && cookieNewRestaurantValue.length === 0) {
                window.location.href = "/";
            }
            else {
                $("#cartCount").text(cookieNewGroceryItemValue.length)
                this.savedAmount();
            }
        }
        else {
            return false;
        }
    }
    addToCart(itemNo, storeId, sisId, itemId, itemPrice, packId, type, stockQuantity, itemName, quantity, image, cartItemNo) {
        // debugger
        let isDelete = false;
        var prnum;
        if (type === "inc") {
             prnum = $('#itemQty' + itemNo).val();
            if (parseInt(prnum) >= parseInt(stockQuantity)) {
                $("#qtyErrorCart" + itemNo).show();
                $("#qtyErrorCart" + itemNo).text("Only " + stockQuantity + " qty available")
                $('#itemQty' + itemNo).val(stockQuantity)
                $("#itemCount" + cartItemNo).text(stockQuantity)
                $("#txtQty" + cartItemNo).val(stockQuantity)
            }
            else {
                prnum++;
                $("#qtyErrorCart" + itemNo).hide();
                $("#qtyErrorCart" + itemNo).text("")
                $('#itemQty' + itemNo).val(prnum);
                $("#txtQty" + cartItemNo).val(prnum)
                $("#itemCount" + cartItemNo).text(prnum)
            }
        }
        else if (type === "dec") {
            $("#qtyErrorCart" + itemNo).hide();
            $("#qtyErrorCart" + itemNo).text("")
             prnum = $('#itemQty' + itemNo).val();
            prnum--;
            if (prnum === 0) {
                isDelete = true;
                $("#itemCount" + cartItemNo).text("")
                ////for save more
                $("#itemCountMain" + cartItemNo).text("")
                $("#itemAddedColour" + cartItemNo).hide()
                $('#addToCart' + cartItemNo).removeClass("cart_clk");
                $('#itemQty' + itemNo).val(prnum);
                $("#addToCart" + cartItemNo).text("ADD TO CART")
            }
            else {
                $("#itemCount" + cartItemNo).text(prnum)
                ////for save more
                $("#itemCountMain" + cartItemNo).text(prnum)
                $("#txtQtyMain" + cartItemNo).val(prnum)
                $("#txtQty" + cartItemNo).val(prnum)
            }
            $('#itemQty' + itemNo).val(prnum);
        }
        else if (type === "remove") {
            isDelete = true;
            $("#qtyErrorCart" + itemNo).hide();
            $("#qtyErrorCart" + itemNo).text("")
            $("#itemCount" + cartItemNo).text("")
            ////for save more
            $("#itemCountMain" + cartItemNo).text("")
            $("#itemAddedColour" + cartItemNo).hide()
            $('#addToCart' + cartItemNo).removeClass("cart_clk");
            $('#itemQty' + itemNo).val(0);
            $("#addToCart" + cartItemNo).text("ADD TO CART")
        }
        this.updateCart(storeId, itemName, itemId, $('#itemQty' + itemNo).val(), itemPrice, sisId, packId, isDelete, stockQuantity, image, cartItemNo)
    }
    updateCart(storeId, item_names, item_ids, quantity, item_prices, store_item_stock_ids, pack_ids, isDelete, stock_quantity, itemImage, cartItemNo) {
        // debugger
        var selectedCartProduct = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
        if (localStorage.getItem('selectedCartProduct') === null) {
            if (isDelete === false || quantity > 0) {
                var selectedProduct = {
                    storeId: storeId,
                    item_names: item_names,
                    item_ids: item_ids,
                    quantity: quantity,
                    item_prices: item_prices,
                    store_item_stock_ids: store_item_stock_ids,
                    pack_ids: pack_ids,
                    stock_quantity: stock_quantity,
                    item_image: itemImage,
                    itemNo: cartItemNo
                }
                selectedCartProduct.push(selectedProduct)
                localStorage.setItem('selectedCartProduct', JSON.stringify(selectedCartProduct))
            }
        }
        else {
            // var selectedCartProduct = JSON.parse(localStorage.getItem('selectedCartProduct')) || []
            var cookieNewValue = [];
            var cookieArray = selectedCartProduct;
            var cookieUpdated = false
            var cookieNewValueCount = 0;
            for (var i = 0; i < cookieArray.length; i++) {
                if (cookieArray[i] !== null && cookieArray[i] !== "null" && cookieArray[i] !== "") {
                    var cookieStoreId = cookieArray[i].storeId
                    var cookieItemId = cookieArray[i].item_ids
                    if (item_ids === cookieItemId && storeId === cookieStoreId) {
                        if (isDelete === false && quantity > 0) {
                            cookieNewValue[cookieNewValueCount] =
                            {
                                storeId: storeId,
                                item_names: item_names,
                                item_ids: item_ids,
                                quantity: quantity,
                                item_prices: item_prices,
                                store_item_stock_ids: store_item_stock_ids,
                                pack_ids: pack_ids,
                                stock_quantity: stock_quantity,
                                item_image: itemImage,
                                itemNo: cartItemNo
                            }
                            cookieNewValueCount++;
                        }
                        cookieUpdated = true
                    }
                    else {
                        cookieNewValue[cookieNewValueCount] = cookieArray[i];
                        cookieNewValueCount++;
                    }
                }
            }
            if (cookieUpdated === false) {
                if (quantity > 0) {
                    cookieNewValue[cookieNewValueCount] = {
                        storeId: storeId,
                        item_names: item_names,
                        item_ids: item_ids,
                        quantity: quantity,
                        item_prices: item_prices,
                        store_item_stock_ids: store_item_stock_ids,
                        pack_ids: pack_ids,
                        stock_quantity: stock_quantity,
                        item_image: itemImage,
                        itemNo: cartItemNo
                    }
                    cookieUpdated = true
                }
            }
            else {
                $(".checkoutAvailPromo" + storeId).removeClass("promoCodeDisable")
                $(".checkoutAvailPromo" + storeId).hide()
                $("#hidPromocodeDetails" + storeId).val('');
                $("#txtPromocode" + storeId).val('');
                $("#applyPromo" + storeId).show()
                $("#remPromo" + storeId).hide()
                $("#promoLink" + storeId).show()
                $("#promoNoDataLink" + storeId).hide()
            }
            localStorage.setItem('selectedCartProduct', JSON.stringify(cookieNewValue))
        }
        $("#cartCount").text((JSON.parse(localStorage.getItem('selectedCartProduct')).length))
        this.checkCartCount();
    }
    isNumber = (e, itemNo, stock_quantity, itemName, itemId, itemPrice, sisId, packId, quantity, image, cartItemNo, storeId) => {
        let isDelete = false;
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            var prnum = $('#itemQty' + itemNo).val();
            if (parseInt(prnum) >= parseInt(stock_quantity)) {
                prnum = stock_quantity;
                $("#qtyErrorCart" + itemNo).show();
                $("#qtyErrorCart" + itemNo).text("Only " + stock_quantity + " qty available")
                $('#itemQty' + itemNo).val(stock_quantity)
                $("#itemCount" + cartItemNo).text(stock_quantity)
                ////for save more
                $("#itemCountMain" + cartItemNo).text(stock_quantity)
                $("#txtQtyMain" + cartItemNo).val(stock_quantity)
                $("#txtQty" + cartItemNo).val(stock_quantity)
            }
            else if (prnum === 0) {
                isDelete = true;
                $("#itemCount" + cartItemNo).text("")
                ////for save more
                $("#itemCountMain" + cartItemNo).text("")
                $("#txtQtyMain" + cartItemNo).text("")
                $("#itemAddedColour" + cartItemNo).hide()
                $('#addToCart' + cartItemNo).removeClass("cart_clk");
                $('#itemQty' + itemNo).val(prnum);
                $("#addToCart" + cartItemNo).text("ADD TO CART")
                $("#txtQty" + cartItemNo).val(prnum)
            }
            else {
                $("#qtyErrorCart" + itemNo).hide();
                $("#qtyErrorCart" + itemNo).text("");
                $('#itemQty' + itemNo).val(prnum)
                $('#itemQty' + cartItemNo).val(prnum)
                $("#itemCount" + cartItemNo).text(prnum)
                ////for save more
                $("#itemCountMain" + cartItemNo).text(prnum)
                $("#txtQtyMain" + cartItemNo).val(prnum)
                $("#txtQty" + cartItemNo).val(prnum)
            }
        }
        else {
            var prnum = e.target.value.replace(/[^0-9\.]/g, '');
            $('#itemQty' + itemNo).val(prnum)
        }
        this.updateCart(storeId, itemName, itemId, $('#itemQty' + itemNo).val(), itemPrice, sisId, packId, isDelete, stock_quantity, image, cartItemNo)
    }
    confirmAllStore() {
        var isSlot = '1'
        for (var i = 0; i < this.state.cartDetails.length; i++) {
            for (var j = 0; j < this.state.cartDetails[i].grocery.length; j++) {
                var storeId = this.state.cartDetails[i].grocery[j].storeId
                if ($("#txtSlot" + storeId).val() === null || $("#txtSlot" + storeId).val() === "null" || $("#txtSlot" + storeId).val() === "") {
                    isSlot = '0'
                    alert("Delivery Slot Required");
                    $("#slot" + storeId).focus()
                    break;
                }
            }
        }
        if (isSlot === '1') {
            for (var i = 0; i < this.state.cartDetails.length; i++) {
                for (var j = 0; j < this.state.cartDetails[i].restaurant.length; j++) {
                    var storeId = this.state.cartDetails[i].restaurant[j].storeId
                    var slotflag = this.setRestaurantSlot(storeId)
                    if (slotflag == '1') {
                        isSlot = '0'
                        $("#slot" + storeId).focus()
                        break;
                    }
                }
            }
        }
        if (isSlot === '1') {
            window.scrollTo(0, 0)
            $("#checkOutHeadingDiv").text("Select a delivery address")
            $("#itemSummeryDiv").hide();
            $("#shippingDiv").show();
            $("#hidStoreIdForCheckout").val('')
            $("#txtAllConfirm").val()
            $("#checkOutStep2").addClass("orderIndicationActive")
        }
    }
    confirmStoreWise(storeId, mainGroupCategpryId, mobileNumber, emailId) {
        var hasAuthentication = window.sessionStorage.getItem('hasAuthentication')
        if (hasAuthentication === "1" && $("#txtConsumerCard" + storeId).val() === "") {
            alert("IDA branch name required");
            $("#txtConsumerCard" + storeId).focus();
        }
        else {
            var slotflag = '0';
            $("#txtTottal" + storeId).val()
            $("#txtTottalCon").val('')
            if (mainGroupCategpryId === '5') {
                $("#txtTottalCon").val('')
                $("#txtTottal" + storeId).val()
                slotflag = this.setRestaurantSlot(storeId)
            }
            if (slotflag === '0') {
                if ($("#txtSlot" + storeId).val() === "") {
                    alert("Delivery Slot Required");
                    $("#slot" + storeId).focus()
                    return false;
                }
                else {
                    $("#hidStoreIdForCheckout").val(storeId)
                    $("#itemSummeryDiv").hide();
                    $("#txtTottal" + storeId).val()
                    $("#txtTottalCon").val('')
                    $("#shippingDiv").show();
                    $("#checkOutHeadingDiv").text("Select a delivery address")
                    $("#checkOutStep2").addClass("orderIndicationActive")
                    window.scrollTo(0, 0)
                }
            }
        }
        var sellerID = "";
        var moduleID = 5;
        var siteCategoryGroupId = "";
        if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
            siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
        }
        else {
            sellerID = window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)

    }
    handleChangeSlot(event, storeId, mainGroupCategpryId) {
        if (mainGroupCategpryId === '5') {
            if (event.target.value === '0') {
                $(".scheduleDelivery" + storeId).hide();
                $(".nonScheduleDelivery" + storeId).hide();
            }
            else if (event.target.value === 'schedule delivery') {
                $(".scheduleDelivery" + storeId).show();
                $(".nonScheduleDelivery" + storeId).hide();
                this.setState({
                    bookingDate: new Date(),
                    bookingTime: '',
                    minTime: this.calculateMinTime(new Date())
                })
            }
            else {
                var slot = $("#slot" + storeId).val();
                $(".nonScheduleDelivery" + storeId).show();
                $(".scheduleDelivery" + storeId).hide();
                if (slot.indexOf('Tomorrow') !== -1) {
                    var a = slot.split(' ')
                    var b = a[5].split('(')
                    this.setState({
                        bookingDate: new Date(),
                        minTime: this.calculateMaxTime(a[1] + ' ' + a[2]),
                        maxTime: this.calculateMaxTime(a[4] + ' ' + b[0])
                    })
                }
                else {
                    var a = slot.split(' ')
                    var b = a[5].split('(')
                    this.setState({
                        minTime: this.calculateMinTime(new Date()),
                        maxTime: this.calculateMaxTime(a[4] + ' ' + b[0])
                    })
                }
            }
        }
        else {
            $("#txtSlot" + storeId).val(event.target.value)
        }
    }
    setRestaurantSlot(storeId) {
        var flag = '0';
        if ($("#slot" + storeId).val() === '0') {
            alert("Select Delivery Slot");
            $("#slot").focus();
            flag = '1';
        }
        else if ($("#slot" + storeId).val() === "schedule delivery" && $("#dateScheduled" + storeId).val() === "") {
            alert("Delivery date required");
            $("#dateScheduled" + storeId).focus();
            flag = '1';
        }
        else if ($("#slot" + storeId).val() === "schedule delivery" && $("#timeScheduled" + storeId).val() === "") {
            alert("Delivery time required");
            $("#timeScheduled" + storeId).focus();
            flag = '1';
        }
        else if ($("#slot" + storeId).val() === "schedule delivery") {
            var from = $("#dateScheduled" + storeId).val()
            var fromdate = from.split('/')
            from = fromdate[2] + "-" + fromdate[1] + "-" + fromdate[0]
            var fromTime = from + " " + $("#timeScheduled" + storeId).val()
            var fromTimeSave = fromdate[0] + "/" + fromdate[1] + "/" + fromdate[2] + " " + $("#timeScheduled" + storeId).val()
            $("#txtSlot" + storeId).val(fromTimeSave)
        }
        else {
            if ($("#timeNonScheduled" + storeId).val() === "") {
                alert("Delivery time required");
                $("#timeNonScheduled" + storeId).focus();
                flag = '1';
            }
            else {
                var slot = $("#slot" + storeId).val()
                slot = slot.substring(0, slot.length - 1).split('\(');
                var today
                if (slot[1] === 'Tomorrow') {
                    today = new Date();
                    var numberOfDaysToAdd = 1;
                    today.setDate(today.getDate() + numberOfDaysToAdd);
                }
                else {
                    today = new Date()
                }
                var dd = today.getDate();
                //The value returned by getMonth is an integer between 0 and 11, referring 0 to January, 1 to February, and so on.
                var mm = today.getMonth() + 1;
                var yyyy = today.getFullYear();
                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }
                today = dd + '/' + mm + '/' + yyyy
                var fromTime = today + " " + $("#timeNonScheduled" + storeId).val()
                $("#txtSlot" + storeId).val(fromTime)
            }
        }
        return flag;
    }
    continueClick() {
        let themeId = localStorage.getItem('themeId')
        if (window.sessionStorage.getItem('mainGroupCategory') === '5') {
            window.location.href = "/restaurantListing/0/0/";
        }
        else if (window.sessionStorage.getItem('mainGroupCategory') === '8') {
            if (themeId === '38') {
                window.location.href = '/'
            }
            else {
                window.location.href = "/cmItemListing/0/0/0/";
            }

        }
        else {
            if (themeId === '31' || themeId === '30') {
                window.location.href = '/'
            }
            else {
                window.location.href = '/home'
            }
        }
    }
    addToCartRestaurant(type, itemId, storeId, itemName, itemMrp, itemImage, itemSize, storeItemSizeId, toppingsData) {
        let isDelete = false;
        let itemNo = itemId + '-' + storeItemSizeId + '-' + toppingsData.toString().replace(',', '-')
        if (type === "addToCart") {
            $('#addToCart' + itemNo).addClass("cart_clk");
            if ($('.cartInput' + itemNo).val() === 0 || $('.cartInput' + itemNo).val() === '0') {
                $('.cartInput' + itemNo).val(1);
            }
        }
        else if (type === "inc") {
            var addItem = $('.cartInput' + itemNo).val()
            addItem++;
            if (addItem > 0) {
                $('.cartInput' + itemNo).val(addItem);
            }
            else if (addItem === 0) {
                $('.cartInput' + itemNo).val(1);
            }

        }
        else if (type === "dec") {
            var removeItem = $('.cartInput' + itemNo).val()
            removeItem--;
            if (removeItem == 0) {
                isDelete = true;
                $('#addToCart' + itemNo).removeClass("cart_clk");
            }
            $('.cartInput' + itemNo).val(removeItem);
        }
        else if (type === "txtChange") {
            var txtItem = $('.cartInput' + itemNo).val()
            if (txtItem == 0) {
                $('#addToCart' + itemNo).removeClass("cart_clk");
            }
            else {
                $('.cartInput' + itemNo).val(txtItem);
            }
        }
        else if (type === "remove") {
            $('#addToCart' + itemNo).removeClass("cart_clk");
            $("#txtQty" + itemNo).val(0)
            $('.cartInput' + itemNo).val(0)
        }
        this.updateCartRestaurant(storeId, itemId, itemName, $('.cartInput' + itemNo).val(), itemSize, itemMrp, itemImage, storeItemSizeId, isDelete, toppingsData)
    }

    updateCartRestaurant(storeId, itemId, itemName, quantity, itemSize, itemMrp, itemImage, storeItemSizeId, isDelete, toppingsData) {
        let itemNo = itemId + '-' + storeItemSizeId
        var selectRestaurantCart = JSON.parse(localStorage.getItem('selectRestaurantCart')) || []
        if (localStorage.getItem('selectRestaurantCart') === null) {
            if (isDelete == false || quantity > 0) {
                var selectedRestaurantProduct = {
                    storeId: storeId,
                    itemId: itemId,
                    itemName: itemName,
                    quantity: quantity,
                    itemSize: itemSize,
                    itemMrp: itemMrp,
                    itemImage: itemImage,
                    storeItemSizeId: storeItemSizeId,
                    itemNo: itemNo,
                    toppingsData: toppingsData
                }
                selectRestaurantCart.push(selectedRestaurantProduct)
                localStorage.setItem('selectRestaurantCart', JSON.stringify(selectRestaurantCart))
            }
        }
        else {
            var cookieNewRestaurantValue = [];
            var cookieRestaurantArray = selectRestaurantCart;
            var cookieRestaurantUpdated = false
            var cookieRestaurantNewValueCount = 0;
            for (var i = 0; i < cookieRestaurantArray.length; i++) {
                if (cookieRestaurantArray[i] !== null && cookieRestaurantArray[i] != 'null' && cookieRestaurantArray[i] !== "") {
                    var cookieStoreId = cookieRestaurantArray[i].storeId
                    var cookieItemId = cookieRestaurantArray[i].itemId
                    var cookieSizeId = cookieRestaurantArray[i].storeItemSizeId
                    var toppingsDataFromCart = cookieRestaurantArray[i].toppingsData
                    var newTopping = false
                    if (null !== toppingsData && 'null' !== toppingsData && '' !== toppingsData) {
                        var toppingsDataFromCartSplit = toppingsData.split(',')
                        for (var t = 0; t < toppingsDataFromCartSplit.length; t++) {
                            if (toppingsDataFromCart.indexOf(toppingsDataFromCartSplit[t]) === -1) {
                                newTopping = true
                            }
                            else {
                                //   alert("else t")
                            }
                        }
                    }
                    else {
                        if (null !== toppingsDataFromCart && 'null' !== toppingsDataFromCart && '' !== toppingsDataFromCart) {
                            newTopping = true
                        }
                    }
                    if (itemId == cookieItemId && storeId == cookieStoreId && storeItemSizeId == cookieSizeId && newTopping == false) {
                        if (isDelete === false && quantity > 0) {
                            cookieNewRestaurantValue[cookieRestaurantNewValueCount] =
                            {
                                storeId: storeId,
                                itemId: itemId,
                                itemName: itemName,
                                quantity: quantity,
                                itemSize: itemSize,
                                itemMrp: itemMrp,
                                itemImage: itemImage,
                                storeItemSizeId: storeItemSizeId,
                                itemNo: itemNo,
                                toppingsData: toppingsData
                            }
                            cookieRestaurantNewValueCount++;
                        }
                        cookieRestaurantUpdated = true
                    }
                    else {
                        cookieNewRestaurantValue[cookieRestaurantNewValueCount] = cookieRestaurantArray[i];
                        cookieRestaurantNewValueCount++;
                    }
                }
            }
            if (cookieRestaurantUpdated === false) {
                if (quantity > 0) {
                    cookieNewRestaurantValue[cookieRestaurantNewValueCount] = {
                        storeId: storeId,
                        itemId: itemId,
                        itemName: itemName,
                        quantity: quantity,
                        itemSize: itemSize,
                        itemMrp: itemMrp,
                        itemImage: itemImage,
                        storeItemSizeId: storeItemSizeId,
                        itemNo: itemNo,
                        toppingsData: toppingsData
                    }
                    cookieRestaurantUpdated = true
                }
            }
            else {
                $(".checkoutAvailPromo" + storeId).removeClass("promoCodeDisable")
                $(".checkoutAvailPromo" + storeId).hide()
                $("#hidPromocodeDetails" + storeId).val('');
                $("#txtPromocode" + storeId).val('');
                $("#applyPromo" + storeId).show()
                $("#remPromo" + storeId).hide()
                $("#promoLink" + storeId).show()
                $("#promoNoDataLink" + storeId).hide()
            }
            localStorage.setItem('selectRestaurantCart', JSON.stringify(cookieNewRestaurantValue))
        }
        let restaurantCartItems = localStorage.getItem('selectRestaurantCart');
        let cartItemInDetail = JSON.parse(restaurantCartItems);
        var grandTotal = 0;
        for (var j = 0; j < cartItemInDetail.length; j++) {
            $('#txtQty' + j).val(cartItemInDetail[j].quantity);
            this.state.itemQty = cartItemInDetail[j].quantity;
            this.state.itemPrice = cartItemInDetail[j].item_prices
            this.state.totalResult = this.state.itemQty * this.state.itemPrice
            grandTotal = parseFloat(grandTotal + (cartItemInDetail[j].item_prices * cartItemInDetail[j].quantity))
        }
        $("#cartCount").text((JSON.parse(localStorage.getItem('selectRestaurantCart')).length))
        this.checkCartCount();
    }
    isNumberRestaurant(e, itemId, storeId, itemName, itemMrp, itemImage, itemSize, storeItemSizeId, toppingsData) {
        let itemNo = itemId + '-' + storeItemSizeId + '-' + toppingsData.toString().replace(',', '-')
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            var prnum = $('.cartInput' + itemNo).val();
        }
        else {
            var prnum = e.target.value.replace(/[^0-9\.]/g, '');
            $('.cartInput' + itemNo).val(prnum)

        }
        this.addToCartRestaurant("txtChange", itemId, storeId, itemName, itemMrp, itemImage, itemSize, storeItemSizeId, toppingsData)
    }
    addToCartCommonItem(type, storeId, itemId, itemName, quantity, itemTitle, brand, sizeName, sizeId, cmItemSizeId, itemMasterId, netAmount, mrp, itemImage, maxBuyCount, availableQuantity) {
        let isDelete = false;
        let itemNo = itemId + '-' + cmItemSizeId
        if (type === "addToCart") {
            $('#addToCart' + itemNo).addClass("cart_clk");
            if ($('.cartInput' + itemNo).val() == 0) {
                $('.cartInput' + itemNo).val(1);
            }
        }
        else if (type === "inc") {
            var addItem = $('.cartInput' + itemNo).val()
            if (addItem > 0) {
                if (parseInt(addItem) >= parseInt(availableQuantity)) {
                    alert("Only " + availableQuantity + " qty available")
                }
                else if (parseInt(addItem) >= parseInt(maxBuyCount)) {
                    alert("You can buy maximum " + maxBuyCount)
                }
                else {
                    addItem++;
                    $('.cartInput' + itemNo).val(addItem);
                }
            }
            else if (addItem == 0) {
                $('.cartInput' + itemNo).val(1);
            }
        }
        else if (type === "dec") {
            var removeItem = $('.cartInput' + itemNo).val()
            removeItem--;
            if (removeItem == 0) {
                isDelete = true;
                $('#addToCart' + itemNo).removeClass("cart_clk");
            }
            $('.cartInput' + itemNo).val(removeItem);
        }
        else if (type === "txtChange") {
            var txtItem = $('.cartInput' + itemNo).val()
            if (txtItem == 0) {
                $('#addToCart' + itemNo).removeClass("cart_clk");
            }
            else {
                if (parseInt(txtItem) > parseInt(availableQuantity)) {
                    alert("Only " + availableQuantity + " qty available")
                }
                else if (parseInt(txtItem) > parseInt(maxBuyCount)) {
                    alert("You can buy maximum " + maxBuyCount)
                }
                else {
                    $('.cartInput' + itemNo).val(txtItem);
                }
            }
        }
        else if (type === "remove") {
            $('#addToCart' + itemNo).removeClass("cart_clk");
            $("#txtQty" + itemNo).val(0)
            $('.cartInput' + itemNo).val(0)
        }
        this.updateCartCommonItem(storeId, itemId, itemName, $('.cartInput' + itemNo).val(), itemTitle, brand, sizeName, sizeId, cmItemSizeId, itemMasterId, netAmount, mrp, itemImage, isDelete, maxBuyCount, availableQuantity)
    }
    updateCartCommonItem(storeId, itemId, itemName, quantity, itemTitle, brand, sizeName, sizeId, cmItemSizeId, itemMasterId, netAmount, mrp, itemImage, isDelete, maxBuyCount, availableQuantity) {
        var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
        let itemNo = itemId + '-' + cmItemSizeId
        if (localStorage.getItem('selectCommonItemCart') === null) {
            if (isDelete === false || quantity > 0) {
                var selectedCommonItemProduct = {
                    storeId: storeId,
                    itemId: itemId,
                    itemName: itemName,
                    quantity: quantity,
                    itemTitle: itemTitle,
                    brand: brand,
                    sizeName: sizeName,
                    sizeId: sizeId,
                    cmItemSizeId: cmItemSizeId,
                    itemMasterId: itemMasterId,
                    netAmount: netAmount,
                    mrp: mrp,
                    itemImage: itemImage,
                    itemNo: itemNo,
                    maxBuyCount: maxBuyCount,
                    availableQuantity: availableQuantity
                }
                selectCommonItemCart.push(selectedCommonItemProduct)
                localStorage.setItem('selectCommonItemCart', JSON.stringify(selectCommonItemCart))
            }
        }
        else {
            var cookieNewCommonItemValue = [];
            var cookieCommonItemArray = selectCommonItemCart;
            var cookieCommonItemUpdated = false
            var cookieCommonItemNewValueCount = 0;
            for (var i = 0; i < cookieCommonItemArray.length; i++) {
                if (cookieCommonItemArray[i] !== null && cookieCommonItemArray[i] !== 'null' && cookieCommonItemArray[i] !== "") {
                    var cookieStoreId = cookieCommonItemArray[i].storeId
                    var cookieItemId = cookieCommonItemArray[i].itemId
                    var cookieCmItemSizeId = cookieCommonItemArray[i].cmItemSizeId.toString().trim()
                    if (itemId == cookieItemId && storeId == cookieStoreId && cookieCmItemSizeId == cmItemSizeId) {
                        if (isDelete == false && quantity > 0) {
                            cookieNewCommonItemValue[cookieCommonItemNewValueCount] =
                            {
                                storeId: storeId,
                                itemId: itemId,
                                itemName: itemName,
                                quantity: quantity,
                                itemTitle: itemTitle,
                                brand: brand,
                                sizeName: sizeName,
                                sizeId: sizeId,
                                cmItemSizeId: cmItemSizeId,
                                itemMasterId: itemMasterId,
                                netAmount: netAmount,
                                mrp: mrp,
                                itemImage: itemImage,
                                itemNo: itemNo,
                                maxBuyCount: maxBuyCount,
                                availableQuantity: availableQuantity
                            }
                            cookieCommonItemNewValueCount++;
                        }
                        cookieCommonItemUpdated = true
                    }
                    else {
                        cookieNewCommonItemValue[cookieCommonItemNewValueCount] = cookieCommonItemArray[i];
                        cookieCommonItemNewValueCount++;
                    }
                }
            }
            if (cookieCommonItemUpdated === false) {
                if (quantity > 0) {
                    cookieNewCommonItemValue[cookieCommonItemNewValueCount] = {
                        storeId: storeId,
                        itemId: itemId,
                        itemName: itemName,
                        quantity: quantity,
                        itemTitle: itemTitle,
                        brand: brand,
                        sizeName: sizeName,
                        sizeId: sizeId,
                        cmItemSizeId: cmItemSizeId,
                        itemMasterId: itemMasterId,
                        netAmount: netAmount,
                        mrp: mrp,
                        itemImage: itemImage,
                        itemNo: itemNo,
                        maxBuyCount: maxBuyCount,
                        availableQuantity: availableQuantity
                    }
                    cookieCommonItemUpdated = true
                }
            }
            else {
                $(".checkoutAvailPromo" + storeId).removeClass("promoCodeDisable")
                $(".checkoutAvailPromo" + storeId).hide()
                $("#hidPromocodeDetails" + storeId).val('');
                $("#txtPromocode" + storeId).val('');
                $("#applyPromo" + storeId).show()
                $("#remPromo" + storeId).hide()
                $("#promoLink" + storeId).show()
                $("#promoNoDataLink" + storeId).hide()
            }
            localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewCommonItemValue))
        }
        $("#cartCount").text((JSON.parse(localStorage.getItem('selectCommonItemCart')).length))
        this.checkCartCount();
    }
    isNumberCommonItem = (e, storeId, itemId, itemName, quantity, itemTitle, brand, sizeName, sizeId, cmItemSizeId, itemMasterId, netAmount, mrp, itemImage, maxBuyCount, availableQuantity) => {
        let itemNo = itemId + '-' + cmItemSizeId
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
        }
        else {
            $('.cartInput' + itemNo).val(1)
        }
        if (maxBuyCount < $('.cartInput' + itemNo).val()) {
            alert("You can buy maximum " + maxBuyCount)
            $('.cartInput' + itemNo).val(maxBuyCount)
        }
        this.addToCartCommonItem("txtChange", storeId, itemId, itemName, quantity, itemTitle, brand, sizeName, sizeId, cmItemSizeId, itemMasterId, netAmount, mrp, itemImage, maxBuyCount, availableQuantity)
    }
    addDefaultSrc(ev) {
        ev.target.src = $("#hid" + ev.target.id).val()
    }
    render() {
        let themeId = localStorage.getItem('themeId')
        let hasMultipleCheckout = window.sessionStorage.getItem('hasMultipleCheckout')
        const { bookingDate, bookingTime } = this.state;
        return (
            this.state.cartDetails.map((cartObject, cartIndex) =>
                (cartObject.grocery.length > 0 || cartObject.commonItems.length > 0 || cartObject.restaurant.length > 0) ?
                    <div className="checkoutSection" id="itemSummeryDiv">
                        <input type="hidden" id="hidStoreIdForCheckout" />
                        <input type="hidden" id="hidStoreIdForPrescriptionCheckout" />

                        <div className="checkoutOrder">
                            {/* <div className="checkoutOrderHead">
                                <div className="checkoutOrderRow">
                                    <div className="checkoutStoreColumn">Store</div>
                                    <div className="checkoutItemDetail">
                                        <div className="checkoutItemColumn">Item</div>
                                        <div className="checkoutQtyColumn">Qty</div>
                                        <div className="checkoutPriceColumn">Price</div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="checkoutOrderBody">
                                {cartObject.grocery.map((groceryObject, groceryObjectIndex) =>
                                    <div className="checkoutOrderRow">
                                        <div className="checkoutStoreColumn">
                                            <div className="checkOutStore">
                                                <div className="checkOutStoreDetails">
                                                    <span className="checkOutStoreLogo"><img id={'grocery' + groceryObjectIndex} onError={this.addDefaultSrc} src={groceryObject.logopath} alt=""/></span>
                                                    <input type="hidden" id={"hidgrocery" + groceryObjectIndex} value={groceryObject.sellerLogo} />
                                                    <h4>{groceryObject.storeName}</h4>
                                                    {(() => {
                                                        if ((themeId === '34' || themeId === '29') && localStorage.getItem('hasGlobalSearch') == "false") {
                                                            return (
                                                                <Link onClick={(e) => this.setStore(groceryObject)} to={"/" + groceryObject.slug}><span class="cartCommonButton">Store page</span></Link>
                                                            )
                                                        }
                                                    })()}
                                                    {this.state.isPrescriptionOnly === '0' || groceryObject.mainCatIds !== 21 || groceryObject.cartItems.length > 0 ?
                                                        <span class="cartCommonButton cartCommonCancel" title={"Remove the item(s) in " + groceryObject.storeName} onClick={(e) => this.deleteStoreFromCart(groceryObject.storeId)}>Remove All</span>
                                                        : ''}
                                                </div>
                                                {this.state.isPrescriptionOnly === '0' || this.state.isPrescriptionOnly === 0 || groceryObject.mainCatIds !== 21 || groceryObject.cartItems.length > 0 ?
                                                    <div className="checkOutInvoice">
                                                        <div class="checkOutStoreName">Invoice by retailer:</div>
                                                        <div class="checkOutMrpSection">Sub Total :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.storeTotalBeforePromoCode).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                        {groceryObject.storePromocodeAmount !== '' ?
                                                            groceryObject.isFreeItem === '1' ?
                                                                <div class="checkOutMrpSection">Promo Code :<span>Applied</span></div>
                                                                :
                                                                <div class="checkOutMrpSection">Promo Code :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.storePromocodeAmount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                            :
                                                            ''
                                                        }
                                                        <div class="checkOutMrpSection">Total Discount :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.discount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                        <div class="checkOutMrpSection">Delivery :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.deliveryAmount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                        <div id="taxDiv" className="priceListSummary">
                                                            {groceryObject.taxData.map((tax, taxIndex) => (
                                                                <div key={taxIndex} className="checkOutMrpSection">
                                                                    {tax.taxName}({parseFloat(tax.taxPercentage).toFixed(window.sessionStorage.getItem('decimalCount'))} %) :
                                                                    <span>{groceryObject.currencySymbol} {parseFloat(tax.taxAmount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div class="checkOutMrpSection">Total :<span>{groceryObject.isMultiple} {groceryObject.currencySymbol} {parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')})}</span></div>
                                                    </div>
                                                    : ''}
                                            </div>
                                            {console.log("groceryObject",groceryObject)}
                                            {this.state.isPrescriptionOnly === '0' || this.state.isPrescriptionOnly === 0 || groceryObject.mainCatIds !== 21 || groceryObject.cartItems.length > 0 ?
                                                <div className="checkoutItemSaveDiv">
                                                    <div className="checkoutItemSave">
                                                        {groceryObject.freeShoppingText !== "" ?
                                                            <div className="checkoutItemText" id={"freeShoppingText" + groceryObject.storeId}>{groceryObject.freeShoppingText}</div>
                                                            : ''}
                                                        <div className="summarySaveText">You Saved {groceryObject.currencySymbol} {(parseFloat(groceryObject.discount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</div>
                                                        {groceryObject.isFreeItem === "1" ?
                                                            <div className="checkoutItemText">Congratulations! You got <span className="summarySaveText">{groceryObject.freeItemName}</span> free</div>
                                                            : ''}
                                                    </div>
                                                </div>
                                                : ''}
                                            <div className="checkoutcontStore">
                                                <div className="checkoutcontStoreLeft">
                                                    Contact <br></br>Store
                                                </div>
                                                <div className="checkoutcontStoreRght">
                                                    <div className="checkoutItemText">Mobile : <span className="summarySaveText">{groceryObject.mobileNumber}</span></div>
                                                    <div className="checkoutItemText">Email : <span className="summarySaveText">{groceryObject.emailId}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="checkoutItemDetail">
                                            {groceryObject.cartItems.map((groceryCartItems, groceryIndex) => (
                                                groceryCartItems.item_names !== "" ?
                                                    <div className="checkoutItemDetailList" id={"itemListDiv" + groceryObject.storeId + groceryIndex}>
                                                        <div className="checkoutItemColumn">
                                                            <div className="checkOutImage"><img src={groceryCartItems.item_image} alt=""/></div>
                                                            <div className="checkOutItemName">
                                                                {groceryCartItems.item_names}
                                                                <div className="checkOutQty">{groceryCartItems.pack_name}</div>
                                                                <div className="checkOutQty">{groceryCartItems.brand_name}</div>
                                                            </div>
                                                        </div>
                                                        {(() => {
                                                            if (groceryCartItems.isOutOfStock === '1') {
                                                                return (
                                                                    <div class="isOutOfStock">Sold Out</div>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="checkoutQtyColumn">
                                                                        <div class="cartQtyBtn">
                                                                            <span class="cartQtyDec" onClick={(e) => this.addToCart((groceryCartItems.storeId + groceryIndex), groceryCartItems.storeId, groceryCartItems.store_item_stock_ids, groceryCartItems.item_ids, groceryCartItems.item_prices, groceryCartItems.pack_ids, "dec", groceryCartItems.stock_quantity, groceryCartItems.item_names, groceryCartItems.quantity, groceryCartItems.item_image, groceryCartItems.itemNo)} id={"minuscart" + (groceryCartItems.storeId + groceryIndex)}>-</span>
                                                                            <span class="cartQtyNumber">
                                                                                <input type="text" className="cartQtyInput" autocomplete="off" id={"itemQty" + (groceryCartItems.storeId + groceryIndex)} onChange={(e) => this.isNumber(e, (groceryCartItems.storeId + groceryIndex), groceryCartItems.stock_quantity, groceryCartItems.item_names, groceryCartItems.item_ids, groceryCartItems.item_prices, groceryCartItems.store_item_stock_ids, groceryCartItems.pack_ids, groceryCartItems.quantity, groceryCartItems.item_image, groceryCartItems.itemNo, groceryCartItems.storeId)} value={groceryCartItems.quantity} />
                                                                            </span>
                                                                            <span class="cartQtyInc" onClick={(e) => this.addToCart((groceryCartItems.storeId + groceryIndex), groceryCartItems.storeId, groceryCartItems.store_item_stock_ids, groceryCartItems.item_ids, groceryCartItems.item_prices, groceryCartItems.pack_ids, "inc", groceryCartItems.stock_quantity, groceryCartItems.item_names, groceryCartItems.quantity, groceryCartItems.item_image, groceryCartItems.itemNo)} id={"addcart" + (groceryCartItems.storeId + groceryIndex)}>+</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })()}
                                                        <div className="checkoutPriceColumn">
                                                            <div className="cartProductItemPrice">
                                                                <div className="newPrice">{groceryObject.currencySymbol}{parseFloat(groceryCartItems.item_prices).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')})}</div>
                                                                {parseFloat(groceryCartItems.item_prices) < parseFloat(groceryCartItems.mrp) ?
                                                                    <div className="oldPrice">{groceryObject.currencySymbol}{parseFloat(groceryCartItems.mrp).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')})}</div>
                                                                    : ''}

                                                            </div>
                                                        </div>
                                                        <span className="checkoutDelete cartremove" title="Remove the item" onClick={(e) => this.addToCart((groceryCartItems.storeId + groceryIndex), groceryCartItems.storeId, groceryCartItems.store_item_stock_ids, groceryCartItems.item_ids, groceryCartItems.item_prices, groceryCartItems.pack_ids, "remove", groceryCartItems.stock_quantity, groceryCartItems.item_names, groceryCartItems.quantity, groceryCartItems.item_image, groceryCartItems.itemNo)} id={"removecart" + (groceryCartItems.storeId + groceryIndex)}><img src={deleteimg} alt=""/></span>
                                                    </div>
                                                    : ''
                                            ))}
                                        </div>
                                        {groceryObject.mainCatIds === 21 ? <UploadPrescription savedAmount={this.savedAmount} storeId={groceryObject.storeId}></UploadPrescription> : ''}
                                        <div className="checkoutSlotSection">
                                            {this.state.isPrescriptionOnly === '0' || groceryObject.mainCatIds !== 21 || groceryObject.cartItems.length > 0 ?
                                                <Promocode savedAmount={this.savedAmount} storeId={groceryObject.storeId} amount={groceryObject.grandTotal}></Promocode>
                                                : ''}
                                            <div className="checkoutSlotHalf">
                                                {groceryObject.consumerCardName !== "" && groceryObject.consumerCardName !== "null" && groceryObject.consumerCardName !== null ?
                                                    <div className="checkoutSlotItem">
                                                        <label>{groceryObject.consumerCardName}</label>
                                                        <input type="text" className="inputField" placeholder={groceryObject.consumerCardName} id={"txtConsumerCard" + groceryObject.storeId} />
                                                    </div>
                                                    :
                                                    <div className="checkoutSlotItem">
                                                        <label>Consumer Card No</label>
                                                        <input type="text" className="inputField" placeholder="Consumer Card Number" id={"txtConsumerCard" + groceryObject.storeId} />
                                                    </div>
                                                }
                                                {console.log(groceryObject,"groceryObject.hasLocation",groceryObject.hasLocation)}
                                                {groceryObject.hasLocation === "0" ?
                                                    groceryObject.storeDeliveryDetails.map((deliverySlotos, x) => (
                                                        <div className="checkoutSlotItem">
                                                            <div>
                                                                <div className="deliveryTime">{deliverySlotos.deliverySlotShow}</div>
                                                                <input type="hidden" id={"txtSlot" + groceryObject.storeId} value={deliverySlotos.deliverySlot}></input>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="checkoutSlotItem gsa-checkoutSlotItem">
                                                        <label>Choose delivery Slot<sup>*</sup></label>
                                                        {groceryObject.storeDeliveryDetails[0].deliverySlot.length === 1 ?
                                                            <div className="checkoutSlotItemField">
                                                                <input type="hidden" id={"txtSlot" + groceryObject.storeId} value={groceryObject.storeDeliveryDetails[0].deliverySlot[0]}></input>
                                                                <select className="inputField select-gsa" id={"slot" + groceryObject.storeId} onChange={(e) => this.handleChangeSlot(e, groceryObject.storeId, '1')} >
                                                                    <option value={groceryObject.storeDeliveryDetails[0].deliverySlot[0]}>{groceryObject.storeDeliveryDetails[0].deliverySlot[0]}</option>
                                                                </select>
                                                            </div>
                                                            :
                                                            <div className="checkoutSlotItemField">
                                                                <input type="hidden" id={"txtSlot" + groceryObject.storeId}></input>
                                                                <select id={"slot" + groceryObject.storeId} className="inputField select-gsa" onChange={(e) => this.handleChangeSlot(e, groceryObject.storeId, '1')}>
                                                                    <option value="0">Select</option>
                                                                    {groceryObject.storeDeliveryDetails[0].deliverySlot.map((deliverySlotos, x) => (
                                                                        <option key={x} value={deliverySlotos}>{deliverySlotos}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="checkoutSlotItem">
                                                    <textarea className="inputTextField" placeholder="Text your needs..." id={"txtUserNeeds" + groceryObject.storeId}></textarea>
                                                </div>
                                            </div>
                                            <div className="checkoutCommonButton">
                                                <input type="hidden" id={"txtTottal" + groceryObject.storeId} value={(parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}></input>
                                                {this.state.isPrescriptionOnly === '0' || groceryObject.mainCatIds !== 21 || groceryObject.cartItems.length > 0 ?
                                                    <div className="checkoutGrandPrice">Grand Total: {cartObject.currencySymbol} {parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')})}</div>
                                                    : ''}
                                                {(() => {
                                                    if (themeId === '34' || themeId === '29') {
                                                        if(window.sessionStorage.getItem('subDomainFlag') == "1"){
                                                            return (
                                                                <Link onClick={(e) => this.setStore(groceryObject)} to={"/"}><span className="commonButton checkBorderBtn">Continue Shopping</span></Link>
                                                            )
                                                        }
                                                        else{
                                                        return (
                                                            <Link onClick={(e) => this.setStore(groceryObject)}   to={{
                                                                pathname: localStorage.getItem('hasGlobalSearch') == "false" ? `/${groceryObject.slug}` : '/globalSearchResult',
                                                                // search: localStorage.getItem('hasGlobalSearch') == "false" ? "" : `?searchKey=${groceryObject.slug}`,
                                                                state: { searchKey: "dental", mode: "" }}}><span className="commonButton checkBorderBtn">Continue Shopping</span></Link>
                                                        )
                                                        }
                                                    }
                                                    else {
                                                        return (
                                                            <span className="commonButton checkBorderBtn" onClick={(e) => this.continueClick()}>Continue Shopping</span>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (groceryObject.cartDisable == true) {
                                                        return (
                                                            <span className="commonButton cartdisable" onClick={(e) => this.confirmStoreWise(groceryObject.storeId, '1', groceryObject.mobileNumber, groceryObject.emailId)} >Continue</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="commonButton " onClick={(e) => this.confirmStoreWise(groceryObject.storeId, '1', groceryObject.mobileNumber, groceryObject.emailId)} >Continue</span>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {cartObject.commonItems.map((groceryObject, groceryObjectIndex) =>
                                    <div className="checkoutOrderRow">
                                        <div className="checkoutStoreColumn">
                                            <div className="checkOutStore">
                                                <div className="checkOutStoreDetails">
                                                    <span className="checkOutStoreLogo"><img id={'common' + groceryObjectIndex} onError={this.addDefaultSrc} src={groceryObject.logopath} alt=""/></span>
                                                    <input type="hidden" id={"hidcommon" + groceryObjectIndex} value={groceryObject.sellerLogo} />

                                                    <h4>{groceryObject.storeName}</h4>
                                                    {(() => {
                                                        if (themeId === '34' || themeId === '29') {
                                                            return (
                                                                <Link onClick={(e) => this.setStore(groceryObject)} to={"/cmItemListing/0/0/0/"}><span class="cartCommonButton">Store page</span></Link>
                                                            )
                                                        }
                                                    })()}
                                                    <span class="cartCommonButton cartCommonCancel" title={"Remove the item(s) in " + groceryObject.storeName} onClick={(e) => this.deleteStoreFromCart(groceryObject.storeId)}>Remove All</span>
                                                </div>
                                                <div className="checkOutInvoice">
                                                    <div class="checkOutStoreName">Invoice by retailer:</div>
                                                    <div class="checkOutMrpSection">Sub Total :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.storeTotalBeforePromoCode).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))} </span></div>
                                                    {groceryObject.storePromocodeAmount != '' ?
                                                        groceryObject.isFreeItem == '1' ?
                                                            <div class="checkOutMrpSection">Promo Code :<span>Applied</span></div>
                                                            :
                                                            <div class="checkOutMrpSection">Promo Code :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.storePromocodeAmount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                        :
                                                        ''
                                                    }
                                                    <div class="checkOutMrpSection">Discount :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.discount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                    <div class="checkOutMrpSection">Delivery :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.deliveryAmount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                    <div id="taxDiv" className="priceListSummary">
                                                        {groceryObject.taxData.map((tax, taxIndex) => (
                                                            <div key={taxIndex} className="checkOutMrpSection">
                                                                {tax.taxName}({parseFloat(tax.taxPercentage).toFixed(window.sessionStorage.getItem('decimalCount'))} %) :
                                                                <span>{groceryObject.currencySymbol} {parseFloat(tax.taxAmount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div class="checkOutMrpSection">Total :<span>{groceryObject.isMultiple} {groceryObject.currencySymbol} {(parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                </div>
                                            </div>
                                            <div className="checkoutItemSaveDiv">
                                                <div className="checkoutItemSave">
                                                    {groceryObject.freeShoppingText !== "" ?
                                                        <div className="checkoutItemText" id={"freeShoppingText" + groceryObject.storeId}>{groceryObject.freeShoppingText}</div>
                                                        : ''}
                                                    <div className="summarySaveText">You Saved {groceryObject.currencySymbol} {(parseFloat(groceryObject.discount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</div>
                                                    {groceryObject.isFreeItem === "1" ?
                                                        <div className="checkoutItemText">Congratulations you got <span className="summarySaveText">{groceryObject.freeItemName}</span> free</div>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="checkoutcontStore">
                                                <div className="checkoutcontStoreLeft">
                                                    Contact <br></br>Store
                                                </div>
                                                <div className="checkoutcontStoreRght">
                                                    <div className="checkoutItemText">Mobile : <span className="summarySaveText">{groceryObject.mobileNumber}</span></div>
                                                    <div className="checkoutItemText">Email : <span className="summarySaveText">{groceryObject.emailId}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="checkoutItemDetail">
                                            {groceryObject.commonItemCartItems.map((groceryCartItems, groceryIndex) => (
                                                <div className="checkoutItemDetailList" id={"itemListDiv" + groceryObject.storeId + groceryCartItems.itemId}>
                                                    {console.log("groceryCartItems",groceryCartItems)}
                                                    <div className="checkoutItemColumn">
                                                        <div className="checkOutImage"><img src={groceryCartItems.itemImage} alt=""/></div>
                                                        <div className="checkOutItemName">
                                                            {groceryCartItems.itemName} {(groceryCartItems.itemTitle !== null && groceryCartItems.itemTitle !== '') ? '-' + groceryCartItems.itemTitle : ''}
                                                            <div className="checkOutQty">{groceryCartItems.brand}</div>
                                                            {groceryCartItems.sizeName !== null && groceryCartItems.sizeName !== '' ?
                                                                <div className="checkOutQty">Size - {groceryCartItems.sizeName}</div>
                                                                : ''
                                                            }

                                                        </div>
                                                    </div>
                                                    {(() => {
                                                        if (groceryCartItems.isOutOfStock === '1') {
                                                            return (
                                                                <div class="isOutOfStock">Sold Out</div>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div className="checkoutQtyColumn">
                                                                    <div class="cartQtyBtn">
                                                                        <span class="cartQtyDec" onClick={(e) => this.addToCartCommonItem("dec", groceryCartItems.storeId, groceryCartItems.itemId, groceryCartItems.itemName, groceryCartItems.quantity, groceryCartItems.itemTitle, groceryCartItems.brand, groceryCartItems.sizeName, groceryCartItems.sizeId, groceryCartItems.cmItemSizeId.toString().trim(), groceryCartItems.itemMasterId, groceryCartItems.netAmount, groceryCartItems.mrp, groceryCartItems.itemImage, groceryCartItems.maxBuyCount, groceryCartItems.availableQuantity)} id={"minuscart" + groceryCartItems.itemId + '-' + groceryCartItems.cmItemSizeId}>-</span>
                                                                        <span class="cartQtyNumber">
                                                                            <input type="text" autoComplete="off" className={"cartQtyInput cartInput cartInput" + groceryCartItems.itemId + '-' + groceryCartItems.cmItemSizeId.toString().trim()} id={"txtQty" + groceryCartItems.itemId + '-' + groceryCartItems.cmItemSizeId} onChange={(e) => this.isNumberCommonItem(e, groceryCartItems.storeId, groceryCartItems.itemId, groceryCartItems.itemName, groceryCartItems.quantity, groceryCartItems.itemTitle, groceryCartItems.brand, groceryCartItems.sizeName, groceryCartItems.sizeId, groceryCartItems.cmItemSizeId.toString().trim(), groceryCartItems.itemMasterId, groceryCartItems.netAmount, groceryCartItems.mrp, groceryCartItems.itemImage, groceryCartItems.maxBuyCount, groceryCartItems.availableQuantity)} onClick={(e) => this.addToCartCommonItem("txtChange", groceryCartItems.storeId, groceryCartItems.itemId, groceryCartItems.itemName, groceryCartItems.quantity, groceryCartItems.itemTitle, groceryCartItems.brand, groceryCartItems.sizeName, groceryCartItems.sizeId, groceryCartItems.cmItemSizeId.toString().trim(), groceryCartItems.itemMasterId, groceryCartItems.netAmount, groceryCartItems.mrp, groceryCartItems.itemImage, groceryCartItems.maxBuyCount, groceryCartItems.availableQuantity)} value={groceryCartItems.quantity} />
                                                                        </span>
                                                                        <span class="cartQtyInc" onClick={(e) => this.addToCartCommonItem("inc", groceryCartItems.storeId, groceryCartItems.itemId, groceryCartItems.itemName, groceryCartItems.quantity, groceryCartItems.itemTitle, groceryCartItems.brand, groceryCartItems.sizeName, groceryCartItems.sizeId, groceryCartItems.cmItemSizeId.toString().trim(), groceryCartItems.itemMasterId, groceryCartItems.netAmount, groceryCartItems.mrp, groceryCartItems.itemImage, groceryCartItems.maxBuyCount, groceryCartItems.availableQuantity)} id={"addcart" + groceryCartItems.itemId + '-' + groceryCartItems.cmItemSizeId}>+</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                    <div className="checkoutPriceColumn">
                                                        <div className="cartProductItemPrice">
                                                            <div className="newPrice">{groceryObject.currencySymbol}{parseFloat(groceryCartItems.netAmount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')})}</div>
                                                            {parseFloat(groceryCartItems.netAmount) < parseFloat(groceryCartItems.mrp) ?
                                                                <div className="oldPrice">{groceryObject.currencySymbol}{parseFloat(groceryCartItems.mrp).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')})}</div>
                                                                : ''}

                                                        </div>
                                                    </div>
                                                    {/* <span className="checkoutDelete" title="Remove the item" onClick={(e) => this.addToCart((groceryCartItems.storeId + groceryIndex), groceryCartItems.storeId, groceryCartItems.store_item_stock_ids, groceryCartItems.item_ids, groceryCartItems.item_prices, groceryCartItems.pack_ids, "remove", groceryCartItems.stock_quantity, groceryCartItems.item_names, groceryCartItems.quantity, groceryCartItems.item_image, groceryCartItems.itemNo)} id={"removecart" + (groceryCartItems.storeId + groceryIndex)}><img src={deleteimg} /></span> */}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="checkoutSlotSection">
                                            <Promocode savedAmount={this.savedAmount} storeId={groceryObject.storeId} amount={groceryObject.grandTotal}></Promocode>
                                            <div className="checkoutSlotHalf">
                                                {groceryObject.consumerCardName !== "" && groceryObject.consumerCardName !== "null" && groceryObject.consumerCardName !== null ?
                                                    <div className="checkoutSlotItem">
                                                        <label>{groceryObject.consumerCardName}</label>
                                                        <input type="text" className="inputField" placeholder={groceryObject.consumerCardName} id={"txtConsumerCard" + groceryObject.storeId} />
                                                    </div>
                                                    :
                                                    <div className="checkoutSlotItem">
                                                        <label>Consumer Card No</label>
                                                        <input type="text" className="inputField" placeholder="Consumer Card Number" id={"txtConsumerCard" + groceryObject.storeId} />
                                                    </div>
                                                }
                                                <div className="checkoutSlotItem">
                                                    {groceryObject.storeDeliveryDetails.map((deliverySlotos, x) => (
                                                        <div>
                                                            <div className="deliveryTime">{deliverySlotos.deliverySlotShow}</div>
                                                            <input type="hidden" id={"txtSlot" + groceryObject.storeId} value={deliverySlotos.deliverySlot}></input>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="checkoutSlotHalf">
                                                <div className="checkoutSlotItem">
                                                    <textarea className="inputTextField" placeholder="Text your needs..." id={"txtUserNeeds" + groceryObject.storeId}></textarea>
                                                </div>
                                            </div>
                                            <div className="checkoutCommonButton">
                                                <input type="hidden" id={"txtTottal" + groceryObject.storeId} value={(parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}></input>
                                                <div className="checkoutGrandPrice">Grand Total: {cartObject.currencySymbol} {(parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</div>
                                                {(() => {
                                                    if (themeId === '34' || themeId === '29') {
                                                        return (
                                                            <Link onClick={(e) => this.setStore(groceryObject)} to={"/" + groceryObject.slug}><span className="commonButton checkBorderBtn">Continue Shopping</span></Link>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="commonButton checkBorderBtn" onClick={(e) => this.continueClick()}>Continue Shopping</span>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (groceryObject.cartDisable === true) {
                                                        return (
                                                            <span className="commonButton cartdisable" onClick={(e) => this.confirmStoreWise(groceryObject.storeId, '8', groceryObject.mobileNumber, groceryObject.emailId)} >Continue</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="commonButton " onClick={(e) => this.confirmStoreWise(groceryObject.storeId, '8', groceryObject.mobileNumber, groceryObject.emailId)} >Continue</span>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {cartObject.restaurant.map((groceryObject, groceryObjectIndex) =>
                                    <div className="checkoutOrderRow">
                                        {console.log("restaurantgroceryObject",groceryObject)}
                                        <div className="checkoutStoreColumn">
                                            <div className="checkOutStore">
                                                <div className="checkOutStoreDetails">
                                                    <span className="checkOutStoreLogo"><img id={'restaurant' + groceryObjectIndex} onError={this.addDefaultSrc} src={groceryObject.logopath} alt=""/></span>
                                                    <input type="hidden" id={"hidrestaurant" + groceryObjectIndex} value={groceryObject.sellerLogo} />
                                                    <h4>{groceryObject.storeName}</h4>
                                                    {(() => {
                                                        if (themeId === '34' || themeId === '29') {
                                                            return (
                                                                <Link onClick={(e) => this.setStore(groceryObject)} to={"/restaurantListing/0/0/"}><span class="cartCommonButton">Store page</span></Link>
                                                            )
                                                        }
                                                    })()}
                                                    <span class="cartCommonButton cartCommonCancel" title={"Remove the item(s) in " + groceryObject.storeName} onClick={(e) => this.deleteStoreFromCart(groceryObject.storeId)}>Remove All</span>
                                                </div>
                                                <div className="checkOutInvoice">
                                                    <div class="checkOutStoreName">Invoice by retailer:</div>
                                                    <div class="checkOutMrpSection">Sub Total :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.storeTotalBeforePromoCode).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                    {groceryObject.storePromocodeAmount !== '' ?
                                                        groceryObject.isFreeItem === '1' ?
                                                            <div class="checkOutMrpSection">Promo Code :<span>Applied</span></div>
                                                            :
                                                            <div class="checkOutMrpSection">Promo Code :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.storePromocodeAmount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                        :
                                                        ''
                                                    }
                                                    <div class="checkOutMrpSection">Discount :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.discount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                    <div class="checkOutMrpSection">Delivery :<span>{groceryObject.currencySymbol} {(parseFloat(groceryObject.deliveryAmount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                    <div id="taxDiv" className="priceListSummary">
                                                        {groceryObject.taxData.map((tax, taxIndex) => (
                                                            <div key={taxIndex} className="checkOutMrpSection">
                                                                {tax.taxName}({parseFloat(tax.taxPercentage).toFixed(window.sessionStorage.getItem('decimalCount'))} %) :
                                                                <span>{groceryObject.currencySymbol} {parseFloat(tax.taxAmount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div class="checkOutMrpSection">Total :<span>{groceryObject.isMultiple} {groceryObject.currencySymbol} {(parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</span></div>
                                                </div>
                                            </div>
                                            <div className="checkoutItemSaveDiv">
                                                <div className="checkoutItemSave">
                                                    {groceryObject.freeShoppingText !== "" ?
                                                        <div className="checkoutItemText" id={"freeShoppingText" + groceryObject.storeId}>{groceryObject.freeShoppingText}</div>
                                                        : ''}
                                                    <div className="summarySaveText">You Saved {groceryObject.currencySymbol} {(parseFloat(groceryObject.discount).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</div>
                                                    {groceryObject.isFreeItem === "1" ?
                                                        <div className="checkoutItemText">Congratulations you got <span className="summarySaveText">{groceryObject.freeItemName}</span> free</div>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="checkoutcontStore">
                                                <div className="checkoutcontStoreLeft">
                                                    Contact <br></br>Store
                                                </div>
                                                <div className="checkoutcontStoreRght">
                                                    <div className="checkoutItemText">Mobile : <span className="summarySaveText">{groceryObject.mobileNumber}</span></div>
                                                    <div className="checkoutItemText">Email : <span className="summarySaveText">{groceryObject.emailId}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="checkoutItemDetail">
                                            {groceryObject.restaurantCartItems.map((restaurantCartItem, groceryIndex) => (
                                                <div className="checkoutItemDetailList" id={"itemListDiv" + groceryObject.storeId + restaurantCartItem.itemId}>
                                                    <div className="checkoutItemColumn">
                                                        {console.log("restaurantCartItems",restaurantCartItem)}
                                                        <div className="checkOutImage"><img src={restaurantCartItem.itemImage} alt=""/></div>
                                                        <div className="checkOutItemName">
                                                            {restaurantCartItem.itemName}
                                                            <div className="checkOutQty">{restaurantCartItem.itemSize}</div>
                                                            {restaurantCartItem.toppingsDetails.map((restaurantCartItemsToppings) => (
                                                                <div>
                                                                    <div className="productItemName newPrice">{restaurantCartItemsToppings.name}</div>
                                                                    {restaurantCartItemsToppings.toppingArray.map((restaurantCartItemsToppingsSub) => (
                                                                        <div className="productItemName productItemNameLeft">{restaurantCartItemsToppingsSub.name} <span className="cartProductItemPrice">{restaurantCartItemsToppingsSub.price != 0 ? groceryObject.currencySymbol + ' ' + parseFloat(restaurantCartItemsToppingsSub.price).toFixed(window.sessionStorage.getItem('decimalCount')) : '  (Free)'}</span></div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {(() => {
                                                        if (restaurantCartItem.isOutOfStock === '1') {
                                                            return (
                                                                <div class="isOutOfStock">Sold Out</div>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div className="checkoutQtyColumn">
                                                                    <div class="cartQtyBtn">
                                                                        <span class="cartQtyDec" onClick={(e) => this.addToCartRestaurant("dec", restaurantCartItem.itemId, restaurantCartItem.storeId, restaurantCartItem.itemName, restaurantCartItem.itemMrp, restaurantCartItem.itemImage, restaurantCartItem.itemSize, restaurantCartItem.storeItemSizeId, restaurantCartItem.toppingsData)} id={"minuscart" + restaurantCartItem.itemId + '-' + restaurantCartItem.storeItemSizeId}>-</span>
                                                                        <span class="cartQtyNumber">
                                                                            <input type="text" autoComplete="off" className={"cartQtyInput cartInput cartInput" + restaurantCartItem.itemId + '-' + restaurantCartItem.storeItemSizeId + '-' + restaurantCartItem.toppingsData.toString().replace(',', '-')} id={"txtQty" + restaurantCartItem.itemId + '-' + restaurantCartItem.storeItemSizeId + '-' + restaurantCartItem.toppingsData.toString().replace(',', '-')} onChange={(e) => this.isNumberRestaurant(e, restaurantCartItem.itemId, restaurantCartItem.storeId, restaurantCartItem.itemName, restaurantCartItem.itemMrp, restaurantCartItem.itemImage, restaurantCartItem.itemSize, restaurantCartItem.storeItemSizeId, restaurantCartItem.toppingsData)} onClick={(e) => this.addToCartRestaurant("txtChange", restaurantCartItem.itemId, restaurantCartItem.storeId, restaurantCartItem.itemName, restaurantCartItem.itemMrp, restaurantCartItem.itemImage, restaurantCartItem.itemSize, restaurantCartItem.storeItemSizeId, restaurantCartItem.toppingsData)} value={restaurantCartItem.quantity} />
                                                                        </span>
                                                                        <span class="cartQtyInc" onClick={(e) => this.addToCartRestaurant("inc", restaurantCartItem.itemId, restaurantCartItem.storeId, restaurantCartItem.itemName, restaurantCartItem.itemMrp, restaurantCartItem.itemImage, restaurantCartItem.itemSize, restaurantCartItem.storeItemSizeId, restaurantCartItem.toppingsData)} id={"addcart" + restaurantCartItem.itemId + '-' + restaurantCartItem.storeItemSizeId}>+</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                    <div className="checkoutPriceColumn">
                                                        <div className="cartProductItemPrice">
                                                            <div className="newPrice">{groceryObject.currencySymbol}{parseFloat(restaurantCartItem.itemMrp).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')})}</div>

                                                        </div>
                                                    </div>
                                                    <span className="checkoutDelete" title="Remove the item" onClick={(e) => this.addToCartRestaurant("remove", restaurantCartItem.itemId, restaurantCartItem.storeId, restaurantCartItem.itemName, restaurantCartItem.itemMrp, restaurantCartItem.itemImage, restaurantCartItem.itemSize, restaurantCartItem.storeItemSizeId, restaurantCartItem.toppingsData)} id={"removecart" + restaurantCartItem.itemId + '-' + restaurantCartItem.storeItemSizeId}><img src={deleteimg} alt=""/></span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="checkoutSlotSection">
                                            <Promocode savedAmount={this.savedAmount} storeId={groceryObject.storeId} amount={groceryObject.grandTotal}></Promocode>
                                            <div className="checkoutSlotHalf">
                                                {groceryObject.consumerCardName !== "" && groceryObject.consumerCardName !== "null" && groceryObject.consumerCardName !== null ?
                                                    <div className="checkoutSlotItem">
                                                        <label>{groceryObject.consumerCardName}</label>
                                                        <input type="text" className="inputField" placeholder={groceryObject.consumerCardName} id={"txtConsumerCard" + groceryObject.storeId} />
                                                    </div>
                                                    :
                                                    <div className="checkoutSlotItem">
                                                        <label>Consumer Card No</label>
                                                        <input type="text" className="inputField" placeholder="Consumer Card Number" id={"txtConsumerCard" + groceryObject.storeId} />
                                                    </div>
                                                }
                                                <div className="checkoutSlotItem">
                                                    <input type="hidden" id={"txtSlot" + groceryObject.storeId}></input>
                                                    <div className="checkoutDetailLeft">
                                                        <label>Choose Delivery Date & Time<sup>*</sup></label>
                                                        <select className="inputField" id={"slot" + groceryObject.storeId} onChange={(e) => this.handleChangeSlot(e, groceryObject.storeId, '5')} >
                                                            <option value="0">Select</option>
                                                            {groceryObject.storeDeliveryDetails[0].deliverySlot.map((deliverySlotos, x) => (
                                                                <option key={x} value={deliverySlotos}>{deliverySlotos}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className={"checknonScheduleDelivery nonScheduleDelivery" + groceryObject.storeId} style={{ display: 'none' }}>
                                                        <div className="checkoutDetailLeft">
                                                            <label>&nbsp;</label>
                                                            <div className="checkoutDetailPicker">
                                                                <div className="checkoutDetailTime">
                                                                    <DatePicker
                                                                        placeholderText="Time"
                                                                        selected={this.state.bookingTime}
                                                                        onChange={this.handle}
                                                                        excludeOut
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="hh:mm aa"
                                                                        timeIntervals={15}
                                                                        dateFormat="h:mm aa"
                                                                        timeCaption="time"
                                                                        minDate={new Date()}
                                                                        minTime={this.state.minTime}
                                                                        maxTime={this.state.maxTime}
                                                                        value={bookingTime} className="inputField" placeholder="Booking time" id={"timeNonScheduled" + groceryObject.storeId} />
                                                                    <img src={clock} className="formImage" alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"checknonScheduleDelivery scheduleDelivery" + groceryObject.storeId} style={{ display: 'none' }}>
                                                        <div className="checkoutDetailLeft">
                                                            <label>&nbsp;</label>
                                                            <div className="checkoutDetailPicker">
                                                                <div className="checkoutDetailPickerHalf">
                                                                    <img src={calender} className="formImage" alt=""/>
                                                                    <DatePicker
                                                                        selected={this.state.bookingDate}
                                                                        minDate={new Date()}
                                                                        showDisabledMonthNavigation
                                                                        onChange={this.handleDateChange} dateFormat="dd/MM/yyyy" value={bookingDate} id={"dateScheduled" + groceryObject.storeId} className="inputField" placeholder="Booking date"
                                                                    />
                                                                </div>
                                                                <div className="checkoutDetailPickerHalf">
                                                                    <DatePicker
                                                                        placeholderText="Time"
                                                                        selected={this.state.bookingTime}
                                                                        onChange={this.handle}
                                                                        excludeOut
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeFormat="hh:mm aa"
                                                                        timeIntervals={15}
                                                                        dateFormat="h:mm aa"
                                                                        timeCaption="time"
                                                                        minDate={new Date()}
                                                                        minTime={this.state.minTime}
                                                                        maxTime={moment().endOf('day').toDate()}
                                                                        value={bookingTime} className="inputField" placeholder="Booking time" id={"timeScheduled" + groceryObject.storeId} />
                                                                    <img src={clock} className="formImage" alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span id="scheduledisclaimer" className="disclaimerText"> * Disclaimer :- The order will be confirmed only after telephonic verification</span>
                                                    </div>
                                                </div>
                                                <div className="checkoutSlotItem">
                                                    <textarea className="inputTextField" placeholder="Text your needs..." id={"txtUserNeeds" + groceryObject.storeId}></textarea>
                                                </div>
                                            </div>
                                            <div className="checkoutCommonButton">
                                                <input type="hidden" id={"txtTottal" + groceryObject.storeId} value={(parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}></input>
                                                <div className="checkoutGrandPrice">Grand Total: {cartObject.currencySymbol} {(parseFloat(groceryObject.grandTotal).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</div>
                                                {(() => {
                                                    if (themeId === '34' || themeId === '29') {
                                                        return (
                                                            <Link onClick={(e) => this.setStore(groceryObject)} to={"/restaurantListing/0/0/"}><span className="commonButton checkBorderBtn">Continue Shopping</span></Link>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="commonButton checkBorderBtn" onClick={(e) => this.continueClick()}>Continue Shopping</span>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (groceryObject.cartDisable === true) {
                                                        return (
                                                            <span className="commonButton cartdisable" onClick={(e) => this.confirmStoreWise(groceryObject.storeId, '5', groceryObject.mobileNumber, groceryObject.emailId)} >Continue</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="commonButton " onClick={(e) => this.confirmStoreWise(groceryObject.storeId, '5', groceryObject.mobileNumber, groceryObject.emailId)} >Continue</span>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {console.log("cartobject",cartObject)}
                            {cartObject.isMultiple === true && hasMultipleCheckout === "1" ?
                                <div className="checkoutGrandSec">
                                    <div className="checkoutGrandTotal">
                                        <input type="hidden" id="txtTottalCon" value={(parseFloat(cartObject.grandTotalSale).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}></input>
                                        <div className="checkoutGrandPrice">Grand Total: {cartObject.currencySymbol} {(parseFloat(cartObject.grandTotalSale).toLocaleString('en-IN', {minimumFractionDigits: window.sessionStorage.getItem('decimalCount'), maximumFractionDigits: window.sessionStorage.getItem('decimalCount')}))}</div>
                                        {this.state.isPaymnetTypeExist === true ?
                                            cartObject.confirmAll === true ?
                                                <span className="commonButton" id="txtAllConfirm" onClick={(e) => this.confirmAllStore()}>Confirm All</span>
                                                :
                                                <span className="commonButton cartdisable">Confirm All</span>
                                            : ''
                                        }
                                    </div>
                                </div>
                                :
                                ""}
                        </div>
                    </div>
                    : <div className="checkOutNodata">
                        <div className="checkOutNoFound"><span>No Data Found</span></div>
                        <Link to={"/"}><span className="commonButton checkBorderBtn">Continue Shopping</span></Link>
                    </div>
            )
        )
    }
}
