import React, { Component, Suspense } from 'react';
import offer from "../images/icons/star.png"
import $ from "jquery";
import Contact from "../itemlisting/contact"
import { pageHitAPICall } from "../services"
import CartBtn from "../commonItem/addcartBtn"
import Pagination from "react-js-pagination";
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"
import triangle from '../images/icons/triangle.png'
import lence from '../images/icons/lence.png'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Storebanner from "../lokalIn/storeBanner"
import arowdown from '../school/assets/images/arow-down.png';
import BannerSlider from "../school/bannerSlider";
import { elasticLink, elasticHostURL } from "../config";
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
export default class listing extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.state = {
      activePage: 1,
      itemListingData: [],
      leftCategoryData: [],
      leftColourData: [],
      sizeData: [],
      brandQueryData: [],
      rowCount: [],
      sortActive: false,
      divPagination: '0',
      value: '',
      minPrice: '',
      maxPrice: '',
      isData: '1',
      storeButtonName: '',
      ItemName: '',
      storeItemStockId: '',
      color: '',
      sizeCode: '',
      category1Id: '',
      category2Id: '',
      category3Id: ''
    };
    this.sortListClick = this.sortListClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.brandFilter = this.brandFilter.bind(this);
    this.priceFilter = this.priceFilter.bind(this);
    this.colorFilter = this.colorFilter.bind(this);
    this.sizeFilter = this.sizeFilter.bind(this);
    this.itemListing = this.itemListing.bind(this);
    this.itemLoad = this.itemLoad.bind(this);
  }
  sortClick() {
    $(".hasOpen").removeClass("fliterAccordionOpen")
    $(".sortListDiv").toggleClass("sortListActive")
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.categoryLevel1Id === this.props.categoryLevel1Id) {
      window.location.reload();
    }
  }
  async itemListing(storeId, sortBy, brandId, minPrice, maxPrice, featureSearch, colorId, sizeId, category1Id, category2Id, category3Id, offset, max, keyWord) {
    debugger
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    this.setState({
      color: colorId,
    })
    brandId = (brandId === '' ? '' : brandId.toString().replace(',', "-"));
    colorId = (colorId === '' ? '' : colorId.toString().replace(',', "-"));
    sizeId = (sizeId === '' ? '' : sizeId.toString().replace(',', "-"));
    var filters = [];
    var fields = ['store_id', 'brand_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'colour_id', 'size_id'];
    var paramsVal = [storeId, brandId, category1Id, category2Id, category3Id, colorId, sizeId]
    var termq;
    for (var k = 0; k < fields.length; k++) {
      if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
        if (fields[k] === 'brand_id' || fields[k] === 'colour_id' || fields[k] === 'size_id') {
          var brandSplit = paramsVal[k].toString().split('-')
          termq = { terms: {} };
          termq.terms[fields[k]] = brandSplit;
          filters.push(termq);
        }
        else {
          termq = { term: {} };
          termq.term[fields[k]] = paramsVal[k];
          filters.push(termq);
        }
      }
    }
    if (minPrice.toString() !== '*' && minPrice.toString() !== '') {
      filters.push({ range: { net_amount: { gte: minPrice, lte: maxPrice } } });
    }
    var sortByFilter
    if (sortBy === 'name') {
      sortByFilter = [{ "item_name.keyword": "asc" }, { sort_order: "asc" }]
    }
    else if (sortBy === 'asc' || sortBy === 'desc') {
      sortByFilter = [{ "net_amount": sortBy }]
    }
    else {
      sortByFilter = [{ sort_order: "asc" }]
    }
    var popularItemData;
    var totalCount
    if (keyWord != 'null' && keyWord != '' && keyWord != null) {
      const body = await client.search({
        index: elasticLink() + '-cm-commonitem-search',
        from: offset, size: max,
        body: {
          query:
          {
            bool: {
              "must": {
                "multi_match": {
                  "query": keyWord,
                  "type": "phrase_prefix",
                  "fields": ["search_string"]
                }
              },
              filter:
              {
                bool:
                {
                  must: filters
                }
              }
            }
          },
          sort: sortByFilter
        }
      });
      popularItemData = body.hits.hits
      totalCount = body.hits.total.value
    }
    else {
      const body = await client.search({
        index: elasticLink() + '-cm-commonitem-search',
        from: offset, size: max,
        body: {
          query:
          {
            bool: {
              filter:
              {
                bool:
                {
                  must: filters
                }
              }
            }
          },
          sort: sortByFilter
        }
      });
      popularItemData = body.hits.hits
      totalCount = body.hits.total.value
    }
    this.setItemObject(popularItemData, totalCount)
  }
  setItemObject(dataResult, totalCount) {
    var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart'))
    var itemrelatedData = []
    if (dataResult.length > 0) {
      for (var i = 0; i < dataResult.length; i++) {
        var sizeArray = []
        if (dataResult[i]._source.item_size !== null && dataResult[i]._source.item_size !== '') {
          var item_size = dataResult[i]._source.item_size.split(',')
          var item_size_id = dataResult[i]._source.item_size_id.split(',')
          var item_size_quantity = dataResult[i]._source.item_size_quantity.split(',')
          var cm_ItemSizeId = dataResult[i]._source.cm_item_size_id.split(',')
          for (var s = 0; s < item_size.length; s++) {
            var sizeVal = {
              sizeName: item_size[s],
              sizeId: item_size_id[s],
              sizeQuantity: item_size_quantity[s],
              cmItemSizeId: cm_ItemSizeId[s]
            }
            sizeArray.push(sizeVal)
          }
        }
        // var isCart = false;
        // var cartQuantity = 0;
        // if (selectCommonItemCart != null && selectCommonItemCart != '' && selectCommonItemCart != 'null') {
        //   for (let n = 0; n < selectCommonItemCart.length; n++) {
        //     if (selectCommonItemCart[n].itemId == dataResult[i]._source.item_id) {
        //       isCart = true;
        //       cartQuantity = selectCommonItemCart[n].quantity
        //     }
        //   }
        // }
        var cartLabel = 'ADD TO CART'
        var cartQuantity = 0;
        var isCartItemColorShow = ""
        var isCartItemOpenClass = ""
        if (selectCommonItemCart !== null && selectCommonItemCart !== '' && selectCommonItemCart !== 'null') {
          for (let n = 0; n < selectCommonItemCart.length; n++) {
            if (selectCommonItemCart[n].itemId === dataResult[i]._source.item_id) {
              cartQuantity = parseInt(cartQuantity) + parseInt(selectCommonItemCart[n].quantity)
              isCartItemColorShow = "block"
              isCartItemOpenClass = "cart_clk"
              cartLabel = 'ADDED'
            }
          }
        }
        var itemData = [];
        itemData = {
          s3_folder_name: dataResult[i]._source.s3_folder_name,
          item_name: dataResult[i]._source.item_name,
          item_id: dataResult[i]._source.item_id,
          store_id: dataResult[i]._source.store_id,
          item_master_id: dataResult[i]._source.item_master_id,
          item_title: dataResult[i]._source.item_title,
          item_image: dataResult[i]._source.item_image,
          mrp: dataResult[i]._source.mrp,
          net_amount: dataResult[i]._source.net_amount,
          brand_name: dataResult[i]._source.brand_name,
          available_quantity: dataResult[i]._source.available_quantity,
          slug: dataResult[i]._source.slug,
          is_offer: dataResult[i]._source.is_offer,
          offer_text: dataResult[i]._source.offer_text,
          item_size: dataResult[i]._source.item_size,
          item_size_id: dataResult[i]._source.item_size_id,
          hasContactUsWithoutPrice: dataResult[i]._source.hascontactuswithoutprice,
          hasBuyOption: dataResult[i]._source.hasbuyoption,
          item_size_quantity: dataResult[i]._source.item_size_quantity,
          storeButtonName:
            dataResult[i]._source.storebuttonname == null
              ? ""
              : dataResult[i]._source.storebuttonname,
          sizeResult: sizeArray,
          // isCart: isCart,
          maxBuyCount: dataResult[i]._source.maxbuycount,
          cartSize: cartQuantity,
          isCartItemColorShow: isCartItemColorShow,
          isCartItemOpenClass: isCartItemOpenClass,
          cartLabel: cartLabel,
          currencySymbol: dataResult[i]._source.currencysymbol,
          colorId: dataResult[i]._source.colour_id
        }
        itemrelatedData.push(itemData)
      }
      this.setState({
        itemListingData: itemrelatedData,
        rowCount: totalCount,
        divPagination: '1',
        isData: '1'
      })
    }
    else {
      this.setState({
        isData: '0'
      });
    }
  }
  async categoryListing(storeId, sortBy, brandId, minPrice, maxPrice, featureSearch, colorId, sizeId, category1Id, category2Id, category3Id, offset, max, keyWord) {
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    brandId = (brandId === '' ? '*' : brandId.toString().replace(',', "-"));
    colorId = (colorId === '' ? '*' : colorId.toString().replace(',', "-"));
    sizeId = (sizeId === '' ? '*' : sizeId.toString().replace(',', "-"));
    var filters = [];
    var fields = ['store_id', 'brand_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'colour_id', 'size_id'];
    var paramsVal = [storeId, brandId, category1Id, category2Id, category3Id, colorId, sizeId]
    for (var k = 0; k < fields.length; k++) {
      if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
        if (fields[k] === 'brand_id' || fields[k] === 'colour_id' || fields[k] === 'size_id') {
          var brandSplit = paramsVal[k].toString().split('-')
          var termq = { terms: {} };
          termq.terms[fields[k]] = brandSplit;
          filters.push(termq);
        }
        else {
          var termq = { term: {} };
          termq.term[fields[k]] = paramsVal[k];
          filters.push(termq);
        }
      }
    }
    if (minPrice !== '*' && minPrice !== '') {
      filters.push({ range: { net_amount: { gte: minPrice, lte: maxPrice } } });
    }
    if (keyWord != 'null' && keyWord != '' && keyWord != null) {
      var matchq = { match_phrase_prefix: {} };
      matchq.match_phrase_prefix['search_string'] = keyWord;
      filters.push(matchq);
    }
    const body = await client.search({
      index: elasticLink() + '-cm-features',
      from: 0, size: 0,
      body: {
        query:
        {
          bool: {
            filter:
            {
              bool:
              {
                must: filters
              }
            }
          }
        },
        "aggs": {
          "category_level1": {
            terms: {
              field: "category_level1_id",
              order: {
                "_term": "asc"
              }
            },
            "aggs": {
              "category_level1_name": {
                terms: {
                  field: "category_level1_name.keyword"
                },
                "aggs": {
                  "category_level2": {
                    terms: {
                      field: "category_level2_id"
                    },
                    "aggs": {
                      "category_level2_name": {
                        terms: {
                          field: "category_level2_name.keyword"
                        },
                        "aggs": {
                          "category_level3_id": {
                            terms: {
                              field: "category_level3_id"
                            },
                            "aggs": {
                              "category_level3_name": {
                                terms: {
                                  field: "category_level3_name.keyword"
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });
    var leftMenuLevelDetails1 = [];
    var leftMenuLevelDetails2 = [];
    var leftMenuLevelDetails3 = [];
    var level1 = [];
    var level2 = [];
    var level3 = [];
    for (const category_level1Id of body.aggregations.category_level1.buckets) {
      leftMenuLevelDetails2 = []
      for (const category_level2Id of category_level1Id.category_level1_name.buckets[0].category_level2.buckets) {
        leftMenuLevelDetails3 = []
        if (category_level1Id.category_level1_name.buckets[0].category_level2.buckets.size > 0) {
          for (const category_level3Id of category_level2Id.category_level2_name.buckets[0].category_level3.buckets) {
            var level3 = {
              categoryName: category_level3Id.category_level3_name.buckets[0].key,
              level1Id: category_level1Id.key,
              level2Id: category_level2Id.key,
              level3Id: category_level3Id.key,
              levelSub: []
            }
            const resultObject3 = Object.values(level3);
            leftMenuLevelDetails3.push(resultObject3)
          }
        }
        level2 = {
          categoryName: category_level2Id.category_level2_name.buckets[0].key,
          level1Id: category_level1Id.key,
          level2Id: category_level2Id.key,
          level3Id: '',
          levelSub: leftMenuLevelDetails3
        }
        const resultObject2 = Object.values(level2);
        leftMenuLevelDetails2.push(resultObject2)
      }
      level1 = {
        categoryName: category_level1Id.category_level1_name.buckets[0].key,
        level1Id: category_level1Id.key,
        level2Id: '',
        level3Id: '',
        levelSub: leftMenuLevelDetails2
      }
      const resultObject1 = Object.values(level1);
      leftMenuLevelDetails1.push(resultObject1)
    }
    this.setState({
      leftCategoryData: leftMenuLevelDetails1
    });
  }
  async brandListing(storeId, sortBy, brandId, minPrice, maxPrice, featureSearch, colorId, sizeId, category1Id, category2Id, category3Id, offset, max, keyWord) {
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    colorId = (colorId === '' ? '*' : colorId.toString().replace(',', "-"));
    sizeId = (sizeId === '' ? '*' : sizeId.toString().replace(',', "-"));
    var filters = [];
    var fields = ['store_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'colour_id', 'size_id'];
    var paramsVal = [storeId, category1Id, category2Id, category3Id, colorId, sizeId]
    for (var k = 0; k < fields.length; k++) {
      if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
        if (fields[k] === 'colour_id' || fields[k] === 'size_id') {
          var brandSplit = paramsVal[k].toString().split('-')
          var termq = { terms: {} };
          termq.terms[fields[k]] = brandSplit;
          filters.push(termq);
        }
        else {
          var termq = { term: {} };
          termq.term[fields[k]] = paramsVal[k];
          filters.push(termq);
        }
      }
    }
    if (minPrice !== '*' && minPrice !== '') {
      filters.push({ range: { net_amount: { gte: minPrice, lte: maxPrice } } });
    }
    if (keyWord != 'null' && keyWord != '' && keyWord != null) {
      var matchq = { match_phrase_prefix: {} };
      matchq.match_phrase_prefix['search_string'] = keyWord;
      filters.push(matchq);
    }
    const body = await client.search({
      index: elasticLink() + '-cm-features',
      from: 0, size: 0,
      body: {
        query:
        {
          bool: {
            filter:
            {
              bool:
              {
                must: filters
              }
            }
          }
        },
        "aggs": {
          "brand_id": {
            terms: {
              field: "brand_id",
              size: 200,
              order: {
                "_term": "asc"
              }
            },
            "aggs": {
              "brand_name": {
                terms: {
                  field: "brand_name.keyword"
                }
              }
            }
          }
        }
      }
    });
    var brand = []
    var brandArray = []
    for (const brand_id of body.aggregations.brand_id.buckets) {
      brand = {
        brand_id: brand_id.key,
        brand_name: brand_id.brand_name.buckets[0].key
      }
      brandArray.push(brand)
    }
    this.setState({
      brandQueryData: brandArray
    });
  }
  async priceListing(storeId, sortBy, brandId, minPrice, maxPrice, featureSearch, colorId, sizeId, category1Id, category2Id, category3Id, offset, max, keyWord) {
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    brandId = (brandId === '' ? '*' : brandId.toString().replace(',', "-"));
    colorId = (colorId === '' ? '*' : colorId.toString().replace(',', "-"));
    sizeId = (sizeId === '' ? '*' : sizeId.toString().replace(',', "-"));
    var filters = [];
    var fields = ['store_id', 'brand_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'colour_id', 'size_id'];
    var paramsVal = [storeId, brandId, category1Id, category2Id, category3Id, colorId, sizeId]
    for (var k = 0; k < fields.length; k++) {
      if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
        if (fields[k] === 'brand_id' || fields[k] === 'colour_id' || fields[k] === 'size_id') {
          var brandSplit = paramsVal[k].toString().split('-')
          var termq = { terms: {} };
          termq.terms[fields[k]] = brandSplit;
          filters.push(termq);
        }
        else {
          var termq = { term: {} };
          termq.term[fields[k]] = paramsVal[k];
          filters.push(termq);
        }
      }
    }
    if (keyWord != 'null' && keyWord != '' && keyWord != null) {
      var matchq = { match_phrase_prefix: {} };
      matchq.match_phrase_prefix['search_string'] = keyWord;
      filters.push(matchq);
    }
    const body = await client.search({
      index: elasticLink() + '-cm-features',
      from: 0, size: 0,
      body: {
        query:
        {
          bool: {
            filter:
            {
              bool:
              {
                must: filters
              }
            }
          }
        },
        "aggregations": {
          "MIN": {
            min: {
              field: "net_amount"
            }
          },
          "MAX": {
            max: {
              field: "net_amount"
            }
          }
        }
      }
    });
    var minPriceSelected;
    var maxPriceSelected;
    if (body.aggregations.MIN.value > 0 || body.aggregations.MAX.value > 0) {
      if (window.sessionStorage.getItem('hidMinSearch') !== null && window.sessionStorage.getItem('hidMinSearch') !== '') {
        minPriceSelected = window.sessionStorage.getItem('hidMinSearch')
      }
      else {
        minPriceSelected = parseInt(body.aggregations.MIN.value)
      }
      if (window.sessionStorage.getItem('hidMaxSearch') !== null && window.sessionStorage.getItem('hidMaxSearch') !== '') {
        maxPriceSelected = window.sessionStorage.getItem('hidMaxSearch')
      }
      else {
        maxPriceSelected = parseInt(body.aggregations.MAX.value)
      }
      this.setState({
        value: {
          min: parseInt(minPriceSelected),
          max: parseInt(maxPriceSelected)
        },
        minPrice: parseInt(body.aggregations.MIN.value),
        maxPrice: parseInt(body.aggregations.MAX.value)
      });
    }
  }
  async colorListing(storeId, sortBy, brandId, minPrice, maxPrice, featureSearch, colorId, sizeId, category1Id, category2Id, category3Id, offset, max, keyWord) {
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    brandId = (brandId === '' ? '*' : brandId.toString().replace(',', "-"));
    colorId = (colorId === '' ? '*' : colorId.toString().replace(',', "-"));
    sizeId = (sizeId === '' ? '*' : sizeId.toString().replace(',', "-"));

    var filters = [];
    var fields = ['store_id', 'brand_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'size_id'];
    var paramsVal = [storeId, brandId, category1Id, category2Id, category3Id, sizeId]
    for (var k = 0; k < fields.length; k++) {
      if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
        if (fields[k] === 'brand_id' || fields[k] === 'size_id') {
          var brandSplit = paramsVal[k].toString().split('-')
          var termq = { terms: {} };
          termq.terms[fields[k]] = brandSplit;
          filters.push(termq);
        }
        else {
          var termq = { term: {} };
          termq.term[fields[k]] = paramsVal[k];
          filters.push(termq);
        }
      }
    }
    if (minPrice !== '*' && minPrice !== '') {
      filters.push({ range: { net_amount: { gte: minPrice, lte: maxPrice } } });
    }
    if (keyWord != 'null' && keyWord != '' && keyWord != null) {
      var matchq = { match_phrase_prefix: {} };
      matchq.match_phrase_prefix['search_string'] = keyWord;
      filters.push(matchq);
    }
    const body = await client.search({
      index: elasticLink() + '-cm-features',
      from: 0, size: 0,
      body: {
        query:
        {
          bool: {
            filter:
            {
              bool:
              {
                must: filters
              }
            }
          }
        },
        "aggs": {
          "colour_id": {
            terms: {
              field: "colour_id",
              size: 200,
              order: {
                "_term": "asc"
              }
            },
            "aggs": {
              "colour_code": {
                terms: {
                  field: "colour_code.keyword"
                },
                "aggs": {
                  "colour_name": {
                    terms: {
                      field: "colour_name.keyword"
                    }
                  }
                }
              }
            }
          }
        }
      }
    });
    var color = []
    var colorArray = []
    for (const colour_id of body.aggregations.colour_id.buckets) {
      color = {
        colour_id: colour_id.key,
        colour_name: colour_id.colour_code.buckets[0].colour_name.buckets[0].key,
        colour_code: colour_id.colour_code.buckets[0].key
      }
      colorArray.push(color)
    }
    this.setState({
      leftColourData: colorArray
    }, () => {
      // if ($("#hidColor").val() !== "") {
      //   var colorCode = $("#hidColor").val().split(',');
      //   colorCode.map((obj) => {
      //     if (obj)
      //       $(".colorList" + obj).addClass('colorListActive');
      //   })
      // }
    });
  }
  async sizeListing(storeId, sortBy, brandId, minPrice, maxPrice, featureSearch, colorId, sizeId, category1Id, category2Id, category3Id, offset, max, keyWord) {
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    brandId = (brandId === '' ? '*' : brandId.toString().replace(',', "-"));
    colorId = (colorId === '' ? '*' : colorId.toString().replace(',', "-"));
    sizeId = (sizeId === '' ? '*' : sizeId.toString().replace(',', "-"));
    var filters = [];
    var fields = ['store_id', 'brand_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'colour_id'];
    var paramsVal = [storeId, brandId, category1Id, category2Id, category3Id, colorId]
    for (var k = 0; k < fields.length; k++) {
      if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
        if (fields[k] === 'colour_id' || fields[k] === 'brand_id') {
          var brandSplit = paramsVal[k].toString().split('-')
          var termq = { terms: {} };
          termq.terms[fields[k]] = brandSplit;
          filters.push(termq);
        }
        else {
          var termq = { term: {} };
          termq.term[fields[k]] = paramsVal[k];
          filters.push(termq);
        }
      }
    }
    if (minPrice !== '*' && minPrice !== '') {
      filters.push({ range: { net_amount: { gte: minPrice, lte: maxPrice } } });
    }
    if (keyWord != 'null' && keyWord != '' && keyWord != null) {
      var matchq = { match_phrase_prefix: {} };
      matchq.match_phrase_prefix['search_string'] = keyWord;
      filters.push(matchq);
    }
    const body = await client.search({
      index: elasticLink() + '-cm-features',
      from: 0, size: 0,
      body: {
        query:
        {
          bool: {
            filter:
            {
              bool:
              {
                must: filters
              }
            }
          }
        },
        "aggs": {
          "size_id": {
            terms: {
              field: "size_id",
              size: 200,
              order: {
                "_term": "asc"
              }
            },
            "aggs": {
              "size_name": {
                terms: {
                  field: "size_name.keyword"
                }
              }
            }
          }
        }
      }
    });
    var size = []
    var sizeArray = []
    for (const size_id of body.aggregations.size_id.buckets) {
      size = {
        size_id: size_id.key,
        size_name: size_id.size_name.buckets[0].key
      }
      sizeArray.push(size)
    }
    this.setState({
      sizeData: sizeArray
    });
  }
  clearAllFilter() {
    $(".clearAllFilter").hide();
    let category1Id = '';
    let category2Id = '';
    let category3Id = '';
    let searchKeyword = '';
    this.setState({
      color: '',
      sizeCode: '',
    }, () => {
      window.sessionStorage.setItem('searchKeyword', '')
    });
    window.sessionStorage.setItem('category1Id', '')
    window.sessionStorage.setItem('category2Id', '')
    window.sessionStorage.setItem('category3Id', '')
    window.sessionStorage.setItem('brandSearch', '')
    window.sessionStorage.setItem('hidMinSearch', '')
    window.sessionStorage.setItem('hidMaxSearch', '')
    // window.sessionStorage.setItem('colorSearch', '')
    // window.sessionStorage.setItem('sizeSearch', '')
    window.sessionStorage.setItem('sortBySearch', 'rank')
    $(".brandClass").attr('checked', false);
    window.sessionStorage.setItem('minPriceSelected', '')
    window.sessionStorage.setItem('maxPriceSelected', '')
    let storeId = window.sessionStorage.getItem('storeId');
    let offset = 0
    let max = 40
    this.itemListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.categoryListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.brandListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.priceListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.colorListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.sizeListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
  }
  componentDidMount() {
    console.log("localStorage.getItem()", localStorage.getItem("themeName"))
    window.scrollTo(0, 0)
    window.sessionStorage.setItem('sortBy', 'rank')
    let categoryLevel1Id = ''
    let categoryLevel2Id = ''
    let categoryLevel3Id = ''
    let searchKeyword = ''
    if (this.props.fromPage == 'Home') {
      categoryLevel1Id = this.props.categoryLevel1Id
      categoryLevel2Id = this.props.categoryLevel2Id
      categoryLevel3Id = this.props.categoryLevel3Id
      searchKeyword = this.props.searchKeyword
    }
    else {
      const { match: { params } } = this.props;
      categoryLevel1Id = params.categoryLevel1Id
      categoryLevel2Id = params.categoryLevel2Id
      categoryLevel3Id = params.categoryLevel3Id
      searchKeyword = params.searchKeyword
    }
    let category1Id = '';
    let category2Id = '';
    let category3Id = '';
    if (searchKeyword != null && searchKeyword != '' && searchKeyword != 'null' && searchKeyword != '0') {
      searchKeyword = searchKeyword

      window.sessionStorage.setItem('category1Id', '')
      window.sessionStorage.setItem('category2Id', '')
      window.sessionStorage.setItem('category3Id', '')
      window.sessionStorage.setItem('brandSearch', '')
      window.sessionStorage.setItem('hidMinSearch', '')
      window.sessionStorage.setItem('hidMaxSearch', '')
      window.sessionStorage.setItem('searchKeyword', searchKeyword)
      // window.sessionStorage.setItem('colorSearch', '')
      // window.sessionStorage.setItem('sizeSearch', '')
      window.sessionStorage.setItem('sortBySearch', 'rank')
      $(".brandClass").attr('checked', false);
      window.sessionStorage.setItem('minPriceSelected', '')
      window.sessionStorage.setItem('maxPriceSelected', '')
    }
    else {
      window.sessionStorage.setItem('searchKeyword', '')
      if (categoryLevel1Id !== null && categoryLevel1Id !== '0' && categoryLevel1Id !== 'null') {
        category1Id = categoryLevel1Id
        window.sessionStorage.setItem('category1Id', category1Id)
      }
      else {
        window.sessionStorage.setItem('category1Id', '')
      }
      if (categoryLevel2Id !== null && categoryLevel2Id !== '0' && categoryLevel2Id !== 'null') {
        category2Id = categoryLevel2Id
        window.sessionStorage.setItem('category2Id', category2Id)
      }
      else {
        window.sessionStorage.setItem('category2Id', '')
      }
      if (categoryLevel3Id !== null && categoryLevel3Id !== '0' && categoryLevel3Id !== 'null') {
        category3Id = categoryLevel3Id
        window.sessionStorage.setItem('category3Id', category3Id)
      }
      else {
        window.sessionStorage.setItem('category3Id', '')
      }
    }
    $(".productdisplaymain").hide();
    let storeId = window.sessionStorage.getItem('storeId');
    let offset = 0
    let max = 40
    window.sessionStorage.setItem('offset', 0)
    window.sessionStorage.setItem('max', 40)
    this.itemListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.categoryListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.brandListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.priceListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.colorListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.sizeListing(storeId, '', '', '', '', '', '', '', category1Id, category2Id, category3Id, offset, max, searchKeyword)
    $(document).mouseup(function (e) {
      var menuContainer = $(".sideMenuDiv");
      if (!menuContainer.is(e.target) // if the target of the click isn't the container...
        && menuContainer.has(e.target).length === 0) // ... nor a descendant of the container
      {
        menuContainer.hide();
        $(".hasOpen").removeClass("fliterAccordionOpen")
      }
    });
    var sellerID = "";
    var moduleID = 2;
    var siteCategoryGroupId = "";

    if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
      siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
    }
    else {
      sellerID = window.sessionStorage.getItem('sellerId')
    }
    pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
  }
  sortListClick(sortBySearch, type) {
    $("#sortBy").text(type);
    let storeId = window.sessionStorage.getItem('storeId');
    window.sessionStorage.setItem('sortBySearch', sortBySearch)
    let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
    let category1Id = (window.sessionStorage.getItem('category1Id') === null ? '' : window.sessionStorage.getItem('category1Id'));
    let category2Id = (window.sessionStorage.getItem('category2Id') === null ? '' : window.sessionStorage.getItem('category2Id'));
    let category3Id = (window.sessionStorage.getItem('category3Id') === null ? '' : window.sessionStorage.getItem('category3Id'));
    let brandSearch = (window.sessionStorage.getItem('brandSearch') === null ? '' : window.sessionStorage.getItem('brandSearch'));
    let hidMinSearch = (window.sessionStorage.getItem('hidMinSearch') === null ? '' : window.sessionStorage.getItem('hidMinSearch'));
    let hidMaxSearch = (window.sessionStorage.getItem('hidMaxSearch') === null ? '' : window.sessionStorage.getItem('hidMaxSearch'));
    let featureSearch = (window.sessionStorage.getItem('featureSearch') === null ? '' : window.sessionStorage.getItem('featureSearch'));
    let colorSearch = (window.sessionStorage.getItem('colorSearch') === null ? '' : window.sessionStorage.getItem('colorSearch'));
    let sizeSearch = (window.sessionStorage.getItem('sizeSearch') === null ? '' : window.sessionStorage.getItem('sizeSearch'));
    let offset = 0;
    let max = 40;
    this.itemListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    $(".clearAllFilter").show();
  }
  categorytClick(category1, category2, category3) {
    let category1Id = '';
    let category2Id = '';
    let category3Id = '';
    if (category1 != '') {
      category1Id = category1
      this.setState({
        category1Id: category1Id
      })
      window.sessionStorage.setItem('category1Id', category1)
    }
    if (category2 != '') {
      category2Id = category2
      this.setState({
        category2Id: category2Id
      })
      window.sessionStorage.setItem('category2Id', category2)
    }
    else {
      window.sessionStorage.setItem('category2Id', '')
      window.sessionStorage.setItem('category3Id', '')
    }
    if (category3 != '') {
      category3Id = category3
      this.setState({
        category3Id: category3Id
      })
      window.sessionStorage.setItem('category3Id', category3)
    }
    else {
      window.sessionStorage.setItem('category3Id', '')
    }
    let storeId = window.sessionStorage.getItem('storeId');
    let sortBySearch = 'rank'
    window.sessionStorage.setItem('sortBySearch', sortBySearch)
    let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
    let brandSearch = (window.sessionStorage.getItem('brandSearch') === null ? '' : window.sessionStorage.getItem('brandSearch'));
    let hidMinSearch = (window.sessionStorage.getItem('hidMinSearch') === null ? '' : window.sessionStorage.getItem('hidMinSearch'));
    let hidMaxSearch = (window.sessionStorage.getItem('hidMaxSearch') === null ? '' : window.sessionStorage.getItem('hidMaxSearch'));
    let featureSearch = (window.sessionStorage.getItem('featureSearch') === null ? '' : window.sessionStorage.getItem('featureSearch'));
    // let colorSearch = (window.sessionStorage.getItem('colorSearch') == null ? '' : window.sessionStorage.getItem('colorSearch'));
    let colorSearch = (this.state.color === null ? '' : this.state.color);
    // let sizeSearch = (window.sessionStorage.getItem('sizeSearch') == null ? '' : window.sessionStorage.getItem('sizeSearch'));
    let sizeSearch = (this.state.sizeCode === null ? '' : this.state.sizeCode);
    let offset = 0;
    let max = 40;
    this.itemListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.categoryListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.brandListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.priceListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.colorListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.sizeListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    $(".clearAllFilter").show();
  }
  brandFilter(id) {
    let storeId = window.sessionStorage.getItem('storeId');
    let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
    // let category1Id = (window.sessionStorage.getItem('category1Id') == null ? '' : window.sessionStorage.getItem('category1Id'));
    // let category2Id = (window.sessionStorage.getItem('category2Id') == null ? '' : window.sessionStorage.getItem('category2Id'));
    // let category3Id = (window.sessionStorage.getItem('category3Id') == null ? '' : window.sessionStorage.getItem('category3Id'));
    let category1Id = (this.state.category1Id === null ? '' : this.state.category1Id);
    let category2Id = (this.state.category2Id === null ? '' : this.state.category2Id);
    let category3Id = (this.state.category3Id === null ? '' : this.state.category3Id);
    let sortBySearch = 'rank'
    window.sessionStorage.setItem('sortBySearch', sortBySearch)
    let hidMinSearch = (window.sessionStorage.getItem('hidMinSearch') === null ? '' : window.sessionStorage.getItem('hidMinSearch'));
    let hidMaxSearch = (window.sessionStorage.getItem('hidMaxSearch') === null ? '' : window.sessionStorage.getItem('hidMaxSearch'));
    let featureSearch = (window.sessionStorage.getItem('featureSearch') === null ? '' : window.sessionStorage.getItem('featureSearch'));
    let colorSearch = (this.state.color === null ? '' : this.state.color);
    let sizeSearch = (this.state.sizeCode === null ? '' : this.state.sizeCode);
    let offset = 0
    let max = 40
    var brandId = ''
    if (document.getElementById('chechk' + id).checked == true) {
      if ($("#hidBrand").val() == '' || $("#hidBrand").val() == null) {
        $("#hidBrand").val(id.toString().trim())
        brandId = id.toString().trim()
      }
      else {
        var val = $("#hidBrand").val() + ',' + id
        $("#hidBrand").val(val.toString().trim())
        brandId = val.toString().trim()
      }
    }
    else {
      var brandArray = $("#hidBrand").val().split(',')
      var brandData = '';
      for (var j = 0; j < brandArray.length; j++) {
        if (id != brandArray[j]) {
          if (brandData == '') {
            brandData = brandArray[j]
          }
          else {
            brandData = brandData + ',' + brandArray[j]
          }
        }
      }
      $("#hidBrand").val(brandData.toString().trim())
      brandId = brandData.toString().trim()


    }
    window.sessionStorage.setItem('brandSearch', brandId)
    this.itemListing(storeId, sortBySearch, $("#hidBrand").val(), hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.priceListing(storeId, sortBySearch, $("#hidBrand").val(), hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.colorListing(storeId, sortBySearch, $("#hidBrand").val(), hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.sizeListing(storeId, sortBySearch, $("#hidBrand").val(), hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    $(".clearAllFilter").show();
  }
  priceFilter() {
    let storeId = window.sessionStorage.getItem('storeId');
    let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
    // let category1Id = (window.sessionStorage.getItem('category1Id') == null ? '' : window.sessionStorage.getItem('category1Id'));
    // let category2Id = (window.sessionStorage.getItem('category2Id') == null ? '' : window.sessionStorage.getItem('category2Id'));
    // let category3Id = (window.sessionStorage.getItem('category3Id') == null ? '' : window.sessionStorage.getItem('category3Id'));
    let category1Id = (this.state.category1Id === null ? '' : this.state.category1Id);
    let category2Id = (this.state.category2Id === null ? '' : this.state.category2Id);
    let category3Id = (this.state.category3Id === null ? '' : this.state.category3Id);
    let sortBySearch = 'rank'
    window.sessionStorage.setItem('sortBySearch', sortBySearch)
    let brandSearch = (window.sessionStorage.getItem('brandSearch') === null ? '' : window.sessionStorage.getItem('brandSearch'));
    window.sessionStorage.setItem('hidMinSearch', this.state.value.min)
    window.sessionStorage.setItem('hidMaxSearch', this.state.value.max)
    let featureSearch = (window.sessionStorage.getItem('featureSearch') === null ? '' : window.sessionStorage.getItem('featureSearch'));
    let colorSearch = (window.sessionStorage.getItem('colorSearch') === null ? '' : window.sessionStorage.getItem('colorSearch'));
    let sizeSearch = (window.sessionStorage.getItem('sizeSearch') === null ? '' : window.sessionStorage.getItem('sizeSearch'));
    let offset = 0
    let max = 40
    this.itemListing(storeId, sortBySearch, brandSearch, this.state.value.min, this.state.value.max, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.brandListing(storeId, sortBySearch, brandSearch, this.state.value.min, this.state.value.max, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.colorListing(storeId, sortBySearch, brandSearch, this.state.value.min, this.state.value.max, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.sizeListing(storeId, sortBySearch, brandSearch, this.state.value.min, this.state.value.max, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    $(".clearAllFilter").show();
  }
  colorFilter(colorId) {
    var colorCode = $("#hidColor").val().split(',');
    var indexOfItem = $.inArray(colorId.toString(), colorCode);
    if (indexOfItem.toString() === '-1') {
      $(".colorList" + colorId).addClass('colorListActive');
      colorCode.push(colorId)
    }
    else {
      $(".colorList" + colorId).removeClass('colorListActive');
      colorCode = $.grep(colorCode, function (a) {
        return a !== colorId.toString();
      });
    }
    $("#hidColor").val(colorCode.toString().trim())
    colorCode = colorCode.slice(1)
    window.sessionStorage.setItem('colorSearch', colorCode)
    this.setState({
      color: colorCode
    })
    let storeId = window.sessionStorage.getItem('storeId');
    let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
    // let category1Id = (window.sessionStorage.getItem('category1Id') == null ? '' : window.sessionStorage.getItem('category1Id'));
    // let category2Id = (window.sessionStorage.getItem('category2Id') == null ? '' : window.sessionStorage.getItem('category2Id'));
    // let category3Id = (window.sessionStorage.getItem('category3Id') == null ? '' : window.sessionStorage.getItem('category3Id'));
    let category1Id = (this.state.category1Id === null ? '' : this.state.category1Id);
    let category2Id = (this.state.category2Id === null ? '' : this.state.category2Id);
    let category3Id = (this.state.category3Id === null ? '' : this.state.category3Id);
    let sortBySearch = 'rank'
    window.sessionStorage.setItem('sortBySearch', sortBySearch)
    let brandSearch = (window.sessionStorage.getItem('brandSearch') === null ? '' : window.sessionStorage.getItem('brandSearch'));
    let hidMinSearch = (window.sessionStorage.getItem('hidMinSearch') === null ? '' : window.sessionStorage.getItem('hidMinSearch'));
    let hidMaxSearch = (window.sessionStorage.getItem('hidMaxSearch') === null ? '' : window.sessionStorage.getItem('hidMaxSearch'));
    let featureSearch = (window.sessionStorage.getItem('featureSearch') === null ? '' : window.sessionStorage.getItem('featureSearch'));
    let sizeSearch = (this.state.sizeCode === null ? '' : this.state.sizeCode);
    let offset = 0
    let max = 40

    this.itemListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorCode, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.categoryListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorCode, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.brandListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorCode, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.priceListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorCode, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.sizeListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorCode, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    $(".clearAllFilter").show();
  }
  sizeFilter(sizeId) {

    // var indexOfItem = $.inArray(colorId.toString(), colorCode);
    // if (indexOfItem == '-1') {
    //   $(".colorList" + colorId).addClass('colorListActive');
    //   colorCode.push(colorId)
    // }
    // else {
    //   $(".colorList" + colorId).removeClass('colorListActive');
    //   colorCode = $.grep(colorCode, function (a) {
    //     return a !== colorId.toString();
    //   });
    // }


    var sizeCode = $("#hidSize").val().split(',');
    var indexOfItem = $.inArray(sizeId.toString(), sizeCode);
    if (indexOfItem.toString() === '-1') {
      $("#checkSize" + sizeId).addClass('sizeListActive');
      sizeCode.push(sizeId)
    }
    else {
      $("#checkSize" + sizeId).removeClass('sizeListActive');
      sizeCode = $.grep(sizeCode, function (a) {
        return a !== sizeId.toString();
      });
    }
    $("#hidSize").val(sizeCode.toString().trim())
    sizeCode = sizeCode.slice(1)
    this.setState({
      sizeCode: sizeCode
    })
    window.sessionStorage.setItem('sizeSearch', sizeCode)
    let storeId = window.sessionStorage.getItem('storeId');
    let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
    // let category1Id = (window.sessionStorage.getItem('category1Id') == null ? '' : window.sessionStorage.getItem('category1Id'));
    // let category2Id = (window.sessionStorage.getItem('category2Id') == null ? '' : window.sessionStorage.getItem('category2Id'));
    // let category3Id = (window.sessionStorage.getItem('category3Id') == null ? '' : window.sessionStorage.getItem('category3Id'));
    let category1Id = (this.state.category1Id === null ? '' : this.state.category1Id);
    let category2Id = (this.state.category2Id === null ? '' : this.state.category2Id);
    let category3Id = (this.state.category3Id === null ? '' : this.state.category3Id);
    let sortBySearch = 'rank'
    window.sessionStorage.setItem('sortBySearch', sortBySearch)
    let brandSearch = (window.sessionStorage.getItem('brandSearch') === null ? '' : window.sessionStorage.getItem('brandSearch'));
    let hidMinSearch = (window.sessionStorage.getItem('hidMinSearch') === null ? '' : window.sessionStorage.getItem('hidMinSearch'));
    let hidMaxSearch = (window.sessionStorage.getItem('hidMaxSearch') === null ? '' : window.sessionStorage.getItem('hidMaxSearch'));
    let featureSearch = (window.sessionStorage.getItem('featureSearch') === null ? '' : window.sessionStorage.getItem('featureSearch'));
    let colorSearch = (this.state.color === null ? '' : this.state.color);
    let offset = 0
    let max = 40
    this.itemListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeCode, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.categoryListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeCode, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.brandListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeCode, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.priceListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeCode, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    this.colorListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeCode, category1Id, category2Id, category3Id, offset, max, searchKeyword)
    $(".clearAllFilter").show();
  }
  itemLoad() {
    let storeId = window.sessionStorage.getItem('storeId');
    let sortBySearch = (window.sessionStorage.getItem('sortBySearch') === null ? '' : window.sessionStorage.getItem('sortBySearch'));
    let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
    let category1Id = (window.sessionStorage.getItem('category1Id') === null ? '' : window.sessionStorage.getItem('category1Id'));
    let category2Id = (window.sessionStorage.getItem('category2Id') === null ? '' : window.sessionStorage.getItem('category2Id'));
    let category3Id = (window.sessionStorage.getItem('category3Id') === null ? '' : window.sessionStorage.getItem('category3Id'));
    let brandSearch = (window.sessionStorage.getItem('brandSearch') === null ? '' : window.sessionStorage.getItem('brandSearch'));
    let hidMinSearch = (window.sessionStorage.getItem('hidMinSearch') === null ? '' : window.sessionStorage.getItem('hidMinSearch'));
    let hidMaxSearch = (window.sessionStorage.getItem('hidMaxSearch') === null ? '' : window.sessionStorage.getItem('hidMaxSearch'));
    let featureSearch = (window.sessionStorage.getItem('featureSearch') === null ? '' : window.sessionStorage.getItem('featureSearch'));
    let colorSearch = (window.sessionStorage.getItem('colorSearch') === null ? '' : window.sessionStorage.getItem('colorSearch'));
    let sizeSearch = (window.sessionStorage.getItem('sizeSearch') === null ? '' : window.sessionStorage.getItem('sizeSearch'));
    let offset = (window.sessionStorage.getItem('offset') === null ? 0 : window.sessionStorage.getItem('offset'));
    let max = (window.sessionStorage.getItem('max') === null ? 40 : window.sessionStorage.getItem('max'));
    this.itemListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
  }
  handlePageChange(pageNumber) {
    window.scrollTo(0, 0)
    let storeId = window.sessionStorage.getItem('storeId');
    let sortBySearch = (window.sessionStorage.getItem('sortBySearch') === null ? '' : window.sessionStorage.getItem('sortBySearch'));
    let searchKeyword = (window.sessionStorage.getItem('searchKeyword') === null ? '' : window.sessionStorage.getItem('searchKeyword'));
    let category1Id = (window.sessionStorage.getItem('category1Id') === null ? '' : window.sessionStorage.getItem('category1Id'));
    let category2Id = (window.sessionStorage.getItem('category2Id') === null ? '' : window.sessionStorage.getItem('category2Id'));
    let category3Id = (window.sessionStorage.getItem('category3Id') === null ? '' : window.sessionStorage.getItem('category3Id'));
    let brandSearch = (window.sessionStorage.getItem('brandSearch') === null ? '' : window.sessionStorage.getItem('brandSearch'));
    let hidMinSearch = (window.sessionStorage.getItem('hidMinSearch') === null ? '' : window.sessionStorage.getItem('hidMinSearch'));
    let hidMaxSearch = (window.sessionStorage.getItem('hidMaxSearch') === null ? '' : window.sessionStorage.getItem('hidMaxSearch'));
    let featureSearch = (window.sessionStorage.getItem('featureSearch') === null ? '' : window.sessionStorage.getItem('featureSearch'));
    let colorSearch = (window.sessionStorage.getItem('colorSearch') === null ? '' : window.sessionStorage.getItem('colorSearch'));
    let sizeSearch = (window.sessionStorage.getItem('sizeSearch') === null ? '' : window.sessionStorage.getItem('sizeSearch'));
    var offset;
    var max;
    if (pageNumber.toString() === "1") {
      offset = "0";
      max = "40";
      this.setState({
        activePage: pageNumber,
      })
    }
    else {
      offset = parseInt(pageNumber - 1) * 40;
      max = "40";
      this.setState({
        activePage: pageNumber,
      })
    }
    window.sessionStorage.setItem('offset', offset)
    window.sessionStorage.setItem('max', max)
    this.itemListing(storeId, sortBySearch, brandSearch, hidMinSearch, hidMaxSearch, featureSearch, colorSearch, sizeSearch, category1Id, category2Id, category3Id, offset, max, searchKeyword)
  }
  fliterAccordion(index) {
    $(".hasOpen").removeClass("fliterAccordionOpen")
    $(".sortListDiv").removeClass("sortListActive")
    $("#accordionList" + index).toggleClass("fliterAccordionOpen");
    $("#accordionName" + index).toggleClass("accordionFliterActive");
  }
  contactForm(storeButtonName, ItemName, storeItemStockId) {
    this.setState({
      storeButtonName: storeButtonName,
      ItemName: ItemName,
      storeItemStockId: storeItemStockId
    })
    $(".contactFormPopUp").show();
  }
  filterOpen() {
    $("#fliterCover").toggleClass("fliterMenuOpen")
  }
  render() {
    let siteLokalDetails = window.sessionStorage.getItem('siteLokalDetails');
    var siteDetailsLokal = JSON.parse(siteLokalDetails);
    let themeId = localStorage.getItem('themeId')
    return (
      <div className="coverWraper coverWraper-bg">
        {console.log("typeof", typeof (themeId))}
        <Suspense fallback={<div></div>}>
          <MainHeader></MainHeader>
          <div className={themeId === '28' ? "contentWrap clContentWrap" : "contentWrap "}>
            {(() => {
              if (themeId === '34' || themeId === '29') {
                return (
                  <Storebanner />
                )
              }
            })()}
            <div className="itemCoverDiv">
              <BannerSlider />
              <div className={(themeId === '34' || themeId === '29') ? "container" : ""}>
                {/* {(() => {
                  if (themeId !== '34' && themeId !== '29') {
                    return (
                      <div className="itemListTop itemTopListing">
                        <div className="filterSection">
                          <div className="sortBy">Sort By:</div>
                          <div className="sortList">
                            <div className="sortListDiv" onClick={(e) => this.sortClick()}>
                              <span id="sortBy">Sort By</span>
                              <span onClick={(e) => this.sortListClick('rank', 'Newst First')}>Newest First</span>
                              <span onClick={(e) => this.sortListClick('asc', 'Price - Low to High')}>Price - Low to High</span>
                              <span onClick={(e) => this.sortListClick('desc', 'Price - High to Low')}>Price - High to Low</span>
                              <span onClick={(e) => this.sortListClick('name', 'Alphabetical')}>Alphabetical</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })()} */}
                <div className="itemListDiv itemListCommon">
                  {(() => {
                    if (themeId !== '34' && themeId !== '29') {
                      return (
                        <div className="itemListTop itemTopListing">
                          <div className="filterSection">
                            <div className="sortBy">Sort By:</div>
                            <div className="sortList">
                              <div className="sortListDiv" onClick={(e) => this.sortClick()}>
                                <span id="sortBy">Sort By</span>
                                <span onClick={(e) => this.sortListClick('rank', 'Newst First')}>Newest First</span>
                                <span onClick={(e) => this.sortListClick('asc', 'Price - Low to High')}>Price - Low to High</span>
                                <span onClick={(e) => this.sortListClick('desc', 'Price - High to Low')}>Price - High to Low</span>
                                <span onClick={(e) => this.sortListClick('name', 'Alphabetical')}>Alphabetical</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })()}
                  <div className="itemFilterSection gsa-filter-sec">
                    <span className='Sc_filterWraper'>
                      <h4 class=" gsa-filters filterDesktop">Filters</h4>
                      <h4 class=" gsa-filters mobileFilter" onClick={this.filterOpen}>Filters <img src={arowdown} alt="" />
                        {themeId === '35' ? <span style={{ display: 'none' }} onClick={(e) => this.clearAllFilter()} class="pull-right claer-all-gsa clearAllFilter">Clear All</span> : ""}
                      </h4>
                      <div className="fliterCoverDiv" id="fliterCover">
                        {this.state.leftCategoryData.map((categoryObj, index) => (
                          <div className="fliterList">
                            {(() => {
                              if (themeId === '34' || themeId === '29') {
                                return (
                                  <h5 className="filterCategoryName" onClick={(e) => this.fliterAccordion(index)} id={"accordionName" + index}>{categoryObj[0]}<img src={arowdown} alt="" /></h5>
                                )
                              }
                              else {
                                return (
                                  <h5 className="filterCategoryName" onClick={(e) => this.categorytClick(categoryObj[1], '', '')}>{categoryObj[0]}</h5>
                                )
                              }
                            })()}
                            <ul className="hasOpen" id={"accordionList" + index}>
                              {categoryObj[4].map((categoryObj2) => (
                                <li>
                                  <a class="filterListName" href="javascript:void(0)" onClick={(e) => this.categorytClick(categoryObj[1], categoryObj2[2], '')}>{categoryObj2[0]}</a>
                                  <ul>
                                    {categoryObj2[4].map((categoryObj3) => (
                                      <li><a href="javascript:void(0)" onClick={(e) => this.categorytClick(categoryObj[1], categoryObj2[2], categoryObj3[3])}>{categoryObj3[0]}</a></li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                        {this.state.brandQueryData.length > 0 ?
                          <div className="fliterList">
                            {(() => {
                              if (themeId === '34' || themeId === '29') {
                                return (
                                  <h5 className="filterCategoryName" id={"accordionNameBrand"} onClick={(e) => this.fliterAccordion('Brand')}>Brand<img src={arowdown} alt="" /></h5>
                                )
                              }
                              else {
                                return (
                                  <h5>BRANDS</h5>
                                )
                              }
                            })()}
                            <ul className="filterListHeight hasOpen" id={"accordionListBrand"}>
                              {this.state.brandQueryData.map((brand) => (
                                <li>
                                  <span class="checkBoxSquare">
                                    <input onClick={(e) => this.brandFilter(brand.brand_id)} class="brandClass" type="checkbox" id={"chechk" + brand.brand_id} name={"chechk" + brand.brand_id} />
                                    <label for={"chechk" + brand.brand_id}></label>
                                  </span>{brand.brand_name}
                                </li>
                              ))}
                            </ul>
                            <input type="hidden" id="hidBrand" />
                          </div>
                          :
                          ''}
                        {
                          (this.state.maxPrice != this.state.minPrice) ?
                            <div className="fliterList">
                              {(() => {
                                if (themeId !== '34' && themeId !== '29') {
                                  return (
                                    <h5 className="filterCategoryName" >PRICE RANGE</h5>
                                  )
                                }
                              })()}
                              <div className="priceRanger">
                                <InputRange
                                  maxValue={this.state.maxPrice}
                                  minValue={this.state.minPrice}
                                  value={this.state.value}
                                  onChange={value => this.setState({ value })}
                                  onChangeComplete={(e) => this.priceFilter()} />
                              </div>
                            </div>
                            :
                            ''
                        }
                        {this.state.leftColourData.length > 0 ?
                          <div className="fliterList">
                            {(() => {
                              if (themeId === '34' || themeId === '29') {
                                return (
                                  <h5 className="filterCategoryName" id={"accordionNameColor"} onClick={(e) => this.fliterAccordion('Color')}>Color<img src={arowdown} alt="" /></h5>
                                )
                              }
                              else {
                                return (
                                  <h5>PRODUCT COLOURS</h5>
                                )
                              }
                            })()}
                            <div className="colorDivFilter hasOpen" id={"accordionListColor"}>
                              {this.state.leftColourData.map((color) => (
                                <span class={"colorList colorList" + color.colour_id} onClick={(e) => this.colorFilter(color.colour_id)}>
                                  <FontAwesomeIcon icon={faCheck} />
                                  <span className="colorItem" title={color.colour_name} style={{ background: color.colour_code }} id={"chechkcolor" + color.colour_id} >
                                  </span>
                                </span>
                              ))}
                            </div>
                            <input type="hidden" id="hidColor" />
                          </div>
                          :
                          ''}
                        {this.state.sizeData.length > 0 ?
                          <div className="fliterList">
                            {(() => {
                              if (themeId === '34' || themeId === '29') {
                                return (
                                  <h5 className="filterCategoryName" id={"accordionNameSize"} onClick={(e) => this.fliterAccordion('Size')}>Size<img src={arowdown} alt="" /></h5>
                                )
                              }
                              else {
                                return (
                                  <h5 className="filterCategoryName">PRODUCT SIZE</h5>
                                )
                              }
                            })()}
                            <div className="productsizeFilter hasOpen" id={"accordionListSize"}>
                              {this.state.sizeData.map((size) => (
                                <span class="sizeList " onClick={(e) => this.sizeFilter(size.size_id)} id={"checkSize" + size.size_id} name={"checkSize" + size.size_id}>{size.size_name}
                                </span>
                              ))}
                            </div>
                            <input type="hidden" id="hidSize" />
                          </div>
                          :
                          ''}
                        {(() => {
                          if (themeId === '34' || themeId === '29') {
                            return (
                              <div className="fliterList">
                                <div className="filterSection">
                                  <div className="sortList">
                                    <div className="sortListDiv" onClick={(e) => this.sortClick()}>
                                      <span id="sortBy">Sort By <img src={arowdown} alt="" /></span>
                                      <span onClick={(e) => this.sortListClick('rank', 'Newst First')}>Newest First</span>
                                      <span onClick={(e) => this.sortListClick('asc', 'Low to High')}>Low to High</span>
                                      <span onClick={(e) => this.sortListClick('desc', 'High to Low')}>High to Low</span>
                                      <span onClick={(e) => this.sortListClick('name', 'Alphabetical')}>Alphabetical</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        })()}
                        {(() => {
                          if (themeId === '34' || themeId === '29') {
                            return (
                              <span className="fliterList clearAllFilter" onClick={(e) => this.clearAllFilter()} id="clearAllFilter" style={{ display: 'none' }}><h5 className="filterCategoryName">Clear All</h5></span>
                            )
                          }
                        })()}
                      </div>
                    </span>
                  </div>
                  <div className="itemListSection">
                    {(() => {
                      if (this.state.itemListingData.length > 0) {
                        return (
                          <div>
                            {this.state.itemListingData.map((itemListingObject, itemListingIndex) => (
                              <div className="productList">
                                <div className="productItem restaurantItem">
                                  <div className="productImage">
                                    <Link to={"/cmItemProfile" + '/' + itemListingObject.item_id}>
                                      <img src={itemListingObject.item_image} alt="" />
                                    </Link>
                                  </div>
                                  <div className="productDetails">
                                    {itemListingObject.is_offer === 1 ?
                                      <div className="offerText"><img src={offer} alt="bankofferprofile" /> {itemListingObject.offer_text}</div>
                                      : ""}
                                    {!itemListingObject.brand_name ?
                                      <div className="brandName">&nbsp;</div>
                                      : <div className="brandName">{itemListingObject.brand_name}</div>}
                                    <div className="productName">
                                      <Link className="elipsis" title={itemListingObject.item_name} to={"/cmItemProfile" + '/' + itemListingObject.item_id}>
                                        {itemListingObject.item_name}
                                      </Link>
                                    </div>
                                    <Link className="elipsis" title={itemListingObject.item_title} to={"/cmItemProfile" + '/' + itemListingObject.item_id}>
                                      <div className="productDescriptionText">{itemListingObject.item_title}</div>
                                    </Link>
                                    <div class="productPrice">
                                      <span class="newPrice">{itemListingObject.currencySymbol} {parseFloat(itemListingObject.net_amount).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                      {itemListingObject.net_amount < itemListingObject.mrp &&
                                        <span class="oldPrice">{itemListingObject.currencySymbol} {parseFloat(itemListingObject.mrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</span>
                                      }
                                    </div>
                                    {itemListingObject.sizeResult.length > 0 ?
                                      <div class="itemSizeShow">
                                        Sizes:
                                        {itemListingObject.sizeResult.map((itemSizeObject, itemSizeIndex) => (
                                          <span>{itemSizeObject.sizeName}</span>
                                        ))}
                                      </div>
                                      :
                                      <div class="itemSizeShow"></div>
                                    }
                                    {console.log("itemListingObject", itemListingObject)}
                                    <div className="addCartButton">
                                      {(() => {
                                        if (itemListingObject.available_quantity > 0) {
                                          if (itemListingObject.hasContactUsWithoutPrice == true) {
                                            if (itemListingObject.storeButtonName) {
                                              return (
                                                <span class="cartButton" onClick={(e) => this.contactForm(itemListingObject.storeButtonName, itemListingObject.item_name, itemListingObject.item_id)}>{itemListingObject.storeButtonName}</span>
                                              )
                                            }
                                            else {
                                              return (
                                                <span class="cartButton" onClick={(e) => this.contactForm(itemListingObject.storeButtonName, itemListingObject.item_name, itemListingObject.item_id)}>Contact Us</span>
                                              )
                                            }
                                          }
                                          else if (itemListingObject.hasBuyOption == true) {
                                            if (itemListingObject.mrp != 0 || itemListingObject.mrp <= 0) {
                                              return (
                                                <CartBtn
                                                  quantity={itemListingObject.available_quantity}
                                                  fromPage={"Listing"}
                                                  itemLoad={this.itemLoad}
                                                  cmsizeId={itemListingObject.cmsizeId}
                                                  cartSize={itemListingObject.cartSize}
                                                  itemId={itemListingObject.item_id}
                                                  itemName={itemListingObject.item_name}
                                                  storeId={itemListingObject.store_id}
                                                  itemTitle={itemListingObject.item_title}
                                                  brand={itemListingObject.brand_name}
                                                  itemSize={itemListingObject.sizeResult}
                                                  netAmount={itemListingObject.net_amount}
                                                  mrp={itemListingObject.mrp}
                                                  itemImage={itemListingObject.item_image}
                                                  index={itemListingObject.item_id}
                                                  itemMasterId={itemListingObject.item_master_id}
                                                  maxBuyCount={itemListingObject.maxBuyCount}
                                                  availableQuantity={itemListingObject.available_quantity}
                                                  itemAddedColour="itemAddedColour"
                                                  qtyError="qtyError"></CartBtn>
                                              )
                                            }
                                            else {
                                              if (itemListingObject.storeButtonName) {
                                                return (
                                                  <span class="cartButton" onClick={(e) => this.contactForm(itemListingObject.storeButtonName, itemListingObject.item_name, itemListingObject.item_id)}>{itemListingObject.storeButtonName}</span>
                                                )
                                              }
                                              else {
                                                return (
                                                  <span class="cartButton" onClick={(e) => this.contactForm(itemListingObject.storeButtonName, itemListingObject.item_name, itemListingObject.item_id)}>Contact Us</span>
                                                )
                                              }
                                            }
                                          }
                                          else if (itemListingObject.hasBuyOption == false) {
                                            if (itemListingObject.storeButtonName) {

                                              return (
                                                <span class="cartButton" onClick={(e) => this.contactForm(itemListingObject.storeButtonName, itemListingObject.item_name, itemListingObject.item_id)}>{itemListingObject.storeButtonName}</span>
                                              )
                                            }
                                            else {
                                              return (
                                                <span class="cartButton" onClick={(e) => this.contactForm(itemListingObject.storeButtonName, itemListingObject.item_name, itemListingObject.item_id)}>Contact Us</span>
                                              )
                                            }
                                          }
                                        }
                                        else {
                                          return (
                                            <span class="cartButton" onClick={(e) => this.contactForm(itemListingObject.storeButtonName, itemListingObject.item_name, itemListingObject.item_id)}>Contact Us</span>
                                          )
                                        }
                                      })()}
                                    </div>
                                  </div>
                                  {(() => {
                                    if (itemListingObject.available_quantity === 0) {
                                      return (
                                        <div>
                                          <div className="itemAdded itemAddedbg itemDisplay gsa-items-dis out-of-stock-gsa"><span className="itemAddCount">Out Of Stock</span></div>
                                        </div>
                                      )
                                    }
                                    else {
                                      if (itemListingObject.cartSize > 0) {
                                        return (
                                          <div>
                                            <div className="itemAdded itemDisplay  " id={"itemAddedColour" + (itemListingObject.item_id.toString()) + "-"}><span className="itemAddCount" id={"itemCount" + (itemListingObject.item_id.toString()) + "-"}>{itemListingObject.cartSize}</span></div>
                                            <div className="itemAlertText" id={'qtyError' + (itemListingObject.item_id.toString()) + "-"}></div>
                                          </div>
                                        )
                                      }
                                      else {
                                        return (
                                          <div>
                                            <div className="itemAdded" id={"itemAddedColour" + (itemListingObject.item_id.toString()) + "-"}><span className="itemAddCount" id={"itemCount" + (itemListingObject.item_id.toString()) + "-"}>{ }</span></div>
                                            <div className="itemAlertText" id={'qtyError' + (itemListingObject.item_id.toString()) + "-"}></div>
                                          </div>
                                        )
                                      }
                                    }
                                  })()}
                                </div>
                              </div>
                            ))}
                            <div class="popupWrap contactFormPopUp">
                              <Contact storeButtonName={this.state.storeButtonName} ItemName={this.state.ItemName} storeItemStockId={this.state.storeItemStockId} moduleType={"common"}></Contact>
                            </div>
                            <div className="paginationSection" id="divPagination">
                              {this.state.divPagination == '1' ? <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={40}
                                totalItemsCount={this.state.rowCount}
                                pageRangeDisplayed={10}
                                onChange={this.handlePageChange}
                              /> : ''}
                            </div>
                          </div>
                        )
                      }
                      else if (this.state.isData == '0') {
                        return (
                          <div className="itemSection productItemListing">
                            <div className="no-item-found noDataDiv">
                              <img src={lence} alt="" />
                              <div className="noDataText"><img src={triangle} alt="" />No item found</div>
                            </div>
                          </div>
                        )
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MainFooter></MainFooter>
        </Suspense>
      </div>
    )
  }
}